import { connect } from '@seedcloud/stateless'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from 'components/common/Button'
import { ConfirmModal } from 'components/common/ConfirmModal/ConfirmModal'
import { SUPPLIER_LABELS, SUPPLIER_LABEL_DISPLAY } from 'constants/supplier'
import { styled, apply } from 'lib/styled'
import { isSubmittingSupplier$, supplierModule } from 'modules/supplier'

const StatusBarStyled = styled.div(apply('flex'), ({ status }) => ({
  paddingInline: '1.6em',
  paddingBlock: [SUPPLIER_LABELS.PENDING_ACTIVATION, SUPPLIER_LABELS.INVITED].includes(
    status
  )
    ? '0'
    : '1.866em',
  fontSize: '15px',
  backgroundColor: '#FAF7E7',
  color: '#AC9100',
  '& p': apply('mb-0'),
  '& > div:first-of-type': apply('flex-1 flex items-center', {
    height: '48px',
  }),
}))

const SubmitButton = styled(Button)(apply('rounded-lg'), {
  backgroundColor: '#00326F',
  height: '100%',
  color: '#FFFFFF',
})

const getSubmitTextByStatus = (status) =>
  status === SUPPLIER_LABELS.PENDING_PARTNERSHIP
    ? 'Approve Partnership'
    : 'Approve Updates'

const getModalTextByStatus = (status, company) =>
  status === SUPPLIER_LABELS.PENDING_PARTNERSHIP
    ? `Are you sure to approve partnership with ${company} as a supplier?`
    : 'Are you sure to approve updates?'

const StatusBar = ({ status, company, isSubmittingSupplier }) => {
  const { id } = useParams()

  const [showModal, setShowModal] = useState(false)

  const shouldShowExtra =
    status === SUPPLIER_LABELS.PENDING_PARTNERSHIP ||
    status === SUPPLIER_LABELS.UPDATED_DETAILS

  const handleSubmit = async () => {
    await supplierModule.submitSupplier(id)
    setShowModal(false)
  }

  return (
    <>
      <StatusBarStyled status={status}>
        <div>
          <p>
            <b>Status: </b> {SUPPLIER_LABEL_DISPLAY[status]}
          </p>
        </div>
        {shouldShowExtra ? (
          <div>
            <SubmitButton
              onClick={() => {
                setShowModal(true)
              }}
            >
              {getSubmitTextByStatus(status)}
            </SubmitButton>
          </div>
        ) : null}
      </StatusBarStyled>

      <ConfirmModal
        title={getSubmitTextByStatus(status)}
        subtitle={getModalTextByStatus(status, company)}
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        onConfirm={handleSubmit}
        loading={isSubmittingSupplier}
      />
    </>
  )
}

const ConnectedStatusBar = connect(() => ({
  isSubmittingSupplier: isSubmittingSupplier$,
}))(StatusBar)

export { ConnectedStatusBar as StatusBar }

import { Form } from 'formik'

import { Button as ButtonBase } from 'components/common/Button'
import { FieldGroup, Row, TextField } from 'components/company/common'
import { styled, apply } from 'lib/styled'

const RegisterButton = styled(ButtonBase)(
  apply('p-2', {
    boxSizing: 'content-box',
    borderRadius: '8px',
    width: '97%',
  })
)

function RegisterForm() {
  return (
    <Form>
      <FieldGroup title="Company">
        <Row>
          <TextField
            name="organizationDetails.companyName"
            type="text"
            label="Company Name"
            required
          />
        </Row>
        <Row>
          <TextField name="organizationDetails.abn" type="text" label="ABN" />
        </Row>
        <Row>
          <TextField
            name="organizationDetails.companyPhone"
            type="text"
            label="Phone"
          />
        </Row>
        <Row>
          <TextField
            name="organizationDetails.companyWebsite"
            type="text"
            label="Website"
          />
        </Row>
      </FieldGroup>
      <FieldGroup title="Commercial Contact">
        <Row>
          <TextField
            name="userDetails.firstName"
            type="text"
            label="First Name"
            required
          />
          <TextField name="userDetails.lastName" type="text" label="Last Name" />
        </Row>
        <Row>
          <TextField name="userDetails.email" type="text" label="Email" required />
          <TextField name="userDetails.phoneNumber" type="text" label="Phone" />
        </Row>
      </FieldGroup>
      <RegisterButton type="submit">Register</RegisterButton>
    </Form>
  )
}

export { RegisterForm }

import { useState } from 'react'

import { ComplianceCard } from './ComplianceCard'

import { apply, styled } from 'lib/styled'
import toaster from 'lib/toaster'

const ComplianceContainer = styled.div(apply('my-8'))
const Row = styled.div(
  apply('flex flex-row my-2 items-center', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)

const ErrorLabel = styled.label(apply('my-2 tracking-wide text-xs text-red'))

const ERROR_MESSAGE = 'The terms and conditions must be accepted.'

const WithComplianceCheckbox = ({
  title,
  disabled,
  errorMessage = ERROR_MESSAGE,
  complianceMessage,
  children,
  onSubmit,
  hideCompliance,
}) => {
  const [isComplied, setIsComplied] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const shouldShowErrorMessage = !isComplied && isDirty

  const handleSubmit = (...args) => {
    if (!isComplied && !hideCompliance) {
      setIsDirty(true)
      toaster.error(errorMessage)
      return
    }
    onSubmit(...args).catch(() => setIsDirty(true))
  }

  return (
    <ComplianceContainer>
      {!hideCompliance ? (
        <>
          <ComplianceCard
            id="compliance_checkbox"
            name="compliance_checkbox"
            disabled={disabled}
            title={title}
            checked={isComplied}
            complianceMessage={complianceMessage}
            onClick={(e) => {
              setIsComplied(e.target.checked)
              setIsDirty(false)
            }}
          />
          <ErrorLabel>{shouldShowErrorMessage && errorMessage}</ErrorLabel>
        </>
      ) : null}
      <Row>
        {children({
          submit: (...args) => handleSubmit(...args),
          isComplied,
        })}
      </Row>
    </ComplianceContainer>
  )
}

export { WithComplianceCheckbox }

import Select from 'react-select'

import { newStyles } from 'components/common/SelectField'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1'))

function SupplierSelectField({
  id,
  name,
  label,
  type = 'select',
  selected,
  onChange = () => {},
  options = [],
  containerStyle,
  containerProps,
  testId = 'supplier-type-filter',
  ...props
}) {
  return (
    <Container {...containerProps}>
      <Select
        value={options.find(({ value }) => value === selected)}
        type={type}
        onChange={onChange}
        options={options}
        menuPlacement="auto"
        isSearchable={false}
        classNamePrefix={testId}
        instanceId={testId}
        styles={newStyles()}
        {...props}
      />
    </Container>
  )
}

export { SupplierSelectField }

import { useParams } from 'react-router-dom'

import { Card } from 'components/common/Card'
import { SupplierBadge } from 'components/supplier/common/SupplierBadge'
import { SUPPLIER_LABEL_DISPLAY } from 'constants/supplier'

function SupplierListItem({ id, companyName, companyAbn, status, ...props }) {
  const { id: inspectedId } = useParams()

  const isInspected = inspectedId ? inspectedId === id : false

  return (
    <Card isInspected={isInspected} href={`/supplier/${id}`} {...props}>
      <Card.Head>
        <Card.Title title={companyName}>{companyName}</Card.Title>
        <SupplierBadge status={status} />
      </Card.Head>
      <Card.Footer>
        <p>ABN : {companyAbn}</p>
        <p>{SUPPLIER_LABEL_DISPLAY[status]}</p>
      </Card.Footer>
    </Card>
  )
}

export { SupplierListItem }

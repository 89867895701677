import { prop, defaultTo, pick, isNotNil } from '@seedcloud/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi, baseApi } from 'lib/http-client'
import { getOrganizationId } from 'utils/getOrganizationId'

const pilotCategoryEntity = new schema.Entity('pilotCategories')

const PilotCategoryAdminService = {
  async list({ next, limit, supplierType, supplierId }) {
    const { organizationId } = await getOrganizationId()

    const config = {
      searchParams: {
        limit,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    let items
    let paging

    if (supplierType) {
      config.searchParams.organizationId = organizationId
      config.searchParams.companyId = supplierId
      config.searchParams.type =
        supplierType !== 'equipment' ? supplierType.toUpperCase() : 'EQUIPMENT'

      const response = await baseApi.get(`pilot-categories`, config).json()
      items = response.items
      paging = response.paging
    } else {
      const response = await adminApi
        .get(`pilot-categories/${organizationId}`, config)
        .json()

      items = response.items
      paging = response.paging
    }

    const normalized = normalize(items, [pilotCategoryEntity])
    const entities = defaultTo({}, prop('pilotCategories', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      limit: paging.limit,
      sortBy: paging.sortBy,
    }
  },
  async publish(payload) {
    return adminApi.post(`pilot-categories`, { json: payload }).json()
  },
  async update(id, payload) {
    const json = pick(['description', 'rate'], payload)

    const updatedCategory = adminApi.put(`pilot-categories/${id}`, { json }).json()

    return updatedCategory
  },
  async delete(id) {
    return adminApi.delete(`pilot-categories/${id}`)
  },
}

export { PilotCategoryAdminService }

import { ClaimCompanyLayout, ClaimCompanyCard } from 'components/claim/common'

const { Image, Title, Description, Button, Field } = ClaimCompanyCard

const ChangePassword = () => (
  <ClaimCompanyLayout>
    <ClaimCompanyCard>
      <Image />
      <Title>Welcome to GHD Portal</Title>
      <Description>To activate your account, please setup your password.</Description>
      <Field name="password" id="password" label="Password" type="password" />
      <Field
        name="confirm_password"
        id="confirm_password"
        label="Confirm Password"
        type="password"
      />
      <Button onClick={() => {}}>Create Password</Button>
    </ClaimCompanyCard>
  </ClaimCompanyLayout>
)

export { ChangePassword }

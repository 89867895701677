import { adminApi, http } from 'lib/http-client'

const handlePutUploadPresigned = ({
  presignedUrl,
  file,
  onError,
  onProgress,
  resolved,
  rejected,
}) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()

    const performAsynchronously = true
    request.open('PUT', presignedUrl, performAsynchronously)
    request.setRequestHeader('content-type', file.type)

    request.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        onProgress(event.loaded, event.total)
      }
    }

    request.onload = () => {
      const success = request.status >= 200 && request.status < 300

      if (!success) {
        reject(new Error('Failed to upload file. Please try again'))
        onError(rejected)
      } else {
        resolve(resolved)
      }
    }

    request.send(file)
  })

const CompanyAdminService = {
  async read(organizationId) {
    return adminApi.get(`organizations/${organizationId}`).json()
  },
  async update(organizationId, payload) {
    return adminApi.patch(`organizations/${organizationId}`, { json: payload }).json()
  },
  async download(id, organizationId) {
    const { signedUrl } = await adminApi
      .get(`organizations/${organizationId}/document-download/${id}`)
      .json()

    return http.get(signedUrl).blob()
  },
  async upload(organizationId, file, json, onError = () => {}, onProgress = () => {}) {
    const { signedUrl: presignedUrl, entity } = await adminApi
      .post(`organizations/${organizationId}/document-upload`, { json })
      .json()

    return handlePutUploadPresigned({
      presignedUrl,
      resolved: { entity },
      file,
      onError,
      onProgress,
    })
  },
  async uploadLogo(
    organizationId,
    file,
    json,
    onError = () => {},
    onProgress = () => {}
  ) {
    const { signedUrl: presignedUrl, filename } = await adminApi
      .post(`organizations/${organizationId}/logo-upload`, { json })
      .json()

    return handlePutUploadPresigned({
      presignedUrl,
      resolved: { filename },
      file,
      onError,
      onProgress,
    })
  },
}

export { CompanyAdminService }

const { path, compose } = require('@seedcloud/ramda-extra')

const composeSearchParams =
  (...argsFn) =>
  (config) => {
    const _config = config
    const searchParams = path(['searchParams'], _config)

    const composed = compose(...argsFn)(searchParams)

    _config.searchParams = composed

    return _config
  }

export { composeSearchParams }

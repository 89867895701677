import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'

import { useJobs } from 'components/common/context/JobContext'
import { ORGANIZATION_ROLES } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'

function FillContactInfo() {
  const { setFieldValue } = useFormikContext()
  const { staffList } = useJobs()
  const { role, user } = useIdentity()
  const isStaff = role === ORGANIZATION_ROLES.STAFF

  const userData = useMemo(() => {
    if (isStaff && user && staffList) {
      const staff = staffList.find(({ userDetails: { email } }) => email === user.email)
      if (staff) {
        const { userDetails } = staff
        return {
          id: userDetails.id,
          name: `${userDetails.firstName} ${userDetails.lastName}`,
          email: userDetails.email,
          mobile: userDetails.phoneNumber,
        }
      }
    }
    return null
  }, [isStaff, user, staffList])

  useEffect(() => {
    if (userData) {
      setFieldValue('contactInfo.client', {
        id: userData.id,
        name: userData.name,
        email: userData.email,
        mobile: userData.mobile,
      })
    }
  }, [userData, setFieldValue])

  return null
}

export { FillContactInfo }

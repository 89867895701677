import { connect } from '@seedcloud/stateless'
import { useRef, useEffect, useState } from 'react'

import { Button } from '../../common/Button'
import { Loader } from '../../common/Loader'
import { Spinner } from '../../common/Spinner'

import CloudImage from 'assets/clouds_platform.svg'
import { styled, apply } from 'lib/styled'
import { createCompanyModule } from 'modules/legacy-company'

const {
  module: companyModule,
  selectors: { canResendInviteAt$ },
} = createCompanyModule()

const BaseActionBar = styled.div(apply('px-4 pt-6 pb-4 rounded-lg'), () => ({
  backgroundColor: '#EEF1F5',
  backgroundImage: `url('${CloudImage}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top right',
  marginBottom: '2.5em',
}))

const ActionHeadWrapper = styled.div(apply('flex w-full items-center'))

const ActionTitleWrapper = styled.div(apply('ml-4'))

const ActionIcon = styled.div({
  padding: '.45em',
})

const ActionTitle = styled.h1({
  fontSize: '1.0625rem',
  fontWeight: 800,
})

const ActionTitleDescription = styled.p({
  fontSize: '0.9375rem',
  marginBottom: 0,
})

const ActionPriceWrapper = styled.div({
  marginLeft: 'auto',
  textAlign: 'right',
})

const IconHeading = () => (
  <ActionIcon>
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.5645 6.4349C41.3621 6.23341 41.1063 6.0939 40.8273 6.03279C40.5483 5.97167 40.2576 5.99149 39.9895 6.0899L6.98951 18.0899C6.70492 18.1978 6.45989 18.3898 6.28699 18.6403C6.11409 18.8908 6.02148 19.188 6.02148 19.4924C6.02148 19.7968 6.11409 20.094 6.28699 20.3445C6.45989 20.595 6.70492 20.787 6.98951 20.8949L19.8745 26.0399L29.3845 16.4999L31.4995 18.6149L21.9445 28.1699L27.1045 41.0549C27.2157 41.334 27.4081 41.5733 27.6568 41.7418C27.9055 41.9103 28.1991 42.0002 28.4995 41.9999C28.8026 41.9937 29.0968 41.8958 29.3431 41.719C29.5895 41.5423 29.7765 41.2951 29.8795 41.0099L41.8795 8.0099C41.9817 7.74451 42.0064 7.45554 41.9506 7.17668C41.8948 6.89782 41.7609 6.64056 41.5645 6.4349Z"
        fill="#2AADE8"
      />
    </svg>
  </ActionIcon>
)

const timeUntil = (time = Date.now()) => Math.ceil((time - Date.now()) / 1000, 1)

const ResendInvite = ({ id, canResendInviteAt }) => {
  const [secondsRemaining, setSecondsRemaining] = useState(0)
  const [inviting, setInviting] = useState(false)
  const intervalRef = useRef()

  useEffect(() => {
    setSecondsRemaining(timeUntil(canResendInviteAt))

    if (canResendInviteAt) {
      intervalRef.current = setInterval(() => {
        const timeRemaining = timeUntil(canResendInviteAt)

        setSecondsRemaining(timeRemaining)

        if (timeRemaining === 0) {
          clearInterval(intervalRef.current)
        }
      }, 1000)
    }

    return () => clearInterval(intervalRef.current)
  }, [canResendInviteAt])

  const isSubmitted = secondsRemaining > 0

  return (
    <BaseActionBar>
      <ActionHeadWrapper>
        <IconHeading />
        <ActionTitleWrapper>
          <ActionTitle>Resend Invite</ActionTitle>
          <ActionTitleDescription>
            The invitation link will be sent to the user&apos;s email address
          </ActionTitleDescription>
        </ActionTitleWrapper>
        <ActionPriceWrapper>
          <Loader
            loading={inviting}
            renderPlaceholder={<Spinner size={32} thickness={6} />}
          >
            <Button
              type="button"
              onClick={async () => {
                setInviting(true)
                await companyModule.resendInvite(id)
                setInviting(false)
              }}
              disabled={isSubmitted}
            >
              {isSubmitted ? 'Sent' : 'Resend'}
            </Button>
          </Loader>
        </ActionPriceWrapper>
      </ActionHeadWrapper>
    </BaseActionBar>
  )
}

const ConnectedResendInvite = connect(() => ({
  canResendInviteAt: canResendInviteAt$,
}))(ResendInvite)

export { ConnectedResendInvite as ResendInvite }

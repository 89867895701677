import { Formik as FormProvider } from 'formik'

import { CustomerDetailsLayout } from './CustomerDetailsLayout'
import { CustomerDetails } from './Fields/CustomerDetails'
import { FormAction } from './Fields/FormAction'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'

function CustomerProfile({
  initialValues,
  validationSchema,
  editing,
  setEditing,
  isUpdating,
  loading,
  onSubmit,
}) {
  if (loading) return <CustomerDetailsLayout disableTabs loading />

  return (
    <FormLayoutProvider value={{ editing }}>
      <FormProvider
        id="customerDetails"
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, resetForm }) => (
          <CustomerDetailsLayout
            disableTabs={editing}
            footer={
              editing && (
                <FormAction
                  loading={isUpdating}
                  loadingText={'Updating...'}
                  okText="Save"
                  onCancel={() => {
                    resetForm()
                    setEditing(false)
                  }}
                />
              )
            }
          >
            <CustomerDetails
              values={values}
              setEditing={setEditing}
              showButtonEdit={true}
            />
          </CustomerDetailsLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

export { CustomerProfile }

import { connect } from '@seedcloud/stateless'

import { SearchInput } from 'components/common/SearchInput'
import { createCompanyModule } from 'modules/legacy-company'

const {
  module: companyModule,
  selectors: { filterQuery$ },
} = createCompanyModule()

const ConnectedFilterInput = connect(() => ({
  filterQuery: filterQuery$,
}))(FilterInput)

const { filterCompanies } = companyModule

const onChange = (value) => {
  filterCompanies(null, { filterQuery: value })
}

function FilterInput({ filterQuery, containerProps }) {
  return (
    <SearchInput
      value={filterQuery}
      placeholder="Enter customer name, abn or email"
      onChange={onChange}
      containerProps={containerProps}
    />
  )
}

export { ConnectedFilterInput as FilterInput }

/* eslint-disable complexity */
import CONSTANTS from '@seedcloud/constants'
import { noop, values } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import FS from 'filesize'
import { useField } from 'formik'
import { useState, useCallback, useMemo } from 'react'

import { ReactComponent as CloseIcon } from 'assets/close.svg'
import { ReactComponent as DeleteIcon } from 'assets/delete.svg'
import { ReactComponent as FileIcon } from 'assets/document_filled.svg'
import { ReactComponent as DownloadIcon } from 'assets/download.svg'
import { ReactComponent as FileOpenIcon } from 'assets/file_open.svg'
import { ReactComponent as SearchIcon } from 'assets/search.svg'
import { ReactComponent as UncheckedIcon } from 'assets/square.svg'
import { ReactComponent as CheckedIcon } from 'assets/square_selected.svg'
import { Button as ButtonBase } from 'components/common/Button'
import { ErrorMessage } from 'components/common/ErrorMessage'
import { Spinner } from 'components/common/Spinner'
import { SendUploadReminder } from 'components/jobs/common/SendUploadReminder'
import { AssignFileModal } from 'components/jobs/JobDetails/documents/AssignFileModal'
import { JOB_STATUSES } from 'constants/jobs'
import { styled, apply } from 'lib/styled'
import { sendUploadReminderStatus$, inspectedJob$ } from 'modules/job'
import { useFileUpload, createErrorMessage, useUpdateEffect } from 'utils/hooks'

const Panel = styled.div(apply('relative flex flex-column'))

const TopBar = styled.div(
  apply('flex items-center justify-between mb-2 items-center', {
    minHeight: '42px',
  })
)

const Separator = styled.hr(apply('m-1'))

const btnStyle = apply('text-xl mr-2', {
  cursor: 'pointer',
  transition: 'opacity 150ms ease-in-out',
  '&:hover': {
    opacity: 0.4,
  },
})

const Button = styled(ButtonBase)(
  apply('flex items-center', {
    gap: '8px',
  })
)

const ActionBtn = styled.i(btnStyle, ({ disabled }) => ({
  pointerEvents: disabled && 'none',
  color: disabled ? 'gray !important' : '',
}))

const ActionWrapper = styled.div(apply('flex flex-row items-center justify-end'), {
  opacity: '0',
  transition: 'opacity 100ms ease-in-out',
  gap: '8px',
  minWidth: '120px',
})

const DownloadBtn = ({ disabled, onClick }) => (
  <ActionBtn disabled={disabled} onClick={onClick}>
    <DownloadIcon style={{ color: '#00326F' }} />
  </ActionBtn>
)

const DeleteBtn = ({ disabled, onClick }) => (
  <ActionBtn disabled={disabled} onClick={onClick}>
    <DeleteIcon style={{ color: '#00326F' }} />
  </ActionBtn>
)

const ActionDownloadAll = ({ text, onClick, ...props }) => (
  <Button type="button" onClick={onClick} {...props}>
    <DownloadIcon />
    <span>{text}</span>
  </Button>
)

// eslint-disable-next-line no-unused-vars
const ActionDelete = ({ onClick }) => (
  <Button
    onClick={onClick}
    style={apply('bg-transparent p-2 mr-1', {
      border: '1px solid #D42C16',
    })}
  >
    <DeleteIcon style={{ color: '#D42C16' }} />
    <span style={{ color: '#D42C16' }}>Delete</span>
  </Button>
)

// eslint-disable-next-line no-unused-vars
const ActionAssign = ({ onClick }) => (
  <Button onClick={() => onClick(true)} type="button">
    <FileOpenIcon />
    <span>Assign to</span>
  </Button>
)

const UploadBtn = styled.i(btnStyle, ({ disabled }) =>
  apply('ml-2', {
    pointerEvents: disabled && 'none',
    color: disabled ? 'gray !important' : '',
  })
)

const DataContainer = styled.div(
  apply('relative border-0 py-2', {
    height: 'auto',
    maxHeight: '375px',
  }),
  ({ noScroll }) => ({
    overflowY: noScroll ? 'hidden' : 'auto',
  })
)

const Text = styled.span(apply('text-base'))

const FileName = styled.span(
  apply('text-base ml-2', {
    width: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
)

const Item = styled.div(
  apply('flex items-center rounded-lg px-3 py-2', {
    cursor: 'pointer',
    '&:hover': apply('bg-grey-lightest'),
    minHeight: '48px',
    transition: 'opacity 150ms ease-in-out',
    '&:hover .wrapper': {
      opacity: '1',
    },
  }),
  ({ selected }) =>
    selected
      ? {
          background: '#EEF1F5',
        }
      : ''
)

const ContentWrapper = styled.div(apply('flex items-center justify-between w-full'))

const ItemWrapper = styled.div(apply('flex items-center justify-between h-full'))

const SpinnerContainer = styled.div(apply('mr-4'))

const PanelSpinnerContainer = styled.div(apply('absolute'), {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const Overlay = styled.div(
  apply('absolute bg-white', {
    opacity: 0.5,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  })
)

const variantMapper = {
  download: {
    text: 'Download All',
    allowUpload: false,
  },
  upload: {
    style: apply('text-blue-light'),
    allowUpload: true,
  },
}

const SearchBar = styled.div(apply('flex items-center bg-transparent p-2'), {
  border: 'none',
})

const SearchInput = styled.input(apply('flex-1 ml-1 bg-transparent'), {
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
})

const ToggleBtn = ({ checked, onChange }) => (
  <div style={apply('mx-3')} onClick={() => onChange()}>
    {checked ? (
      <CheckedIcon height={16} width={16} />
    ) : (
      <UncheckedIcon height={16} width={16} />
    )}
  </div>
)

// eslint-disable-next-line no-unused-vars
const Search = () => (
  <SearchBar>
    <SearchIcon />
    <SearchInput type="text" placeholder="Search" />
  </SearchBar>
)

const Content = ({
  id,
  operatingFile,
  canDownload,
  canDelete,
  onDownload,
  onDelete,
  fileName,
  fileSize,
  name,
}) => {
  if (operatingFile === id) {
    return (
      <ActionWrapper style={{ opacity: '1' }}>
        <SpinnerContainer>
          <Spinner size={20} thickness={5} />
        </SpinnerContainer>
      </ActionWrapper>
    )
  }

  return (
    <ActionWrapper className="wrapper">
      {canDownload && (
        <DownloadBtn
          onClick={(e) => {
            e.stopPropagation()
            onDownload({ id, fileName, fileSize }, name)
          }}
        />
      )}
      {canDelete && (
        <DeleteBtn
          onClick={(e) => {
            e.stopPropagation()
            onDelete({ id, fileName })
          }}
        />
      )}
    </ActionWrapper>
  )
}

const ConnectedFilePanel = connect(() => ({
  sendUploadReminderStatus: sendUploadReminderStatus$,
  inspectedJob: inspectedJob$,
}))(FilePanel)

// eslint-disable-next-line complexity
function FilePanel({
  entity = 'GHD',
  type = 'download',
  name,
  id,
  containerStyle,
  documentType,
  operatingFile,
  multiOperatingDocumentType,
  onDownload = noop,
  onMultiDownload = noop,
  onUpload = noop,
  onDelete = noop,
  disabled,
  onRejected = noop,
  acceptFileType = `${values(CONSTANTS.FILE_MIME_TYPES)}`,
  isLoading = false,
  itemPrefix,
  sendUploadReminderStatus,
  inspectedJob,
  canDelete = true,
  canUpload = true,
  canDownload = true,
  folderList = [],
  ...props
}) {
  const variant = variantMapper[type]
  const [selected, setSelected] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const [{ value: documents }, { error }] = useField({
    name,
    id,
    ...props,
  })

  const folderSize = useMemo(
    () => documents.reduce((acc, { fileSize }) => acc + Number(fileSize), 0),
    [documents]
  )

  useUpdateEffect(() => {
    if (selected && documents) {
      setIsChecked(selected.length === documents.length)
    }
  }, [selected, documents])

  const { getRootProps, getInputProps } = useFileUpload({
    accept: acceptFileType,
    noDrag: true,
    noKeyboard: true,
    onDropRejected: useCallback((fileRejections) =>
      onRejected(name, createErrorMessage(fileRejections))
    ),
    disabled,
    onDropAccepted: useCallback((files) => onUpload(files, name)),
  })

  const handleSubmit = async () => {
    setShowModal(false)
  }

  // eslint-disable-next-line no-unused-vars
  const handleSelect = (key) => {
    if (selected.includes(key)) {
      setSelected((prevSelected) => prevSelected.filter((item) => item !== key))
    } else {
      setSelected([...selected, key])
    }
  }

  const handleToggle = () => {
    setIsChecked(!isChecked)

    if (!isChecked) {
      setSelected(documents.map(({ id }) => id))
    } else {
      setSelected([])
    }
  }

  return (
    <Panel style={containerStyle}>
      <TopBar>
        <div style={apply('flex items-center justify-center')}>
          <span>Total asset size: {FS(folderSize)}</span>
          {selected.length === 0 ? (
            <div style={apply('flex items-center justify-center')}>
              {/* <Search placeholder="Search" /> */}
            </div>
          ) : (
            <ItemWrapper>
              <ToggleBtn checked={isChecked} onChange={handleToggle} />
              <span>
                {selected.length} {selected.length > 1 ? 'files' : 'file'} selected
              </span>
              <CloseIcon style={apply('ml-2')} onClick={() => setSelected([])} />
            </ItemWrapper>
          )}
        </div>
        <div>
          {variant.allowUpload && canUpload && (
            <UploadBtn
              className={variantMapper.upload.icon}
              style={variantMapper.upload.style}
              disabled={isLoading}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
            </UploadBtn>
          )}
          {canDownload && selected.length === 0 ? (
            <ActionDownloadAll
              disabled={multiOperatingDocumentType}
              text={variantMapper.download.text}
              onClick={() => onMultiDownload(documents, documentType)}
            />
          ) : (
            <ItemWrapper>
              {/* <ActionDelete />
              <ActionAssign onClick={setShowModal} /> */}
            </ItemWrapper>
          )}
        </div>
      </TopBar>
      <Separator />
      {entity === 'Pilot' && inspectedJob?.status === JOB_STATUSES.DELIVERED && (
        <div style={apply('flex justify-end w-full')}>
          <SendUploadReminder
            style={apply('mr-0')}
            status={sendUploadReminderStatus}
            jobId={inspectedJob.id}
          />
        </div>
      )}
      <DataContainer
        isLoading={isLoading}
        noScroll={multiOperatingDocumentType === documentType}
      >
        {documents.map(({ id, fileName, fileSize = 0 }) => (
          <div key={id}>
            <Item
              selected={selected.includes(id)}
              // onClick={() => handleSelect(id)}
            >
              <ContentWrapper>
                <ItemWrapper>
                  {selected.includes(id) ? (
                    <CheckedIcon style={{ width: '16px', height: '16px' }} />
                  ) : (
                    <FileIcon style={{ width: '16px', height: '16px' }} />
                  )}

                  <FileName>{fileName}</FileName>
                </ItemWrapper>
                <Text>{FS(fileSize)}</Text>
                <Content
                  id={id}
                  fileName={fileName}
                  fileSize={fileSize}
                  name={name}
                  onDelete={onDelete}
                  onDownload={onDownload}
                  canDownload={canDownload}
                  canDelete={canDelete}
                />
              </ContentWrapper>
            </Item>
            <Separator />
          </div>
        ))}
        <PanelSpinnerContainer hidden={multiOperatingDocumentType !== documentType}>
          <Spinner size={64} thickness={7} />
        </PanelSpinnerContainer>
        <Overlay hidden={multiOperatingDocumentType !== documentType} />
      </DataContainer>
      <ErrorMessage error={error} visible={true} style={apply('text-sm')} />
      <AssignFileModal
        title={'Assign to Folder'}
        isOpen={showModal}
        loading={false}
        closeModal={() => setShowModal(false)}
        onConfirm={handleSubmit}
        folderList={folderList}
      />
    </Panel>
  )
}

export { ConnectedFilePanel as FilePanel }

import { isNil } from '@seedcloud/ramda-extra'
import { Formik as FormProvider } from 'formik'

import { EquipmentForm } from './Fields/Equipment'
import { PilotAndAviationDetail } from './Fields/PilotAndAviationDetails'
import { ServiceArea } from './Fields/ServiceArea'
import { PilotDetailsLayout } from './PilotDetailsLayout'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { DocumentsPanel } from 'components/common/DocumentsPanel'
import { FormAction } from 'components/company/common'

function PilotAviationDetails({
  editing,
  setEditing,
  initialValues,
  validationSchema,
  id,
  documents,
  pilotModule,
  paramsId,
  isInspectingPilot,
  inspectedPilot,
  isUpdatingIndividualPilot,
  onSubmit,
  isAdmin = false,
}) {
  if (isNil(inspectedPilot)) return <PilotDetailsLayout disableTabs loading />

  return (
    <FormLayoutProvider value={{ editing }}>
      <FormProvider
        id="pilotDetails"
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, resetForm }) => (
          <PilotDetailsLayout
            disableTabs={editing}
            loading={isInspectingPilot}
            footer={
              editing && (
                <FormAction
                  loading={isUpdatingIndividualPilot}
                  loadingText="Updating..."
                  okText="Save"
                  cancelText="Cancel"
                  onCancel={() => {
                    resetForm()
                    setEditing(false)
                  }}
                />
              )
            }
          >
            <PilotAndAviationDetail
              showButtonEdit={!paramsId}
              setEditing={setEditing}
              values={values}
            />
            <EquipmentForm
              values={values.equipments}
              name="equipments"
              title="Equipment Form"
            />
            <ServiceArea />
            {documents && !isAdmin ? (
              <DocumentsPanel
                documents={documents}
                ownerId={id}
                onDownloadClick={pilotModule.downloadDocument}
              />
            ) : null}
          </PilotDetailsLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

export { PilotAviationDetails }

import { path, prop } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { usePilotCompany } from '../common/hooks/usePilotCompany'

import { OrderDetails } from './OrderDetails'

import { Spinner } from 'components/common/Spinner'
import { DOCUMENT_TYPES } from 'constants/documents'
import { JOB_STATUSES } from 'constants/jobs'
import { API_TYPES } from 'lib/http-client'
import { apply, styled } from 'lib/styled'
import { createDocumentModule } from 'modules/document'
import { inspectedJob$, jobModule } from 'modules/job'
import { createOrganizationModule } from 'modules/organization'

const Container = styled.div(apply('flex flex-column w-full pb-8'))

const {
  module: documentModule,
  selectors: { jobFolderList$, fileEntities$ },
} = createDocumentModule(API_TYPES.WEB)

const {
  module: pilotCompanyModule,
  selectors: { inspectedOrganization$ },
} = createOrganizationModule(API_TYPES.WEB)

const ConnectedOrderDetailsContainer = connect(() => ({
  inspectedJob: inspectedJob$,
  jobFolderList: jobFolderList$,
  fileEntities: fileEntities$,
  pilotCompany: inspectedOrganization$,
}))(OrderDetailsContainer)

// eslint-disable-next-line complexity
function OrderDetailsContainer({
  inspectedJob = {},
  jobFolderList,
  fileEntities,
  pilotCompany,
}) {
  const history = useHistory()
  const { id } = useParams()

  const loading = !inspectedJob || id !== prop('id', inspectedJob) || !jobFolderList

  useEffect(() => {
    jobModule.inspectEngagedJob(id)

    // we use filterJobDocuments instead of fetchJobDocuments to be explicit about fetching ALL job document types.
    documentModule.filterJobDocuments(id, {
      documentTypes: [
        DOCUMENT_TYPES.JOB_TERMS,
        DOCUMENT_TYPES.JOB_JSEA,
        DOCUMENT_TYPES.JOB_CASA,
        DOCUMENT_TYPES.JOB_ASSET,
      ],
    })

    return () => {
      documentModule.reset()
    }
  }, [])

  usePilotCompany(pilotCompanyModule, inspectedJob, 'engagedBy')
  const companyName = path(['name'], pilotCompany)

  if (loading) {
    return (
      <Container>
        <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
      </Container>
    )
  }

  if (inspectedJob?.status !== JOB_STATUSES.COMPLETED) {
    history.goBack()
  }

  return (
    <OrderDetails
      history={history}
      inspectedJob={inspectedJob}
      fileEntities={fileEntities}
      jobFolderList={jobFolderList}
      companyName={companyName}
      documentModule={documentModule}
    />
  )
}

export { ConnectedOrderDetailsContainer }

const CURRENCY_VALUE = {
  AUD: 'AUD $',
  // TODO: Uncomment when mutiple currencies is available
  // USD: 'USD $',
  // EURO: 'EURO €',
}

const COUNTRY_LIST_APLHA_2 = {
  AU: 'Australia',
  NZ: 'New Zealand',
}

export { CURRENCY_VALUE, COUNTRY_LIST_APLHA_2 }

import { Tabs } from './Tabs'

import { Spinner } from 'components/common/Spinner'
import { Table } from 'components/common/withTable'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex flex-column w-full bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const ButtonContainer = styled.div(apply('justify-end flex m-6'))

function JobTable({
  selectedTab,
  jobFilters,
  isLoadingJobs,
  columns,
  data,
  onRowClicked,
  onSort,
  onTurnPage,
  tabs,
  isAdmin,
  filterBar,
}) {
  const {
    sortBy,
    paging: { startRow, next },
    limit,
  } = jobFilters

  return (
    <Container>
      <ButtonContainer>{filterBar}</ButtonContainer>

      <Tabs tabs={tabs} selectedTab={selectedTab} hideTopBorder={!isAdmin} />

      {isLoadingJobs ? (
        <Spinner size={24} thickness={4} style={{ margin: 'auto 0' }} />
      ) : (
        <Table
          columns={columns}
          data={data}
          onClick={onRowClicked}
          sortBy={sortBy}
          startRow={startRow}
          next={next}
          limit={limit}
          onSort={onSort}
          turnPage={onTurnPage}
          domain={'jobs'}
        />
      )}
    </Container>
  )
}

export { JobTable }

import { ConnectedPilotCategoriesAdminContainer } from './PilotCategoriesAdminContainer'
import { ConnectedPilotCategoriesContainer } from './PilotCategoriesContainer'

import { useIdentity } from 'lib/solta-id-react'

const PilotCategoriesGateway = () => {
  const { isAdmin } = useIdentity()
  return isAdmin ? (
    <ConnectedPilotCategoriesAdminContainer />
  ) : (
    <ConnectedPilotCategoriesContainer />
  )
}

export { PilotCategoriesGateway as PilotCategories }

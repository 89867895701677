import { isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { useState } from 'react'
import Modal from 'react-modal'

import { Button, CancelButton as CancelButtonBase } from 'components/common/Button'
import { useJobs } from 'components/common/context/JobContext'
import { Input } from 'components/common/Fields/Input'
import { styled, apply } from 'lib/styled'
import toaster from 'lib/toaster'
import { getFolderNumbers } from 'utils/getFolderNumbering'

const Root = styled.div(apply('text-black bg-white rounded-lg '), {
  padding: '64px 64px',
  width: '656px',
  maxWidth: '656px',
  alignItems: 'center',
})

const Title = styled.h1(apply('mb-6'), {
  fontWeight: 700,
  fontSize: '2rem',
  textAlign: 'left',
})

const Space = styled.div(apply('flex mt-4'), {
  gap: '1rem',
})

const ActionButton = styled(Button)(apply('flex-1'), {
  marginTop: '1rem',
})

const CancelButton = styled(CancelButtonBase)(apply('flex-1 mt-5'), {
  background: 'transparent',
  boxShadow: 'none',
  marginTop: '1rem',
})

const Label = styled.label(
  apply('mb-2 uppercase tracking-wide text-xs text-grey-dark normal-case')
)

Modal.setAppElement('#root')

function FolderNamingModal({
  isOpen,
  closeModal,
  title,
  onConfirm,
  loading,
  confirmText = 'Create',
  confirmLoadingText = 'Creating...',
}) {
  const { jobFolderList } = useJobs()

  const folderNumber = getFolderNumbers(jobFolderList)
  const [folderName, setFolderName] = useState(`Folder ${folderNumber()}`)

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      id="folderNamingModal"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(20, 20, 20, 0.2)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <Title>{title}</Title>
        <Label htmlFor="folderName">Folder Name</Label>
        <Input
          name="folderName"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
        />
        <Space>
          <CancelButton disabled={loading} onClick={() => closeModal()}>
            Cancel
          </CancelButton>
          <ActionButton
            disabled={loading}
            onClick={() => {
              const isDuplicate = jobFolderList.find(
                (folder) => folder.name === folderName
              )
              if (isNotNilOrEmpty(folderName) && !isDuplicate) {
                onConfirm(folderName)
                setFolderName(`Folder ${folderNumber()}`)
                closeModal()
              } else if (isDuplicate) {
                toaster.error('Folder name exist!')
              } else {
                toaster.error('Folder name is required')
              }
            }}
          >
            {loading ? confirmLoadingText : confirmText}
          </ActionButton>
        </Space>
      </Root>
    </Modal>
  )
}

export { FolderNamingModal }

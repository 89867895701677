import { useMemo } from 'react'

import { Table } from 'components/common/withTable'
import { createPilotCategoriesModule } from 'modules/pilotCategory'

const { module: pilotCategoryModule } = createPilotCategoriesModule()

const EquipmentTable = ({ currency, values, next, limit, startRow, id }) => {
  const onTurnPage = ({ turnNext }) =>
    pilotCategoryModule.fetchPilotCategories(null, {
      turnPage: true,
      turnNext,
      type: 'equipment',
      supplierId: id,
    })

  const EQUIPMENT_CATEGORY_COLUMNS = useMemo(
    () => [
      {
        Header: 'No',
        Cell: (prop) => prop.row.index + 1 + (startRow || 0),
        disableSortBy: true,
      },
      {
        Header: `Equipment Category`,
        accessor: 'description',
        disableSortBy: true,
      },
      {
        Header: `Make`,
        accessor: 'make',
        disableSortBy: true,
      },
      {
        Header: `Model`,
        accessor: 'model',
        disableSortBy: true,
      },
      { Header: `Rate  (${currency}/hr)`, accessor: 'rate', disableSortBy: true },
    ],
    [currency, values, startRow]
  )

  return (
    <Table
      columns={EQUIPMENT_CATEGORY_COLUMNS}
      data={values}
      next={next}
      limit={limit}
      turnPage={onTurnPage}
      startRow={startRow}
    />
  )
}

export { EquipmentTable }

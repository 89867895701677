import { prepend } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { SupplierListItem } from './SupplierListItem'
import { SupplierSelectField } from './SupplierSelectField'

import { Button as ButtonBase } from 'components/common/Button'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { PlusIcon } from 'components/icons'
import { FilterInput } from 'components/supplier/common/FilterInput'
import { SUPPLIER_TYPES_DISPLAY_NAMES } from 'constants/supplier'
import { styled, apply } from 'lib/styled'
import {
  filterType$,
  isFetchingSuppliers$,
  supplierList$,
  supplierModule,
} from 'modules/supplier'
import { useDebounce } from 'utils/hooks/useDebounce'

const Container = styled.div(
  apply('flex flex-column w-1/3 min-w-10 max-w-24 mr-6 bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const FilterContainer = styled.div(
  apply('p-4 border-0 border-b-1 border-solid border-grey-lighter')
)
const InputContainer = styled.div(apply('flex mb-4'))

const CreateSupplierButton = styled(ButtonBase)(
  apply('w-full mb-4 flex items-center rounded-lg justify-center', {
    boxSizing: 'border-box',
    height: '48px',
    gap: '.5rem',
    background: '#009DE4',
    color: 'white',
  })
)

const List = styled.ol(
  apply('p-5', {
    overflow: 'auto',
    listStyleType: 'none',
  })
)

const onFilterTypeChanged = ({ value: filterType }) => {
  supplierModule.filterSuppliers(null, { filterType })
}

const onSearchChanged = (value) => {
  supplierModule.filterSuppliers(null, { filterQuery: value || '' })
}

const options = prepend(
  { value: undefined, label: 'Any role' },
  Object.entries(SUPPLIER_TYPES_DISPLAY_NAMES).map(([value, label]) => ({
    label,
    value,
  }))
)

function SupplierList({
  supplierList,
  isFetchingSuppliers,
  inspectedEntity,
  filterType,
  ...props
}) {
  useLayoutEffect(() => {
    supplierModule.fetchSuppliers()
    return () => {
      supplierModule.reset()
    }
  }, [])

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    onSearchChanged(debouncedSearch)
  }, [debouncedSearch])

  return (
    <Container {...props}>
      <FilterContainer>
        <Link to="/supplier/new">
          <CreateSupplierButton>
            <PlusIcon /> ADD SUPPLIER
          </CreateSupplierButton>
        </Link>
        <InputContainer>
          <FilterInput
            onChange={setSearch}
            containerProps={{ style: apply('flex-1') }}
          />
        </InputContainer>
        <SupplierSelectField
          options={options}
          onChange={onFilterTypeChanged}
          selected={filterType}
        />
      </FilterContainer>

      <Loader
        loading={isFetchingSuppliers}
        renderPlaceholder={<Spinner size={32} thickness={6} style={apply('mt-4')} />}
      >
        <List>
          {supplierList.map(
            ({ id, abn, company: { invitationStatus: status, companyName } }) => (
              <SupplierListItem
                key={id}
                id={id}
                companyName={companyName}
                companyAbn={abn}
                status={status}
              />
            )
          )}
        </List>
      </Loader>
    </Container>
  )
}

const ConnectedSupplierList = connect(() => ({
  supplierList: supplierList$,
  isFetchingSuppliers: isFetchingSuppliers$,
  filterType: filterType$,
}))(SupplierList)

export { ConnectedSupplierList as SupplierList }

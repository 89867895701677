import { useContext } from 'react'

import { FormLayoutContext } from '../../../common/context/FormLayoutContext'

import { SelectField as FormSelect } from 'components/common/SelectField'
import { styled, apply } from 'lib/styled'

const BaseTextField = styled.div(apply('flex flex-column justify-center items-start'), {
  gap: '1em',
})

const TextLabel = styled.span({
  fontSize: '0.8125rem',
  color: '#555555',
  fontWeight: 400,
})

const TextValue = styled.p({
  fontSize: '1.0625rem',
  color: '#000000',
  fontWeight: 400,
  lineHeight: '20.4px',
  marginBottom: '14px',
})

const SelectField = ({ label, value, required, name, options, disabled, ...props }) => {
  const { editing } = useContext(FormLayoutContext)

  return editing ? (
    <FormSelect
      required={required}
      name={name}
      label={label}
      options={options}
      isDisabled={disabled}
      newStyle
      {...props}
    />
  ) : (
    <BaseTextField>
      <TextLabel>
        {label}
        {required && '*'}
      </TextLabel>
      <TextValue>{value || 'No Data'}</TextValue>
    </BaseTextField>
  )
}

export { SelectField }

import { SearchInput } from 'components/common/SearchInput'

function FilterInput({ filterQuery, containerProps, onChange }) {
  return (
    <SearchInput
      value={filterQuery}
      placeholder="Search by name, email, phone"
      onChange={onChange}
      containerProps={containerProps}
    />
  )
}

export { FilterInput }

import {
  isNilOrEmpty,
  mergeRight,
  path,
  pick,
  pipe,
  renameKeys,
} from '@seedcloud/ramda-extra'

import { authModule } from 'modules/auth'

export const getOrganizationId = async () => {
  const { user } = authModule.getState()

  const organizationId = path(['companyId'], user)

  const pickAndRenamedUser = pipe(
    pick(['company', 'companyId']),
    renameKeys({ company: 'companyName' }),
    mergeRight({ organizationId })
  )

  if (isNilOrEmpty(user)) {
    const response = await authModule.getProfile()
    return pickAndRenamedUser(response)
  }

  return pickAndRenamedUser(user)
}

export const getCompanyAndOrganizationId = async () => getOrganizationId()

import { ConnectedOrderDetailsContainer as ConnectedOrderDetailsAdminContainer } from './OrderDetailsAdminContainer'
import { ConnectedOrderDetailsContainer } from './OrderDetailsContainer'

import { useIdentity } from 'lib/solta-id-react'

const OrderDetailsGateway = () => {
  const { isAdmin } = useIdentity()
  return isAdmin ? (
    <ConnectedOrderDetailsAdminContainer />
  ) : (
    <ConnectedOrderDetailsContainer />
  )
}

export { OrderDetailsGateway as OrderDetails }

import { isNilOrEmpty } from '@seedcloud/ramda-extra'

const addSearchParams = (paramsObj, projectionFn) => (searchParams) => {
  const _searchParams = searchParams

  const [key] = Object.keys(paramsObj)

  if (isNilOrEmpty(paramsObj[key])) return _searchParams
  const val = projectionFn(paramsObj[key])

  if (isNilOrEmpty(val)) return _searchParams
  _searchParams[key] = val

  return _searchParams
}

export { addSearchParams }

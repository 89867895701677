import { saveAs } from 'file-saver'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import TinyQueue from 'tinyqueue'

import { useJobs } from 'components/common/context/JobContext'
import { DeleteFileModal } from 'components/common/DeleteFileModal'
import { FilePanel } from 'components/common/FilePanel'
import { FilePanelStaff } from 'components/common/FilePanelStaff'
import { DOCUMENT_TYPES } from 'constants/documents'
import { styled, apply } from 'lib/styled'
import { useFileModal } from 'utils/hooks'

const Container = styled.div(apply('flex flex-row my-2 items-center justify-between'))

function JobDocuments({ type, jobReference, folderList }) {
  const {
    onDownloadJobDocument,
    onUploadJobDocument,
    onDeleteJobDocument,
    isOperatingFile,
    isAdmin,
  } = useJobs()

  const { setFieldError } = useFormikContext()
  const [operatingFile, setOperatingFile] = useState(null)
  const [multiOperatingDocumentType, setMultiOperatingDocumentType] = useState(null)
  const [deleteFileModal, setDeleteFileModal] = useFileModal()

  const onFileDeleted = ({ id: selectedDocId, fileName }) => {
    setDeleteFileModal({ isOpened: true, fileName, fileId: selectedDocId })
  }
  const onFileDownload = async ({ id: selectedDocId, fileName, fileSize }) => {
    setOperatingFile(selectedDocId)
    const file = await onDownloadJobDocument(selectedDocId, fileSize)
    if (file) saveAs(file, fileName)
    setOperatingFile(null)
  }

  const onMultiDownload = async (documents, fileType) => {
    setMultiOperatingDocumentType(fileType)

    const queue = new TinyQueue([...documents])

    while (queue.length) {
      const document = queue.peek()
      // eslint-disable-next-line
      const file = await onDownloadJobDocument(document.id)
      queue.pop()
      saveAs(file, document.fileName)
    }

    setMultiOperatingDocumentType(null)
  }

  const onFileUpload = async (files, fieldName) => {
    setMultiOperatingDocumentType(DOCUMENT_TYPES.JOB_ASSET)
    await Promise.all(
      files.map((file) => onUploadJobDocument(file, DOCUMENT_TYPES.JOB_ASSET))
    )
    setFieldError(fieldName, undefined)
    setMultiOperatingDocumentType(null)
  }

  const onRejected = (fieldName, errorMsg) => setFieldError(fieldName, errorMsg)

  return (
    <Container>
      {type === 'pilot' && (
        <FilePanel
          entity="Pilot"
          containerStyle={apply('flex-1')}
          type="download"
          documentType={DOCUMENT_TYPES.JOB_ASSET}
          operatingFile={operatingFile}
          multiOperatingDocumentType={multiOperatingDocumentType}
          name="documents.uploadedByPilot"
          onDownload={onFileDownload}
          onMultiDownload={onMultiDownload}
          onDelete={onFileDeleted}
          itemPrefix={jobReference}
          isLoading={isOperatingFile}
          folderList={folderList}
        />
      )}
      {type === 'staff' && (
        <FilePanelStaff
          entity="Staff"
          containerStyle={apply('flex-1')}
          type="upload"
          name="documents.uploadedByOrganization"
          documentType={DOCUMENT_TYPES.JOB_ASSET}
          operatingFile={operatingFile}
          multiOperatingDocumentType={multiOperatingDocumentType}
          onMultiDownload={onMultiDownload}
          onDelete={onFileDeleted}
          onUpload={onFileUpload}
          onDownload={onFileDownload}
          onRejected={onRejected}
          itemPrefix={jobReference}
          canUpload={isAdmin}
          isLoading={isOperatingFile}
        />
      )}
      <DeleteFileModal
        isOpen={deleteFileModal.isOpened}
        setIsOpen={(open) => setDeleteFileModal({ isOpened: open })}
        fileName={deleteFileModal.fileName}
        deleteHandler={async () => {
          setOperatingFile(deleteFileModal.fileId)
          await onDeleteJobDocument(deleteFileModal.fileId)
          setOperatingFile(null)
        }}
      />
    </Container>
  )
}

export { JobDocuments }

const SUPPLIER_LABELS = {
  ACTIVE: 'active',
  UPDATED_DETAILS: 'updated-information',
  PENDING_ACTIVATION: 'pending-activation',
  INVITED: 'invited',
  PENDING_PARTNERSHIP: 'pending-partnership',
}

const SUPPLIER_LABEL_DISPLAY = {
  [SUPPLIER_LABELS.ACTIVE]: 'Active',
  [SUPPLIER_LABELS.UPDATED_DETAILS]: 'Company has updated informations',
  [SUPPLIER_LABELS.PENDING_ACTIVATION]: 'Pending Company Activation',
  [SUPPLIER_LABELS.INVITED]: 'Pending Company Activation',
  [SUPPLIER_LABELS.PENDING_PARTNERSHIP]: 'Need to review company partnership',
}

const SUPPLIER_LABEL_TEXT = {
  [SUPPLIER_LABELS.ACTIVE]: 'Active',
  [SUPPLIER_LABELS.UPDATED_DETAILS]: 'Need to Review',
  [SUPPLIER_LABELS.PENDING_ACTIVATION]: 'Pending',
  [SUPPLIER_LABELS.INVITED]: 'Pending',
  [SUPPLIER_LABELS.PENDING_PARTNERSHIP]: 'Need to Review',
}

const SUPPLIER_TYPES = {
  PILOT: 'pilot',
  PROFESSIONAL: 'professional',
}

const SUPPLIER_TYPES_DISPLAY_NAMES = {
  [SUPPLIER_TYPES.PILOT]: 'Pilot Services',
  [SUPPLIER_TYPES.PROFESSIONAL]: 'Professional Services',
}

export {
  SUPPLIER_LABELS,
  SUPPLIER_LABEL_DISPLAY,
  SUPPLIER_LABEL_TEXT,
  SUPPLIER_TYPES,
  SUPPLIER_TYPES_DISPLAY_NAMES,
}

import { useEffect } from 'react'

import { ConnectedJobTable as ConnectedJobTableAdmin } from './JobTableAdminContainer'
import { ConnectedJobTable } from './JobTableContainer'

import { useIdentity } from 'lib/solta-id-react'
import { jobModule } from 'modules/job'

const JobTableGateway = () => {
  const { isAdmin, role } = useIdentity()

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (isAdmin) {
      jobModule.setSelectedTab(null, 'new')
    }
    if (!isAdmin && role === 'customer') {
      jobModule.setSelectedTab(null, 'completed')
    }
    if (!isAdmin && role !== 'customer') {
      jobModule.setSelectedTab(null, 'published')
    }
  })

  return isAdmin ? <ConnectedJobTableAdmin /> : <ConnectedJobTable />
}

export { JobTableGateway as JobTable }

import CONSTANTS from '@seedcloud/constants'
import { isNilOrEmpty } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import * as Validator from 'yup'

import { StaffDetailsForm } from './StaffDetailsForm'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { TabEditing } from 'components/common/TabEditing'
import { styled, apply } from 'lib/styled'
import {
  staffModule,
  inspectedStaff$,
  isInspectingStaff$,
  isUploadingImage$,
} from 'modules/staff'

const { object, string } = Validator
const requiredString = string().required('This field is required')

const validationSchema = object({
  userDetails: object({
    firstName: requiredString,
    lastName: requiredString,
    phoneNumber: requiredString.matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please input phone number with country code ex: +6123123'
    ),
    email: requiredString.email('Not a valid email address'),
  }),
  role: requiredString,
})

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const Scrollable = styled.div(
  apply('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)

const { inspectStaff, resetStaffFilter, fetchStaff } = staffModule

const ConnectedStaffDetails = connect(() => ({
  isInspectingStaff: isInspectingStaff$,
  inspectedStaff: inspectedStaff$,
  isUploadingImage: isUploadingImage$,
}))(StaffDetails)

function StaffDetails({
  isInspectingStaff,
  staffEntities,
  isUploadingImage,
  inspectedStaff,
  ...props
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [reRenderKey, setReRenderKey] = useState(false)

  const location = useLocation()
  const { id } = useParams()
  const isNew = location?.state?.isNew

  useEffect(() => {
    setIsEditing(false)
  }, [id])

  useEffect(() => {
    inspectStaff(id)
  }, [id])

  useEffect(() => {
    if (isNew) {
      resetStaffFilter()
      fetchStaff(null, { turnPage: false })
    }
  }, [isNew])

  if (isNilOrEmpty(inspectedStaff)) return null

  const { role, userId, isActive } = inspectedStaff
  const { firstName, lastName, phoneNumber, email, avatar } = userId

  return (
    <Container {...props}>
      <FormProvider
        id="staffDetails"
        enableReinitialize
        initialValues={{
          userDetails: {
            firstName,
            lastName,
            phoneNumber,
            email,
          },
          role,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await staffModule.updateStaff(id, values)
          setIsEditing(false)
        }}
      >
        <Loader
          loading={isInspectingStaff}
          renderPlaceholder={
            <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
          }
        >
          <Scrollable>
            <Form key={reRenderKey}>
              <Header>
                <Title>Staff Details</Title>
                <TabEditing
                  style={{ marginLeft: 'auto' }}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  disabled={isUploadingImage}
                  customHandleCancel={() => setReRenderKey(!reRenderKey)}
                />
              </Header>
              <StaffDetailsForm
                id={id}
                avatarSrc={avatar}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                userId={userId?.id}
                isActive={isActive}
              />
            </Form>
          </Scrollable>
        </Loader>
      </FormProvider>
    </Container>
  )
}

export { ConnectedStaffDetails as StaffDetails }

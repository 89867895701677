import { useHistory } from 'react-router-dom'

import { ReactComponent as Drone3d } from 'assets/drone-3d.svg'
import { Button as ButtonBase } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Background = styled.div(
  apply('flex flex-column justify-center items-center', {
    backgroundColor: '#EDF2F7',
    height: '100vh',
  })
)
const Container = styled.div(
  apply('flex flex-column justify-center items-center  px-10 py-16', {
    backgroundColor: '#FFFFFF',
    margin: '155px auto',
    width: '646px',
    borderRadius: '16px',
    '@media (max-width: 768px)': {
      width: '90%',
      margin: '50px auto',
    },
  })
)
const Title = styled.h2(apply('mt-6 text-4xl font-bold text-center'))
const Description = styled.p(
  apply('m-0 text-gray-600 text-center', {
    color: '#595959',
  })
)

const RegisterButton = styled(ButtonBase)(
  apply('flex justify-center my-8 items-center', {
    color: '#009DE4',
    boxSizing: 'content-box',
    padding: '4px 24px',
    borderRadius: '8px',
    border: '1px solid #009DE4',
    backgroundColor: 'transparent',
  })
)

const ImageContainer = styled.div(
  apply('flex justify-center items-center pl-16', {
    width: '100%',
  })
)

function RegisterSuccess() {
  const history = useHistory()

  return (
    <Background>
      <Container>
        <ImageContainer>
          <Drone3d />
        </ImageContainer>
        <Title>Thank you for Signing Up!</Title>
        <Description>
          Please check your email to activate your NearbySky account.
        </Description>
        <RegisterButton onClick={() => history.push('/')}>
          Return to homepage
        </RegisterButton>
      </Container>
    </Background>
  )
}

export { RegisterSuccess }

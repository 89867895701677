import { defaultTo } from '@seedcloud/ramda-extra'
import { useField } from 'formik'
import TextAreaBase from 'react-textarea-autosize'

import { ErrorMessage } from 'components/common/ErrorMessage'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex flex-column'))
const TopBar = styled.div(apply('flex justify-between'))
const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const Input = styled(TextAreaBase)(
  apply('bg-white text-lg text-dark rounded-md px-3 py-2'),
  ({ resize }) => ({ resize: resize || 'vertical' }),
  {
    '&:read-only': {
      backgroundColor: 'rgb(243 244 246)',
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
      border: '0',
    },
    border: '1px solid #CCCCCC',
  }
)
const CharacterLimit = styled(Label)(apply('ml-a text-gray-400'))

function TextAreaField({
  name,
  id,
  label,
  noLabel = false,
  containerStyle,
  characterLimit = 0,
  resize,
  ...props
}) {
  const [{ value, onChange: onFieldChange, ...fieldProps }, { touched, error }] =
    useField({
      name,
      id,
      ...props,
    })

  const useCharacterLimit = characterLimit > 0

  function handleChange(event) {
    if (useCharacterLimit && event.target.value.length > characterLimit) {
      return
    }

    onFieldChange(event)
  }

  return (
    <Container style={containerStyle}>
      <TopBar>
        {!noLabel && <Label htmlFor={name || id}>{label}</Label>}
        {useCharacterLimit && (
          <CharacterLimit>
            {characterLimit - defaultTo('')(value).length}
          </CharacterLimit>
        )}
      </TopBar>
      <Input
        value={value}
        onChange={handleChange}
        {...fieldProps}
        {...props}
        resize={resize}
      />

      <ErrorMessage visible={touched} error={error} />
    </Container>
  )
}

export { TextAreaField }

import { Switch, Route } from 'react-router-dom'

import { ApplicationsManager } from 'components/applications/ApplicationsManager'
import { ClaimCompanyManager } from 'components/claim/ClaimCompanyManager'
import { NotFoundPage } from 'components/common/NotFoundPage'
import { ProtectedRoute } from 'components/common/ProtectedRoute'
import { withNavigation } from 'components/common/withNavigation'
import { CompanyManager } from 'components/company/CompanyManager'
import { CustomerProfileManager } from 'components/customers/CustomerProfileManager'
import { JobsManager } from 'components/jobs/JobsManager'
import { CompanyManager as LegacyCompanyManager } from 'components/legacy-company/CompanyManager'
import { PilotDetailsManager } from 'components/pilots/PilotDetailsManager'
import { PilotsManager } from 'components/pilots/PilotsManager'
import { ProductsManager } from 'components/products/ProductsManager'
import { StaffManager } from 'components/staff/StaffManager'
import { SubscriptionPlansManager } from 'components/subscription-plans/SubscriptionPlansManager'
import { SupplierManager } from 'components/supplier/SupplierManager'
import { RESOURCES } from 'constants/resources'
import { ROLES } from 'constants/roles'
import { usePermissions } from 'lib/permissions'
import { useIdentity } from 'lib/solta-id-react'

const resourceToPage = {
  [RESOURCES.PILOT_APPLICATION]: ApplicationsManager,
  [RESOURCES.PILOT_DETAILS]: PilotsManager,
  [RESOURCES.JOB]: JobsManager,
  [RESOURCES.PRODUCT]: ProductsManager,
  [RESOURCES.STAFF]: StaffManager,
  [RESOURCES.CLAIM_COMPANY]: ClaimCompanyManager,
  [RESOURCES.MY_COMPANY]: CompanyManager,
  [RESOURCES.MY_PILOT_INFO]: PilotDetailsManager,
  [RESOURCES.MY_PROFILE]: CustomerProfileManager,
  [RESOURCES.SUBSCRIPTION_PLANS]: SubscriptionPlansManager,
}

export const getDefaultResource = (role) => {
  if ([ROLES.SUPPLIER_ADMIN, ROLES.PLATFORM_ADMIN].includes(role)) {
    return RESOURCES.MY_COMPANY
  }

  if ([ROLES.USER, ROLES.CUSTOMER].includes(role)) {
    return RESOURCES.JOB
  }

  return RESOURCES.PILOT_APPLICATION
}

const lookUpIntitialPage = (role) => {
  const resource = getDefaultResource(role)
  if (resource) {
    return resourceToPage[resource]
  }
  return NotFoundPage
}

const Dashboards = withNavigation(() => {
  const permissions = usePermissions()
  const { role } = useIdentity()

  return (
    <Switch>
      <ProtectedRoute
        path={['/applications', '/application']}
        component={ApplicationsManager}
        allowed={permissions.can('read', RESOURCES.PILOT_APPLICATION)}
      />

      <ProtectedRoute
        path={['/pilots', '/pilot']}
        component={PilotsManager}
        allowed={permissions.can('read', RESOURCES.PILOT_DETAILS)}
      />

      <ProtectedRoute
        path="/company"
        component={CompanyManager}
        allowed={permissions.can('read', RESOURCES.MY_COMPANY)}
      />

      <ProtectedRoute
        path={['/jobs', '/job']}
        component={JobsManager}
        allowed={permissions.can('read', RESOURCES.JOB)}
      />

      <ProtectedRoute
        path={['/products', '/product']}
        component={ProductsManager}
        allowed={permissions.can('read', RESOURCES.PRODUCT)}
      />

      <ProtectedRoute
        path="/staff"
        component={StaffManager}
        allowed={permissions.can('read', RESOURCES.STAFF)}
      />

      <ProtectedRoute
        path={['/suppliers', '/supplier']}
        component={SupplierManager}
        allowed={permissions.can('read', RESOURCES.SUPPLIER)}
      />

      <ProtectedRoute
        path="/customer"
        component={LegacyCompanyManager}
        allowed={permissions.can('read', RESOURCES.STAFF)}
      />

      <ProtectedRoute
        path="/my-pilot-info"
        component={PilotDetailsManager}
        allowed={permissions.can('read', RESOURCES.MY_PILOT_INFO)}
      />
      <ProtectedRoute
        path="/my-profile"
        component={CustomerProfileManager}
        allowed={permissions.can('read', RESOURCES.MY_PROFILE)}
      />
      <ProtectedRoute
        path="/subscription-plans"
        component={SubscriptionPlansManager}
        allowed={permissions.can('read', RESOURCES.SUBSCRIPTION_PLANS)}
      />

      <Route exact path="/" component={lookUpIntitialPage(role)} />

      <Route path="/" component={NotFoundPage} />
    </Switch>
  )
})

export { Dashboards }

const { JOB_STATUSES } = require('constants/jobs')

export function createJobtabs({
  setSelectedTab,
  filterJobs,
  filterAvailable,
  exclude = [],
}) {
  const defaultFilters = {
    limit: 15,
    sortOrder: 'desc',
    paging: {
      startRow: undefined,
      next: undefined,
    },
  }

  const tabs = [
    {
      title: 'New',
      id: 'new',
      onClick: () => {
        setSelectedTab(null, 'new')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.NEW],
          sortBy: 'createdAt',
        })
      },
      testId: 'jobs-table-new-tab',
    },
    {
      title: 'Queue',
      id: 'queue',
      onClick: () => {
        setSelectedTab(null, 'queue')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.QUEUE],
          sortBy: 'createdAt',
        })
      },
      testId: 'jobs-table-queue-tab',
    },
    {
      title: 'Published',
      id: 'published',
      onClick: () => {
        setSelectedTab(null, 'published')
        const filterFn = filterAvailable || filterJobs
        filterFn(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.PUBLISHED],
          sortBy: 'createdAt',
        })
      },
      testId: 'jobs-table-published-tab',
    },
    {
      title: 'Engaged',
      id: 'engaged',
      onClick: () => {
        setSelectedTab(null, 'engaged')
        const status = !filterAvailable
          ? { statuses: [JOB_STATUSES.ENGAGED] }
          : { status: JOB_STATUSES.ENGAGED }
        filterJobs(null, {
          ...defaultFilters,
          ...status,
          sortBy: 'engagedAt',
        })
      },
      testId: 'jobs-table-engaged-tab',
    },
    {
      title: 'Delivered',
      id: 'delivered',
      onClick: () => {
        setSelectedTab(null, 'delivered')
        const status = !filterAvailable
          ? { statuses: [JOB_STATUSES.DELIVERED] }
          : { status: JOB_STATUSES.DELIVERED }

        filterJobs(null, {
          ...defaultFilters,
          ...status,
          sortBy: 'updatedAt',
        })
      },
      testId: 'jobs-table-delivered-tab',
    },
    {
      title: 'In Review',
      id: 'in_review',
      onClick: () => {
        setSelectedTab(null, 'in_review')

        const status = !filterAvailable
          ? { statuses: [JOB_STATUSES.IN_REVIEW, JOB_STATUSES.IN_REVIEW_NETWORK] }
          : { status: JOB_STATUSES.IN_REVIEW }
        filterJobs(null, {
          ...defaultFilters,
          ...status,
          sortBy: 'updatedAt',
        })
      },
      testId: 'jobs-table-in-review-tab',
    },
    {
      title: 'Completed',
      id: 'completed',
      onClick: () => {
        setSelectedTab(null, 'completed')
        const status = !filterAvailable
          ? { statuses: [JOB_STATUSES.COMPLETED] }
          : { status: JOB_STATUSES.COMPLETED }
        filterJobs(null, {
          ...defaultFilters,
          ...status,
          sortBy: 'updatedAt',
        })
      },
      testId: 'jobs-table-completed-tab',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
      onClick: () => {
        setSelectedTab(null, 'cancelled')
        const status = !filterAvailable
          ? { statuses: [JOB_STATUSES.CANCELLED] }
          : { status: JOB_STATUSES.CANCELLED }
        filterJobs(null, {
          ...defaultFilters,
          ...status,
          sortBy: 'cancelledAt',
        })
      },
      testId: 'jobs-table-cancelled-tab',
    },
    {
      title: 'Disputed',
      id: 'disputed',
      onClick: () => {
        setSelectedTab(null, 'disputed')
        const status = !filterAvailable
          ? { statuses: [JOB_STATUSES.DISPUTED] }
          : { status: JOB_STATUSES.DISPUTED }
        filterJobs(null, {
          ...defaultFilters,
          ...status,
          sortBy: 'engagedAt',
        })
      },
      testId: 'jobs-table-disputed-tab',
    },
  ]

  return tabs.filter((tab) => !exclude.includes(tab.id))
}

import { companyAdminModule } from './admin/module'
import { companyAdminSelectors } from './admin/selectors'
import { CompanyService as CompanyAdminService } from './admin/service'
import { companyModule } from './web/module'
import { companySelectors } from './web/selectors'
import { CompanyService } from './web/service'

import { API_TYPES } from 'lib/http-client'
import { createModule } from 'utils/createModule'

export const createCompanyModule = (type = API_TYPES.ADMIN) => {
  if (type === API_TYPES.ADMIN) {
    return createModule(companyAdminModule, companyAdminSelectors, CompanyAdminService)
  }

  return createModule(companyModule, companySelectors, CompanyService)
}

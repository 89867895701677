import { documentModule } from './module'
import { documentSelectors } from './selectors'
import { documentService } from './service'

import { API_TYPES, adminApi, baseApi } from 'lib/http-client'
import { createModule } from 'utils/createModule'

export const createDocumentModule = (type = API_TYPES.ADMIN) => {
  const documentAdminService = documentService(adminApi)
  const documentAdminModule = documentModule(documentAdminService)
  const documentAdminSelectors = documentSelectors(documentAdminModule)

  const documentWebService = documentService(baseApi)
  const documentWebModule = documentModule(documentWebService)
  const documentWebSelectors = documentSelectors(documentWebModule)

  if (type === API_TYPES.ADMIN) {
    return createModule(
      documentAdminModule,
      documentAdminSelectors,
      documentAdminService
    )
  }

  return createModule(documentWebModule, documentWebSelectors, documentWebService)
}

import { adminApi, baseApi } from 'lib/http-client'

function OrganizationService() {
  return {
    async fetchOrganization(id) {
      const response = await adminApi.get(`organizations/${id}`).json()
      return response
    },
    async read(orgId, companyId) {
      return baseApi
        .get(`organizations/${orgId}`, {
          searchParams: {
            companyId,
          },
        })
        .json()
    },
    async update(orgId, companyId, payload) {
      return baseApi
        .patch(`organizations/${orgId}`, {
          json: payload,
          searchParams: {
            companyId,
          },
        })
        .json()
    },
  }
}

export { OrganizationService }

import { Hamburger } from './Hamburger'

import { Button as ButtonBase } from 'components/common/Button'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply(
    'relative flex flex-row items-center justify-center w-full bg-white pl-3 pr-6 py-8 h-3 border-0 border-solid border-b-1 border-grey-light'
  )
)

const LogoutBtn = styled(ButtonBase)(
  apply('bg-transparent border-0 border-1 border-grey-light shadow-none text-grey-400')
)

const Title = styled.h1(apply('text-xl font-normal tracking-wide m-0 ml-2'))

function NavigationBar({ title, onSidebarToggle, showSidebar }) {
  const { isAuthenticated, logout, user } = useIdentity()

  return (
    <Container>
      <Hamburger onClick={onSidebarToggle} isOpen={showSidebar} />
      <Title>{title}</Title>

      {isAuthenticated && (
        <div style={{ marginLeft: 'auto' }}>
          Logged in as {user.name}
          <LogoutBtn onClick={logout} style={apply('ml-2')}>
            Log out
          </LogoutBtn>
        </div>
      )}
    </Container>
  )
}

export { NavigationBar }

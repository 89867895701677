import { prepend } from '@seedcloud/ramda-extra'

import { useFilter } from './FilterBar'

import { SelectField } from 'components/common/SelectField'
import { PUBLISH_TYPES_DISPLAY_NAMES } from 'constants/publishType'

const publishTypeOptions = prepend(
  { value: undefined, label: 'Any Publish Type' },
  Object.entries(PUBLISH_TYPES_DISPLAY_NAMES).map(([value, label]) => ({
    label,
    value,
  }))
)

const PublishType = () => {
  const { onSubmit } = useFilter()

  const handlePublishTypeChange = (publishType) =>
    onSubmit({ publishType: publishType.value })

  return (
    <SelectField
      name="publishType"
      type="select"
      optional
      emptyOption={{ value: undefined }}
      onChange={handlePublishTypeChange}
      options={publishTypeOptions}
      selectStyles={{ valueContainer: { height: '46px' } }}
      size="lg"
      newStyle
    />
  )
}

export { PublishType }

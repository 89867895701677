import { useLayoutEffect, useState } from 'react'

import { Tabs } from './ui'

const TabItems = Tabs.Item.withComponent('a')

const noop = () => {}

const ScrollTabs = ({ deps = [] }) => {
  const [tabItems, setTabItems] = useState([])
  const [activeTabs, setActiveTabs] = useState(undefined)

  useLayoutEffect(() => {
    const tabItemsNode = document.querySelectorAll('[data-tabs]')

    if (!tabItemsNode.length) return noop

    const observerCallback = (entries) => {
      const { target, isIntersecting } = entries[0]

      const id = target.getAttribute('id')

      if (isIntersecting) {
        setActiveTabs(id)
      }
    }

    const observers = []

    // Creating observers for each tab items
    tabItemsNode.forEach((item, i) => {
      const threshold = i === 0 ? 0 : -200
      const observer = new IntersectionObserver(observerCallback, {
        rootMargin: `${threshold}px`,
      })

      observers.push(observer)
      observer.observe(item)

      setTabItems((prev) => [...prev, { id: item.id, name: item.textContent }])
    })

    return () => {
      setTabItems([])
      // Cleanup each of the observers
      observers.forEach((observer) => observer.disconnect())
    }
  }, deps)

  const handleClick = (id) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Tabs style={{ paddingLeft: '1.8rem' }}>
      {tabItems.map(({ id, name }) => (
        <TabItems key={id} selected={activeTabs === id} onClick={() => handleClick(id)}>
          {name}
        </TabItems>
      ))}
    </Tabs>
  )
}

export { ScrollTabs }

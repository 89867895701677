import { pick, pipe, isNilOrEmpty, omitBy } from '@seedcloud/ramda-extra'

import { adminApi, http } from 'lib/http-client'
import { downloadInChunks } from 'lib/multipart/download'
import { normalizeFileTree } from 'utils/normalizeFileTree'
import { stringifyParams } from 'utils/stringifyParams'

function documentService(api) {
  return {
    async fetchJobDocuments(id, filters = { limit: 100 }) {
      const searchParams = pipe(
        omitBy(isNilOrEmpty),
        pick(['q', 'limit', 'next', 'sortBy', 'sortOrder', 'documentTypes'])
      )(filters)

      const response = await api
        .get(`organization-jobs/${id}/documents?${stringifyParams(searchParams)}`)
        .json()

      const { paging } = response

      const { fileEntities, folderEntities, folderOrders } = normalizeFileTree(
        response,
        id
      )

      return {
        fileEntities,
        folderEntities,
        folderOrders,
        next: paging.next,
      }
    },

    async downloadJobDocument(id, jobId) {
      const { signedUrl } = await api
        .get(`organization-jobs/${jobId}/download-documents/${id}`)
        .json()

      return http.get(signedUrl).blob()
    },

    async downloadMultipartJobDocument(id, jobId, fileSize, onProgress, folderId) {
      const { signedUrl } = await api
        .get(`organization-jobs/${jobId}/download-documents/${id}`)
        .json()

      return downloadInChunks(signedUrl, {
        id,
        fileSize,
        onProgress,
        folderId,
      })
    },

    deleteJobDocument(id, jobId) {
      return adminApi.delete(`organization-jobs/${jobId}/documents/${id}`)
    },

    renameJobDocument(id, jobId, newFileName) {
      const config = {
        json: {
          fileName: newFileName,
        },
      }

      return adminApi.patch(`organization-jobs/${jobId}/documents/${id}`, config).json()
    },
  }
}

export { documentService }

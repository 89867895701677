export const ACCEPTED_FILE_MIME_TYPES = [
  'image/*',
  'video/*',
  'application/pdf',

  // KML
  'application/vnd.google-earth.kml+xml',

  // Archives
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip',
  'application/vnd.rar',
  'application/x-rar-compressed',
  'application/octet-stream',

  // Pilot Files
  'bin',
  'obs',
  'nav',
  'MRK',
  'zip',

  // Shape Files
  'x-gis/x-shapefile',
  'shp',
  'shx',
  'dbf',
  'prj',
  'sbn',
  'sbx',
  'fbn',
  'fbx',
  'ain',
  'aih',
  'ixs',
  'mxs',
  'atx',
  'cpg',
  'qix',
]

const WINRAR_WINZIP_CLI = /\.(z\d{2,})$/
const SEVEN_ZIP = /\.(zip|7z)\.\d+$/
const OTHER_PATTERN = /\.zip\.part\d$/

export const ZIP_EXTENSION_PATTERNS = [WINRAR_WINZIP_CLI, OTHER_PATTERN, SEVEN_ZIP]

function validateWildCard(mimeType, wildcard) {
  const mimeTypeGroup = (/^[^/]+/.exec(mimeType) || []).pop() // image/png -> image
  const wildcardGroup = wildcard.slice(0, -2) // image/* -> image
  return mimeTypeGroup === wildcardGroup
}

function validateMimeTypes(acceptedFileTypes, type) {
  return acceptedFileTypes.some((acceptedType) => {
    if (/\*$/.test(acceptedType)) {
      return validateWildCard(type, acceptedType)
    }
    return type === acceptedType
  })
}

function validateRegexesFileExtensions(regexesFileTypes, name) {
  return regexesFileTypes.some((pattern) => pattern.test(name))
}

function validateFileExtension(acceptedFileTypes, name) {
  const ext = name.split('.').pop()
  return acceptedFileTypes.includes(ext ?? '')
}

function validateFileType(
  acceptedFileTypes = ACCEPTED_FILE_MIME_TYPES,
  regexFileExtensionPatterns = ZIP_EXTENSION_PATTERNS
) {
  return function (file) {
    const { type, name } = file

    if (!name) return false
    if (!type)
      return (
        validateRegexesFileExtensions(regexFileExtensionPatterns, name) ||
        validateFileExtension(acceptedFileTypes, name)
      )
    return validateMimeTypes(acceptedFileTypes, type)
  }
}

export { validateFileType }

import { ConnectedCompanyAdminDetail } from './CompanyDetailAdminContainer'
import { ConnectedCompanyDetail } from './CompanyDetailContainer'

import { useIdentity } from 'lib/solta-id-react'

const CompanyDetailGateway = () => {
  const { isAdmin } = useIdentity()
  return isAdmin ? <ConnectedCompanyAdminDetail /> : <ConnectedCompanyDetail />
}

export { CompanyDetailGateway as CompanyDetail }

import moment from 'moment'

import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex flex-column bg-white w-full py-8 px-4 rounded-lg shadow-sm')
)

const Wrapper = styled.div(
  apply('grid', {
    gridTemplateColumns: '1fr 3fr',
    gap: '2rem',
    '@media (max-width: 1024px)': {
      gridTemplateColumns: '1fr',
    },
  })
)

const Image = styled.img(apply('w-full h-auto'))

const Details = styled.div(apply('flex flex-column'))

const TitleText = styled.span(
  apply('text-2xl font-bold', {
    marginBottom: '32px',
  })
)

const DetailText = styled.span(apply('text-lg'))

const Col = styled.div(apply('flex flex-wrap'))

const Row = styled.div(
  apply('flex flex-column w-full mb-6 items-start', {
    flex: '0 0 50%',
    maxWidth: '50%',
    gap: '8px',
  })
)

const RowFull = styled.div(
  apply('flex flex-column w-full mb-6 items-start', {
    flex: '0 0 100%',
    maxWidth: '100%',
    gap: '8px',
  })
)

const Label = styled.span(apply('mr-2 text-sm', { color: '#555' }))

const toHumanReadableDate = (date) => moment(date).format('D MMM YYYY')

// eslint-disable-next-line complexity
function OrderOverview({ reference, product, address, startedAt, finishedAt }) {
  return (
    <Container>
      <TitleText>Job Overview</TitleText>
      <Wrapper>
        <Image src={product?.productImage} alt="Job Image" />
        <Details>
          <Col>
            <Row>
              <Label>Reference Number</Label>
              <DetailText>{reference ?? 'No Data'}</DetailText>
            </Row>
            <Row>
              <Label>Product Name</Label>
              <DetailText>{product?.name ?? 'No Data'}</DetailText>
            </Row>
            <RowFull>
              <Label>Location</Label>
              <DetailText>{address ?? 'No Data'}</DetailText>
            </RowFull>
            <Row>
              <Label>Start Date</Label>
              <DetailText>
                {startedAt ? toHumanReadableDate(startedAt) : 'No Data'}
              </DetailText>
            </Row>
            <Row>
              <Label>End Date</Label>
              <DetailText>
                {finishedAt ? toHumanReadableDate(finishedAt) : 'No Data'}
              </DetailText>
            </Row>
            <RowFull>
              <Label>Description</Label>
              <DetailText>{product?.description ?? 'No Data'}</DetailText>
            </RowFull>
          </Col>
        </Details>
      </Wrapper>
    </Container>
  )
}

export { OrderOverview }

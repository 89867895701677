import { isInvalidDate } from '@seedcloud/ramda-extra'
import { differenceInMinutes } from 'date-fns'
import { useFormikContext } from 'formik'
import moment from 'moment'

import { DateField as DateFieldBase } from './DateField'

import { apply, styled } from 'lib/styled'

const Container = styled.div(apply('flex items-center flex-1'))
const Row = styled.div(
  apply('flex items-center justify-between mb-4', {
    '&:last-of-type': apply('mb-0'),
  })
)
const Column = styled.div(apply('flex flex-column justify-evenly flex-1'))
const Separator = styled.div(apply('my-4 show-box border-grey-light'))
const Value = styled.div(apply('h-2 text-right w-full'))
const Label = styled.div(apply('w-3/4'))
const DateField = styled(DateFieldBase)(apply('w-full'))

const formatCurrency = (value, ...opts) =>
  new Intl.NumberFormat('au-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...opts,
  }).format(value)

const extractRate = (rate) => ({
  rate: rate ?? 'N/A',
  displayedRate: rate ? `${formatCurrency(rate)} / hr` : 'N/A',
})

const TotalWrapper = styled.div(
  apply('p-8 rounded-lg', {
    backgroundColor: '#F4F6F8',
  })
)

const addUnit = (value, unit, plural = `${unit}s`) =>
  `${value} ${value === 1 ? unit : plural}`

// eslint-disable-next-line complexity
function PriceBlock({ isEditing, ...props }) {
  const {
    values: {
      jobInfo: {
        startedAt,
        finishedAt,
        engagedBy,
        equipmentFee = 0,
        calloutFee = 0,
        marketFee = 0,
      },
    },
  } = useFormikContext()

  let duration = 'N/A'
  let total = 'N/A'
  const { rate, displayedRate } = extractRate(engagedBy?.pilotCategory?.rate)

  const startedAtDate = moment.isMoment(startedAt) ? startedAt.toDate() : startedAt
  const finishedAtDate = moment.isMoment(finishedAt) ? finishedAt.toDate() : finishedAt
  const invalidDates = isInvalidDate(startedAtDate) || isInvalidDate(finishedAtDate)

  if (!invalidDates) {
    // We don't care about seconds or milliseconds
    startedAtDate.setSeconds(0, 0)
    finishedAtDate.setSeconds(0, 0)

    const diffInMinutes = differenceInMinutes(finishedAtDate, startedAtDate)
    if (diffInMinutes >= 0) {
      const hours = Math.floor(diffInMinutes / 60)
      const minutes = diffInMinutes - hours * 60
      duration = `${addUnit(hours, 'hr')} ${addUnit(minutes, 'min')}`

      // If the duration is less than 1 hour, charge for 1 hour
      const hoursToCharge = Math.max(1, diffInMinutes / 60)

      const totalValue =
        hoursToCharge * rate +
        (Number(equipmentFee) + Number(calloutFee) + Number(marketFee))

      total = rate === 'N/A' ? 'N/A' : formatCurrency(totalValue)
    }
  }

  return (
    <Container {...props}>
      <Column>
        <Row>
          <Label>Started At</Label>
          <DateField
            disabled={!isEditing}
            name="jobInfo.startedAt"
            type="text"
            aria-label="Started At"
            autoOk
            withTimePicker
            rootStyle={apply('w-full', { flex: 'initial' })}
            placeholder={!isEditing ? 'Not Yet Available' : undefined}
          />
        </Row>
        <Row>
          <Label>Finished At</Label>
          <DateField
            disabled={!isEditing}
            name="jobInfo.finishedAt"
            type="text"
            aria-label="Finished At"
            autoOk
            withTimePicker
            rootStyle={apply('w-full', { flex: 'initial' })}
            placeholder={!isEditing ? 'Not Yet Available' : undefined}
          />
        </Row>
        <Separator />
        <TotalWrapper>
          <Row>
            <Label>Job Entry x Hours:</Label>
            <Value>Total</Value>
          </Row>
          <Separator />
          <Row>
            <Label>Duration</Label>
            <Value>{duration}</Value>
          </Row>
          <Row>
            <Label>Rate</Label>
            <Value>{displayedRate}</Value>
          </Row>
          <Row style={apply('font-semibold text-grey-darker')}>
            <Label>Total</Label>
            <Value>{total}</Value>
          </Row>
        </TotalWrapper>
      </Column>
    </Container>
  )
}

export { PriceBlock }

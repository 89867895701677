/* eslint-disable complexity */
import { isNotNilOrEmpty, path } from '@seedcloud/ramda-extra'
import moment from 'moment'
import Modal from 'react-modal'

import { Avatar } from 'components/common/Avatar'
import { useJobs } from 'components/common/context/JobContext'
import { CircledMarkerLayer, Map } from 'components/common/Map/index'
import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { styled, apply } from 'lib/styled'

const Root = styled.div(
  apply('text-black bg-white rounded-lg font-thin', { width: '448px' })
)
const Container = styled.div(apply('py-8 px-8 '))
const HeaderPanel = styled.div(
  apply('absolute bg-white z-2 shadow-sm px-6 pb-2 pt-1 w-24', { marginTop: '-156px' })
)
const Project = styled.div(apply('text-grey text-xs'))
const Address = styled.div(
  apply('text-xl font-normal my-1', { overflow: 'hidden', height: '60px' })
)
const Product = styled.div(apply('text-grey text-base'))
const PillsContainer = styled.div(apply('flex my-2'))
const Pill = styled.div(apply('bg-grey-lighter text-sm px-4 py-1 mr-3'))
const ContactContainer = styled.div(
  apply('py-6 border-solid border-grey-light border-0 border-b-1 flex')
)
const Contact = styled.div(apply(''))
const Name = styled.div(apply('font-light text-lg'))
const UpdatedAt = styled.div(apply('text-grey'))
const DetailsContainer = styled.div(apply('py-4'))
const Label = styled.div(apply('text-xl font-normal my-3'))
const Reference = styled.div(apply('bg-grey-lighter text-lg w-full text-center py-2'))
const Description = styled.div(apply('text-lg font-hairline min-h-12'))
const EngageButton = styled.div(
  apply('text-white text-center py-3 rounded-md', { backgroundColor: '#4b84b3' })
)

Modal.setAppElement('#root')

function PreviewModal({ isOpen, setIsOpen, jobInfo }) {
  const { productList } = useJobs()

  const {
    project,
    location,
    product: selectedProduct,
    acceptedBy,
    acceptedAt,
    scheduledAt,
    reference,
    radius,
    description,
  } = jobInfo
  const coordinates = location?.geometry?.coordinates
  const address = location?.place_name

  let product = selectedProduct

  if (!path(['serviceCategory'], selectedProduct)) {
    product = productList.find((product) => product.id === selectedProduct.id)
  }

  const serviceCategory = SERVICE_CATEGORIES.find(
    ({ value }) => value === product.serviceCategory
  )

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      id="special"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <Map
          centerCoords={coordinates ?? [0, 0]}
          containerStyle={apply('block w-full', { height: '300px' })}
          zoom={{ 50: [8], 100: [7], 150: [6], 200: [6] }[radius]}
          type="marker"
        >
          <CircledMarkerLayer
            units="kilometres"
            markerCoords={coordinates}
            radius={radius}
            visible={isNotNilOrEmpty(coordinates)}
          />
        </Map>
        <Container>
          <HeaderPanel>
            <Project>{project?.reference ?? ''}</Project>
            <Address>{address}</Address>
            <Product>{product?.name ?? ''}</Product>
            <PillsContainer>
              <Pill>{serviceCategory ? serviceCategory.readableValue : ''}</Pill>
              <Pill>${product?.pricing.basePrice ?? ''}</Pill>
              <Pill>{moment(scheduledAt).format('YYYY-MM-DD hh:mm')}</Pill>
            </PillsContainer>
          </HeaderPanel>

          <ContactContainer>
            <Avatar src={acceptedBy?.avatar} style={apply('mr-4')} />
            <Contact>
              <Name>{acceptedBy?.fullName}</Name>
              <UpdatedAt>
                Updated at: {moment(acceptedAt).format('YYYY-MM-DD hh:mm')}
              </UpdatedAt>
            </Contact>
          </ContactContainer>

          <DetailsContainer>
            <Label>Reference</Label>
            <Reference>{reference}</Reference>
            <Label>Description</Label>
            <Description>{description}</Description>
          </DetailsContainer>

          <EngageButton>Engage</EngageButton>
        </Container>
      </Root>
    </Modal>
  )
}

export { PreviewModal }

import CONSTANTS from '@seedcloud/constants'
// import { values, prop, isNotNilOrEmpty, pathOr } from '@seedcloud/ramda-extra'
import { values, pathOr } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useLayoutEffect } from 'react'
import * as Validator from 'yup'

import { PersonalDetails } from './PersonalDetails'
import { ServiceArea } from './ServiceArea'
import { SkillsAndAbilities } from './SkillsAndAbilities'

import { Button } from 'components/common/Button'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { NavigationBar } from 'components/common/withNavigation/NavigationBar'
import { LICENCE_TYPES } from 'constants/licenceTypes'
import { roleKey } from 'constants/roles'
// import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import toast from 'lib/toaster'
import { authModule, user$ } from 'modules/auth'
import { createPilotModule } from 'modules/pilot'
import { mapboxResultSchema } from 'utils/hooks/preProcessResults'
import { dateNowMinimum } from 'utils/validation'

// const { object, string, array, number } = Validator
const { object, string, number } = Validator
const optionalString = (label) => string().optional().label(label)
const requiredString = (label) => string().required().label(label)

const { module: pilotModule } = createPilotModule(API_TYPES.WEB)

const validationSchema = object({
  userDetails: object({
    firstName: requiredString('First name'),
    lastName: requiredString('Last name'),
    email: requiredString('Email').email('Not a valid email address'),
    phoneNumber: requiredString('Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Not a valid phone number'
    ),
  }),
  pilotDetails: object().shape(
    {
      address: mapboxResultSchema.required(),
      licenceType: optionalString('Licence type').oneOf(values(LICENCE_TYPES)),
      licenceNumber: optionalString('Licence number'),
      licenceExpiredAt: dateNowMinimum().label('Licence expiry').optional(),
      // Hide Insurance and Service Categories for now
      // insuranceNumber: string().when('insuranceExpiredAt', {
      //   is: isNotNilOrEmpty,
      //   then: requiredString('Insurance number'),
      //   otherwise: optionalString('Insurance number'),
      // }),
      // insuranceExpiredAt: dateNowMinimum()
      //   .label('Insurance expiry')
      //   .when('insuranceNumber', {
      //     is: isNotNilOrEmpty,
      //     then: dateNowMinimum().required().nullable(),
      //     otherwise: dateNowMinimum().optional().nullable(),
      //   }),
      // serviceCategories: array()
      //   .of(string().oneOf(SERVICE_CATEGORIES.map(prop('value'))))
      //   .min(1)
      //   .label('Service categories'),
      serviceRadius: number().required().label('Radius'),
    },
    // [['insuranceNumber', 'insuranceExpiredAt']]
    []
  ),
})

const Container = styled.div(apply('flex justify-center bg-grey-lighter py-6'))

const FormContainer = styled.div(
  apply('flex-1 flex flex-column px-2 bg-white rounded-lg', {
    overflow: 'auto',
    maxWidth: '800px',
  })
)

const Scrollable = styled.div(
  apply('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)

const Title = styled.h1(apply('m-0 text-xxl font-normal my-6'))
const SubTitle = styled.p(apply('m-0 text-sm font-light'))
const SubmitButtonContainer = styled.div(apply('my-6'))

const ConnectedPilotOnboarding = connect(() => ({ user: user$ }))(PilotOnboardingPage)

function PilotOnboardingPage({ setIsOnboarded, user }) {
  const { setUser, user: auth0User } = useIdentity()

  useLayoutEffect(() => {
    authModule.getProfile()
  }, [])

  if (!user) return null

  // eslint-disable-next-line no-unreachable
  return (
    <Container>
      <FormContainer>
        <NavigationBar>
          <NavigationBar.Logout />
        </NavigationBar>
        <FormProvider
          id="PilotOnboardingPage"
          enableReinitialize
          initialValues={{
            userDetails: {
              firstName: pathOr('', ['firstName'], user),
              lastName: pathOr('', ['lastName'], user),
              phoneNumber: pathOr('', ['phoneNumber'], user),
              email: pathOr('', ['email'], user),
            },
            pilotDetails: {
              companyName: pathOr('', ['company'], user),
              address: {
                // eslint-disable-next-line camelcase
                place_name: '',
                geometry: {
                  type: 'Point',
                  coordinates: [151.2093, -33.8688],
                },
              },
              isBanned: false,
              licenceType: undefined,
              licenceNumber: undefined,
              licenceExpiredAt: undefined,
              // insuranceNumber: undefined,
              // insuranceExpiredAt: undefined,
              serviceRadius: 50,
              // serviceCategories: [],
              termsAndConditionsAccepted: true,
            },
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            pilotModule.createPilot(null, values).then(() => {
              toast.success('Success onboarding')
              const roles = JSON.parse(auth0User?.[roleKey])
              roles.pilotOnboarding = null
              setUser((user) => ({ ...user, [roleKey]: JSON.stringify(roles) }))
              setIsOnboarded(true)
            })
          }}
        >
          <Loader
            renderPlaceholder={
              <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
            }
          >
            <Scrollable>
              <Form>
                <Title>Pilot Onboarding</Title>
                <SubTitle>
                  Please fill in the below form to register your pilot details
                </SubTitle>
                <PersonalDetails />

                <SkillsAndAbilities />

                <ServiceArea />

                <SubmitButtonContainer>
                  <Button type="submit">Submit</Button>
                </SubmitButtonContainer>
              </Form>
            </Scrollable>
          </Loader>
        </FormProvider>
      </FormContainer>
    </Container>
  )
}

export { ConnectedPilotOnboarding as PilotOnboardingPage }

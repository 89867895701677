import { prop, pathOr } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

// eslint-disable-next-line import/no-cycle
import { authModule } from './module'

const { state$, effectState$ } = authModule

const isBanned$ = select(state$, prop('isBanned'))
const organizationId$ = select(state$, prop('organizationId'))
const user$ = select(state$, prop('user'))

const getUserOrganizationIdStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['getUserOrganizationId', 'status'], effectState)
)

const isFetchingOrganizationId$ = select(
  getUserOrganizationIdStatus$,
  (status) => status === 'pending'
)

export { isBanned$, isFetchingOrganizationId$, organizationId$, user$ }

import { useHistory } from 'react-router-dom'

import { Tabs as BaseTabs } from './ui'

const Tabs = ({
  tabs,
  pathLength = 3,
  defaultSelectedId = 'details',
  selectedHandler,
  ...props
}) => {
  const history = useHistory()
  const pathArray = history.location.pathname.split('/')

  const renderTabs = () =>
    tabs.map((tab) => {
      const tabSelected =
        pathArray.length >= pathLength
          ? pathArray.includes(tab.id)
          : tab.id === defaultSelectedId
      return (
        <BaseTabs.Item key={tab.id} to={tab.path} selected={tabSelected} block>
          {tab.title}
        </BaseTabs.Item>
      )
    })

  return <BaseTabs {...props}>{renderTabs()}</BaseTabs>
}

export { Tabs }

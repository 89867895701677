import { isNotNil, prop, defaultTo } from '@seedcloud/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi } from 'lib/http-client'

const companyEntity = new schema.Entity('company')
const pilotEntity = new schema.Entity('pilots')

const CompanyService = {
  async list({ query = '', type, limit = 1000, next, isNBS = '' }) {
    const config = {
      searchParams: {
        q: query,
        limit,
        isNBS,
      },
    }
    if (isNotNil(next)) {
      config.searchParams.next = next
    }
    if (isNotNil(type)) {
      config.searchParams.type = type
    }

    const { items, paging } = await adminApi.get('companies', config).json()

    const normalized = normalize(items, [companyEntity])
    const entities = defaultTo({}, prop('company', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async customerList({ query = '', type, limit = 1000, next, isNBS = '' }) {
    const config = {
      searchParams: {
        q: query,
        limit,
        isNBS,
      },
    }
    if (isNotNil(next)) {
      config.searchParams.next = next
    }
    if (isNotNil(type)) {
      config.searchParams.type = type
    }

    const items = await adminApi.get('customer', config).json()

    const normalized = normalize(items, [companyEntity])
    const entities = defaultTo({}, prop('company', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
    }
  },

  async create(body) {
    const config = {
      json: body,
    }

    return adminApi.post('customer', config).json()
  },

  async read(id) {
    return adminApi.get(`customer/${id}`).json()
  },

  async update(id, { insuranceExpiredAt, ...otherData }) {
    const config = {
      json: {
        ...otherData,
      },
    }

    return adminApi.patch(`customer/${id}`, config).json()
  },

  async listPilots({ id, query = '', limit = 1000, next }) {
    const config = {
      searchParams: {
        q: query,
        limit,
      },
    }
    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await adminApi
      .get(`companies/${id}/pilots`, config)
      .json()

    const normalized = normalize(items, [pilotEntity])
    const entities = defaultTo({}, prop('pilots', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async add(id) {
    return adminApi.post(`companies/${id}/add`)
  },

  async reinvite(id) {
    return adminApi.post(`companies/${id}/reinvite`)
  },

  async invitePilot({ companyId, userDetails }) {
    const config = {
      json: userDetails,
    }

    return adminApi.post(`companies/${companyId}/pilots`, config).json()
  },
}

export { CompanyService }

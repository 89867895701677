import { connect } from '@seedcloud/stateless'
import { useLayoutEffect, useState } from 'react'

import { PilotList } from './PilotList'

import { roleKey } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { createCompanyModule } from 'modules/legacy-company'
import { createPilotModule } from 'modules/pilot'
import { useDebounce } from 'utils/hooks/useDebounce'
import { useUpdateEffect } from 'utils/hooks/useUpdateEffect'

const {
  module: companyModule,
  selectors: { companyList$ },
} = createCompanyModule()

const {
  module: pilotModule,
  selectors: { pilotList$, inspectedEntity$, isLoadingPilots$, selectedCompanyId$ },
} = createPilotModule()

export const ConnectedPilotListAdminContainer = connect(() => ({
  pilotList: pilotList$,
  isLoadingPilots: isLoadingPilots$,
  inspectedEntity: inspectedEntity$,
  companyList: companyList$,
  selected: selectedCompanyId$,
}))(PilotListContainer)

const { fetchPilots, reset } = pilotModule

const onSearchChanged = (value) => {
  pilotModule.filterPilots(null, { filterQuery: value || '' })
}

function PilotListContainer({
  isLoadingPilots,
  pilotList,
  inspectedEntity,
  selected,
  companyList,
}) {
  const { user } = useIdentity()
  const roles = JSON.parse(user?.[roleKey])
  const orgId = Object.keys(roles.staff)[0] // TODO: Switch account to the current organization

  useLayoutEffect(() => {
    fetchPilots(null)
    companyModule.fetchCompanies(null)
    return () => {
      reset()
    }
  }, [])

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  useUpdateEffect(() => {
    onSearchChanged(debouncedSearch)
  }, [debouncedSearch])

  const pilotListProps = {
    isLoadingPilots,
    pilotList,
    inspectedEntity,
    search,
    setSearch,
    orgId,
    selectedFilter: selected,
    companyList,
    onFilterChange: (value) =>
      pilotModule.filterPilots(null, { companyId: value.value }),
    isAdmin: true,
  }

  return <PilotList {...pilotListProps} />
}

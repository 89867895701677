import {
  prop,
  pathOr,
  pick,
  mergeDeepRight,
  isNotNilOrEmpty,
  ifElse,
  isNilOrEmpty,
  always,
  propOr,
  pipe,
  path,
} from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

// eslint-disable-next-line import/no-cycle
import { companyModule } from './module'

const { state$, effectState$ } = companyModule

const companyEntities$ = select(state$, prop('entities'))
const inspectedEntity$ = select(state$, prop('inspectedEntity'))
const token$ = select(state$, prop('token'))

const inspectedCompany$ = select(inspectedEntity$, companyEntities$, (id, entities) =>
  prop(id, entities)
)

const companyDetail$ = select(inspectedCompany$, (entities) => {
  let detail = {}

  if (isNotNilOrEmpty(entities)) {
    const picked = pick(['firstName', 'lastName', 'email', 'phoneNumber'])
    const propOrEmpty = propOr({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    })
    const commercial = picked(propOrEmpty('commercialUserId', entities))
    const addOnsContacts = entities.commercialUserId.additionalContacts
    const billing = picked(propOrEmpty('billing', addOnsContacts))
    const heroPilot = picked(propOrEmpty('heroPilot', addOnsContacts))

    const companyProps = pipe(
      path(['company']),
      pick(['supplierType', 'invitationStatus', 'companyName', 'phoneNumber'])
    )(entities)

    detail = pipe(
      pick(['abn', 'website', 'address', 'id']),
      mergeDeepRight({ commercial, billing, heroPilot }),
      mergeDeepRight(companyProps)
    )(entities)
  }

  return detail
})

const supplierType$ = select(inspectedCompany$, (entities) =>
  pathOr('', ['company', 'supplierType'], entities)
)

const invitationStatus$ = select(inspectedCompany$, (entities) =>
  pathOr('', ['company', 'invitationStatus'], entities)
)

const aviationDetail$ = select(inspectedCompany$, (entities) =>
  ifElse(
    isNilOrEmpty,
    always({}),
    pick([
      'country',
      'companyArn',
      'documents',
      'links',
      'licenses',
      'insuranceNumber',
      'insuranceProvider',
      'insuranceIssuedAt',
      'insuranceExpiredAt',
      'equipments',
      'invitationStatus',
    ])
  )(entities)
)

const inspectCompanyStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['inspectCompany', 'status'], effectState)
)

const inspectGetUserCompanyAndOrganizationId$ = select(effectState$, (effectState) =>
  pathOr('idle', ['getUserCompanyAndOrganizationId', 'status'], effectState)
)

const inspectSubmitCompany$ = select(effectState$, (effectState) =>
  pathOr('idle', ['submitCompany', 'status'], effectState)
)

const updateCompanyCurrencyStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr(
      'fulfilled',
      ['updateCompanyCurrency', inspectedEntity, 'status'],
      effectState
    )
)

const isSubmittingCompany$ = select(
  inspectSubmitCompany$,
  (status) => status === 'pending'
)

const isInspectingCompany$ = select(
  inspectCompanyStatus$,
  (status) => status === 'pending'
)

const isInspectingGetCompanyAndOrganizationId$ = select(
  inspectGetUserCompanyAndOrganizationId$,
  (status) => status === 'pending'
)

const updateCompanyStatus$ = select(effectState$, (effectState) =>
  pathOr('fulfilled', ['updateCompany', 'status'], effectState)
)

const inspectUploadAviationDocuments$ = select(effectState$, (effectState) =>
  pathOr('fulfilled', ['uploadAviationDocuments', 'status'], effectState)
)

const isUploading$ = select(
  inspectUploadAviationDocuments$,
  (status) => status === 'pending'
)

const isSubmitting$ = select(updateCompanyStatus$, (status) => status === 'pending')

const currency$ = select(inspectedCompany$, prop('currency'))
const isUpdatingCurrency$ = select(
  updateCompanyCurrencyStatus$,
  (status) => status === 'pending'
)

const claimCompanyStatus$ = select(token$, effectState$, (token, effectState) =>
  pathOr('idle', ['claimCompany', token, 'status'], effectState)
)

const isClaimingCompany$ = select(claimCompanyStatus$, (status) => status === 'pending')

export const companySelectors = {
  companyEntities$,
  inspectedEntity$,
  isInspectingCompany$,
  inspectedCompany$,
  isSubmitting$,
  isUpdatingCurrency$,
  companyDetail$,
  aviationDetail$,
  currency$,
  isUploading$,
  isInspectingGetCompanyAndOrganizationId$,
  supplierType$,
  invitationStatus$,
  isSubmittingCompany$,
  isClaimingCompany$,
}

import { styled, apply } from 'lib/styled'

const Container = styled.button(
  apply(
    'tracking-wide uppercase rounded-md h-2 px-4 text-xs border-0 border-solid border-blue-lighter font-normal'
  ),
  ({ disabled }) => ({
    color: disabled ? '#262626' : '#FFFF',
    cursor: disabled ? 'not-allowed' : 'pointer',
    textTransform: 'none',
    backgroundColor: disabled ? '#D9D9D9' : '#009DE4',
    border: '1px solid #D9D9D9',
  })
)
const CancelButton = styled(Button)(apply('bg-transparent shadow-none text-grey-400'))
const CreateButton = styled(Button)()

function Button({ ...props }) {
  return <Container {...props} />
}

export { Button, CancelButton, CreateButton }

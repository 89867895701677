import { Switch, Route } from 'react-router-dom'

import { SubscriptionPlans } from './SubscriptionPlansContainer'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex flex-column p-6'))

// eslint-disable-next-line react/display-name
function SubscriptionPlansManager() {
  return (
    <Container>
      <Switch>
        <Route
          exact
          path={['/', '/subscription-plans']}
          component={SubscriptionPlans}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { SubscriptionPlansManager }

import { useFormikContext } from 'formik'

import { StaffActionsSection } from './StaffActionsSection'

import { Avatar } from 'components/common/Avatar'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { ImageUpload } from 'components/common/ImageUpload'
import { SelectField } from 'components/common/SelectField'
import { ORGANIZATION_ROLE_DISPLAY_NAMES } from 'constants/roles'
import { styled, apply } from 'lib/styled'
import { staffModule } from 'modules/staff'

const Container = styled.section()
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})
const ActiveToggleContainer = styled.div(
  apply('flex flex-column items-center mx-4 mt-4 mb-2')
)
const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const BannedPill = styled.div(
  apply('uppercase tracking-wide px-2 py-1 rounded-xl bg-grey-light text-center', {
    whiteSpace: 'nowrap',
    fontSize: 10,
  })
)

const roleOptions = Object.entries(ORGANIZATION_ROLE_DISPLAY_NAMES).map(
  ([id, label]) => ({
    id,
    value: id,
    label,
  })
)

function StaffDetailsForm({
  id,
  userId,
  isCreateStaff = false,
  isEditing,
  avatarSrc,
  isActive,
  ...props
}) {
  const { values, setFieldValue } = useFormikContext()

  const isBanned = values?.isBanned

  const onSelectChange = (name) => (selectedOption) => {
    setFieldValue(name, selectedOption?.value)
  }

  const onUploadAvatar = async ([file]) => {
    if (isCreateStaff) {
      staffModule.setAvatar(null, file)
    } else {
      await staffModule.uploadImage(null, file, userId)
    }
  }

  return (
    <Container {...props}>
      <Details>
        <Column style={apply('flex-0 ml-4 mr-10 items-center')}>
          <ImageUpload
            name="avatarSrc"
            imageSrc={avatarSrc}
            disabled={!isEditing}
            ImageComponent={Avatar}
            imageProps={{
              style: apply('m-0', { width: '120px', height: '120px' }),
            }}
            onUpload={onUploadAvatar}
          />
          {!isCreateStaff && (
            <>
              <ActiveToggleContainer>
                <Label>{isBanned ? 'Suspended' : 'Active'}</Label>
              </ActiveToggleContainer>
              {!isActive && <BannedPill>Pending Activation</BannedPill>}
            </>
          )}
        </Column>
        <Column style={apply('flex-1')}>
          <Row>
            <FormInput
              name="userDetails.firstName"
              type="text"
              disabled={!isEditing}
              label="First Name"
            />
            <FormInput
              name="userDetails.lastName"
              type="text"
              disabled={!isEditing}
              label="Last Name"
            />
          </Row>
          <Row>
            <FormInput
              name="userDetails.phoneNumber"
              type="text"
              disabled={!isEditing}
              label="Mobile"
            />
            <FormInput
              name="userDetails.email"
              type="text"
              disabled={!isEditing}
              readOnly={!isCreateStaff}
              label="Email"
            />
          </Row>
          <Row>
            <SelectField
              name="role"
              label="Role"
              type="select"
              onChange={onSelectChange('role')}
              isDisabled={!isCreateStaff}
              options={roleOptions}
              placeholder="Select Role"
              isSearchable={false}
              containerProps={{ style: apply('flex-1') }}
            />
          </Row>
        </Column>
      </Details>
      {!isCreateStaff && <StaffActionsSection id={id} isActive={isActive} />}
    </Container>
  )
}

export { StaffDetailsForm }

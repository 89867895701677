import { Avatar } from '@material-ui/core'

import CardBg from 'assets/card_bg.svg'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex flex-column bg-white w-full rounded-lg shadow-sm', {
    padding: '24px 24px 28px 24px',
    '@media (min-width: 1280px)': {
      maxWidth: '460px',
    },
  })
)

const PilotCard = styled.div(
  apply('rounded-lg w-100 flex items-center', {
    padding: '8px',
    backgroundColor: '#EEF1F5',
    backgroundImage: `url('${CardBg}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    gap: '24px',
  })
)

const NameLabel = styled.span(apply('mr-2 text-sm', { color: '#555' }))

const NameText = styled.span(apply('text-lg font-bold'))

const Label = styled.span(apply('mr-2 text-sm', { color: '#555' }))

const TitleText = styled.p(apply('text-2xl font-bold'))

const DetailText = styled.span(
  apply('text-lg', {
    maxWidth: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  ({ text }) => ({
    title: text,
  })
)

const PilotDetails = styled.div(apply('flex flex-column'))

const Col = styled.div(apply('flex flex-wrap w-full'))

const Row = styled.div(
  apply('flex flex-column w-full mb-6 items-start', {
    flex: '0 0 100%',
    maxWidth: '100%',
    gap: '8px',
  })
)

function OrderPilot({ engagedBy = {}, companyName }) {
  return (
    <Container>
      <TitleText>Captured By</TitleText>
      <PilotCard>
        <Avatar src={engagedBy.avatar} style={{ width: '70px', height: '70px' }} />
        <PilotDetails>
          <NameLabel>Name</NameLabel>
          <NameText>{engagedBy.fullName}</NameText>
        </PilotDetails>
      </PilotCard>
      <Col>
        <Row style={{ marginTop: '24px' }}>
          <Label>Email</Label>
          <DetailText text={engagedBy.email}>{engagedBy.email}</DetailText>
        </Row>
        <Row>
          <Label>Mobile</Label>
          <DetailText>{engagedBy.phoneNumber}</DetailText>
        </Row>
        <Row>
          <Label>Company</Label>
          <DetailText>{companyName}</DetailText>
        </Row>
      </Col>
    </Container>
  )
}

export { OrderPilot }

import { ConnectedPilotDetailsAdmin } from './PilotDetailsAdminContainer'
import { ConnectedPilotDetails } from './PilotDetailsContainer'

import { useIdentity } from 'lib/solta-id-react'

const PilotDetailsGateway = () => {
  const { isAdmin } = useIdentity()
  return isAdmin ? <ConnectedPilotDetailsAdmin /> : <ConnectedPilotDetails />
}

export { PilotDetailsGateway as PilotDetails }

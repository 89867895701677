import { FieldArray } from 'formik'
import { useContext } from 'react'

import { Button } from 'components/common/Button'
import {
  FormLayoutContext,
  DocumentField,
  EmptyFileItem,
  FileItem,
} from 'components/company/common'
import { TrashIcon } from 'components/icons'
import { COMPANY_DOCUMENT_TYPES } from 'constants/documentTypes'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import { getDocumentFileTypes } from 'utils/getDocumentFileTypes'
import { getDocumentNumbers } from 'utils/getDocumentNumbering'

const DocumentContainer = styled.div(apply('flex flex-column'), {
  flex: '1',
  gap: '1rem',
})

const ExtraActionContainer = styled.div(apply('flex'), {
  gap: '1rem',
})

const ContentContainer = styled.div(apply('flex-1'))

const ExtraButton = styled(Button)(({ isFirstElement, theme }) => ({
  aspectRatio: '1 / 1',
  padding: 0,
  width: 40,
  height: 40,
  marginTop: isFirstElement ? '28px' : '0',
  background: isFirstElement ? theme.colors.primary : '#555555',
  color: 'white',
}))

const Document = ({
  values,
  isUploading,
  handleUploadDocuments,
  handleDownloadDocuments,
  setFieldValue,
}) => {
  const { editing } = useContext(FormLayoutContext)

  const {
    user: { sub: auth0UserId },
  } = useIdentity()

  const handleUploadDocument = async (files, currentFiles) => {
    const entities = await handleUploadDocuments(null, {
      files,
      auth0UserId,
    })

    const documentNumber = getDocumentNumbers(currentFiles)
    const documentType = getDocumentFileTypes(currentFiles)

    return files.map((_, idx) => ({
      name: `Document ${documentNumber()}`,
      url: entities[idx].entity.fileName,
      id: entities[idx].entity.id,
      type: documentType,
    }))
  }

  const handleTypeChange = (val, docId) => {
    if (val === COMPANY_DOCUMENT_TYPES.TNC) {
      const updatedValues = values.map((document) => {
        if (document.id === docId) {
          return { ...document, type: COMPANY_DOCUMENT_TYPES.TNC }
        }
        return { ...document, type: COMPANY_DOCUMENT_TYPES.OTHER }
      })
      setFieldValue('documents', updatedValues)
    } else if (val === COMPANY_DOCUMENT_TYPES.OTHER) {
      const updatedValues = values.map((document) => {
        if (document.id === docId) {
          return { ...document, type: COMPANY_DOCUMENT_TYPES.OTHER }
        }
        return document
      })
      setFieldValue('documents', updatedValues)
    }
  }

  return (
    <DocumentContainer>
      <ExtraActionContainer>
        <ContentContainer>
          <DocumentField
            label="Supporting Documents"
            name="documents"
            tooltip="Upload license, insurance, and other supporting documents here"
            isUploading={isUploading}
            handleOnFileDrop={handleUploadDocument}
          />
        </ContentContainer>
      </ExtraActionContainer>
      <FieldArray
        name="documents"
        render={(arrayHelpers) => (
          <>
            {values?.length > 0 ? (
              values.map(({ url, id, type }, idx) => (
                <ExtraActionContainer key={idx}>
                  <ContentContainer>
                    <FileItem
                      itemType="file"
                      name={`documents.${idx}.name`}
                      url={url}
                      editing={editing}
                      handleClick={async () => {
                        const file = await handleDownloadDocuments(id)
                        if (file) {
                          window.open(URL.createObjectURL(file))
                        }
                      }}
                      type={type}
                      docId={id}
                      handleTypeChange={handleTypeChange}
                    />
                  </ContentContainer>
                  {editing ? (
                    <ExtraButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.remove(idx)
                      }}
                    >
                      <TrashIcon />
                    </ExtraButton>
                  ) : null}
                </ExtraActionContainer>
              ))
            ) : (
              <EmptyFileItem type="file" />
            )}
          </>
        )}
      />
    </DocumentContainer>
  )
}

export { Document }

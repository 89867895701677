import Modal from 'react-modal'

import { ReactComponent as AllowPermissionIcon } from 'assets/allow_permission.svg'
import { Button } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('text-black bg-white rounded-lg'), {
  padding: '44px 30px',
  maxHeight: '500px',
  maxWidth: '560px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '32px',
})

const Title = styled.h1({
  fontWeight: 700,
  fontSize: '1.75rem',
  textAlign: 'center',
})

const Subtitle = styled.span({
  margin: 0,
  fontSize: '.875rem',
  textAlign: 'center',
})

const ActionButton = styled(Button)(
  apply('flex flex-1 w-full py-3 justify-center items-center rounded-lg')
)

Modal.setAppElement('#root')

function MultiDownloadModal({ isOpen, closeModal, title, subtitle, onConfirm }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      id="unique"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(20, 20, 20, 0.2)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <AllowPermissionIcon width={256} height={256} />
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <ActionButton onClick={() => onConfirm()}>OK</ActionButton>
      </Root>
    </Modal>
  )
}

export { MultiDownloadModal }

import { map, prop, path, pathOr } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { pilotAdminModule } from './module'

const { state$, effectState$ } = pilotAdminModule

const pilotEntities$ = select(state$, (state) => prop('entities', state))
const order$ = select(state$, (state) => prop('order', state))
const inspectedEntity$ = select(state$, (state) => prop('inspectedEntity', state))

const pilotList$ = select(order$, pilotEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const inspectedPilot$ = select(inspectedEntity$, pilotEntities$, (id, entities) =>
  prop(id, entities)
)

const filterQuery$ = select(state$, (state) => prop('filterQuery', state))
const selectedCompanyId$ = select(state$, (state) => prop('companyId', state))

const fetchPilotsStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchPilots', 'status'], effectState)
)

const inspectPilotStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectPilot', inspectedEntity, 'status'], effectState)
)

const isLoadingPilots$ = select(fetchPilotsStatus$, (status) => status === 'pending')

const isInspectingPilot$ = select(inspectPilotStatus$, (status) => status === 'pending')

export const pilotAdminSelectors = {
  pilotEntities$,
  pilotList$,
  inspectedEntity$,
  inspectedPilot$,
  filterQuery$,
  isLoadingPilots$,
  isInspectingPilot$,
  selectedCompanyId$,
}

import ky from 'ky'

import { CONFIG } from 'config'
import { getToken } from 'lib/solta-id'

const REQUEST_MAX_TIMEOUT = 30_000

const http = ky.create({ timeout: REQUEST_MAX_TIMEOUT })

const publicApi = http.extend({ prefixUrl: `${CONFIG.API.URL}` })

const baseApi = http.extend({
  prefixUrl: `${CONFIG.API.URL}`,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = await getToken()

        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const adminApi = baseApi.extend({
  prefixUrl: `${CONFIG.API.URL}/admin`,
})

const REQUEST_MAX_TIMEOUT_UPLOAD = 4 * 60 * 60 * 1000 // 4 hours timeout

const multipartApi = ky.create({
  timeout: REQUEST_MAX_TIMEOUT_UPLOAD,
})

const API_TYPES = {
  ADMIN: 'admin',
  WEB: 'web',
}

export { adminApi, baseApi, publicApi, multipartApi, http, API_TYPES }

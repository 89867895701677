import { Switch, Route } from 'react-router-dom'

import { InvitePilot } from './InvitePilot/InvitePilotGateway'
import { PilotAviationDetails } from './PilotDetails'
import { PilotDetails } from './PilotDetails/PilotDetailsGateway'
import { PilotList } from './PilotList/PilotListGateway'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

// eslint-disable-next-line react/display-name
const withPilotList = (Component) => (props) =>
  (
    <>
      <PilotList />
      <Component {...props} />
    </>
  )

const PilotListWrapper = ({ component }) => (
  <>
    <PilotList />
    {component}
  </>
)

function PilotsManager() {
  return (
    <Container>
      <Switch>
        <Route exact path={['/', '/pilots']} component={PilotList} />
        <Route
          exact
          path={['/pilot/:id', '/pilot/:id/personal-details']}
          render={withPilotList(PilotDetails)}
        />
        <Route
          exact
          path={['/pilot/:id', '/pilot/:id/aviation-details']}
          render={withPilotList(PilotAviationDetails)}
        />
        <Route
          exact
          path="/pilot/:companyId/invite-pilot"
          component={() => <PilotListWrapper component={<InvitePilot />} />}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { PilotsManager }

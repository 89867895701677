const PUBLISH_TYPES = {
  INTERNAL: 'internal',
  SUPPLIER: 'supplier',
  NETWORK: 'network',
}

const PUBLISH_TYPES_DISPLAY_NAMES = {
  [PUBLISH_TYPES.INTERNAL]: 'Internal',
  [PUBLISH_TYPES.SUPPLIER]: 'Supplier',
  [PUBLISH_TYPES.NETWORK]: 'Network',
}

export { PUBLISH_TYPES, PUBLISH_TYPES_DISPLAY_NAMES }

import { useHistory } from 'react-router-dom'

import { Button } from 'components/common/Button'
import { CanCreateJob } from 'lib/permissions'
import { styled, apply } from 'lib/styled'

const CreateJobButton = styled(Button)(
  apply(
    'flex flex-row px-5 py-2 ml-8 items-center text-white font-semibold rounded-lg'
  ),
  ({ theme }) => ({
    height: '48px',
    background: theme.colors.primary,
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  })
)

const CreateJob = () => {
  const history = useHistory()

  return (
    <CanCreateJob>
      <CreateJobButton
        onClick={async () => {
          history.push(`job/new`)
        }}
      >
        Create Job
      </CreateJobButton>
    </CanCreateJob>
  )
}

export { CreateJob }

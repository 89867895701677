import { map, prop, path, pathEq, pathOr } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { companyAdminModule } from './module'

const { state$, effectState$ } = companyAdminModule

const companyEntities$ = select(state$, prop('entities'))
const pilotEntities$ = select(state$, prop('pilotEntities'))
const nbsCompanyEntities$ = select(state$, prop('nbsEntities'))
const order$ = select(state$, prop('order'))
const pilotOrder$ = select(state$, prop('pilotOrder'))
const nbsOrder$ = select(state$, prop('nbsOrder'))
const inspectedEntity$ = select(state$, prop('inspectedEntity'))
const filterQuery$ = select(state$, prop('filterQuery'))
const filterType$ = select(state$, prop('filterType'))
const canResendInviteAt$ = select(state$, prop('canResendInviteAt'))

const companyList$ = select(order$, companyEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const pilotList$ = select(pilotOrder$, pilotEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const nbsCompanyList$ = select(nbsOrder$, nbsCompanyEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const inspectedCompany$ = select(inspectedEntity$, companyEntities$, (id, entities) =>
  prop(id, entities)
)

const fetchCustomersStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchCustomers', 'status'], effectState)
)

const fetchNBSCompaniesStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchNBSCompanies', 'status'], effectState)
)

const isLoadingCompany$ = select(
  fetchCustomersStatus$,
  (status) => status === 'pending'
)

const isLoadingNBSCompany$ = select(
  fetchNBSCompaniesStatus$,
  (status) => status === 'pending'
)

const inspectCustomerStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectCustomer', inspectedEntity, 'status'], effectState)
)

const isInspectingCompany$ = select(
  inspectCustomerStatus$,
  (status) => status === 'pending'
)

const isUploadingImage$ = select(
  effectState$,
  pathEq(['uploadImage', 'status'], 'pending')
)

const isCreatingCustomer$ = select(
  effectState$,
  pathEq(['createCustomer', 'status'], 'pending')
)

const isResendingInvite$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    path(['resendInvite', inspectedEntity, 'status'], effectState) === 'pending'
)

export const companyAdminSelectors = {
  inspectedEntity$,
  companyList$,
  order$,
  pilotList$,
  nbsCompanyList$,
  isLoadingCompany$,
  isLoadingNBSCompany$,
  filterQuery$,
  filterType$,
  isCreatingCustomer$,
  inspectedCompany$,
  isInspectingCompany$,
  isUploadingImage$,
  canResendInviteAt$,
  isResendingInvite$,
}

/* eslint-disable import/no-cycle */
import { createModule } from '@seedcloud/stateless'

import { AuthService } from './service'

import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  isBanned: false,
  organizationId: null,
  auth0UserId: null,
  user: undefined,
})

const setUserBanStatus = (module) => async (_, isBanned) =>
  module.setState({ isBanned })

const getProfile = (module, { setError }) =>
  withTryCatch(
    async () => {
      const user = await AuthService.fetchCurrentAccount()

      const { companyId: organizationId } = user

      module.setState({
        user,
        organizationId,
      })

      return user
    },
    {
      onError: (err) => setError(null, err),
    }
  )

const updateProfile = (module, { setError }) =>
  withTryCatch(
    async (_, payload) => {
      const user = await AuthService.updateCurrentAccount(payload)
      module.setState({
        user,
      })

      return user
    },
    { errHandler: setError }
  )

const changePassword = (_, { setError }) =>
  withTryCatch(
    async () => {
      const response = await AuthService.ChangePassword()
      return response
    },
    { errHandler: setError }
  )

const authModule = createModule({
  name: 'auth',
  initialState: INITIAL_STATE,
  decorators: {
    setUserBanStatus,
    getProfile,
    updateProfile,
    changePassword,
  },
})

export { authModule }

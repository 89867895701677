import { isNilOrEmpty } from '@seedcloud/ramda-extra'
import { Form, Formik as FormProvider } from 'formik'
import Modal from 'react-modal'
import * as Validator from 'yup'

import { FieldGroup as FieldGroupBase, Row, TextField } from '../common'

import { Button, CancelButton as CancelButtonBase } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const { object, string, number } = Validator

const requiredString = (label) => string().required().label(label)

const validationSchema = object({
  description: requiredString('Pilot Category'),
  make: requiredString('Make'),
  model: requiredString('Model'),
  rate: number().positive().integer().required().label('Equipment rate'),
})

const Root = styled.div(apply('text-blwack bg-white rounded-lg'), {
  padding: '40px',
  width: '646px',
  maxWidth: '646px',
})

const FieldGroup = styled(FieldGroupBase)({
  marginBottom: 0,
})

const ActionButton = styled(Button)(apply('flex-1 mt-5'), {
  marginTop: '1rem',
})

const CancelButton = styled(CancelButtonBase)(apply('flex-1 mt-5'), {
  background: 'transparent',
  boxShadow: 'none',
  marginTop: '1rem',
})

Modal.setAppElement('#root')

function EquipmentCategoryModal({
  isOpen,
  closeModal,
  title,
  currency,
  initialValues,
  onSubmit,
  loading,
}) {
  const initialFormValues = isNilOrEmpty(initialValues)
    ? {
        description: '',
        make: '',
        model: '',
        rate: '',
      }
    : { ...initialValues }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      id="unique"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(20, 20, 20, 0.2)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <FormProvider
          enableReinitialize
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <FieldGroup title={title}>
              <Row>
                <TextField
                  label="Equipment Category"
                  name="description"
                  asInput
                  disabled={loading}
                />
                <TextField label="Make" name="make" asInput disabled={loading} />
              </Row>
              <Row>
                <TextField label="Model" name="model" asInput disabled={loading} />
                <TextField
                  label={`Rate (${currency}/hr)`}
                  type="number"
                  name="rate"
                  asInput
                  disabled={loading}
                />
              </Row>
              <Row>
                <CancelButton onClick={closeModal} disabled={loading}>
                  Cancel
                </CancelButton>
                <ActionButton type="submit" disabled={loading}>
                  {loading ? 'Saving...' : 'Save'}
                </ActionButton>
              </Row>
            </FieldGroup>
          </Form>
        </FormProvider>
      </Root>
    </Modal>
  )
}

export { EquipmentCategoryModal }

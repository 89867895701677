import { connect } from '@seedcloud/stateless'
import { useEffect } from 'react'

import { FormInput as FormInputBase } from 'components/common/FormInput'
import { styled, apply } from 'lib/styled'
import { createCompanyModule } from 'modules/legacy-company'

const {
  module: companyModule,
  selectors: { pilotList$ },
} = createCompanyModule()

const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0 ml-0'),
})
const Title = styled.h2(apply('my-6 text-xl font-light'))

const Connected = connect(() => ({
  pilotList: pilotList$,
}))(KeyContactsSection)

function KeyContactsSection({ id, isEditing }) {
  useEffect(() => {
    companyModule.fetchCompanyPilots(id)
  }, [])

  return (
    <>
      <Title>Key Contacts</Title>
      <Details>
        <Column style={apply('flex-1')}>
          <Row>
            <FormInput
              name="user.firstName"
              type="text"
              disabled={!isEditing}
              label="First Name"
            />
            <FormInput
              name="user.lastName"
              type="text"
              disabled={!isEditing}
              label="Last Name"
            />
          </Row>
          <Row>
            <FormInput
              name="user.phoneNumber"
              type="text"
              disabled={!isEditing}
              label="Mobile"
            />
            <FormInput name="user.email" type="text" label="Email" disabled />
          </Row>
        </Column>
      </Details>
    </>
  )
}

export { Connected as KeyContactsSection }

import { Form } from 'formik'
import { useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import CloudLayoutImage from 'assets/clouds_container.svg'
import { FormLayoutContext } from 'components/common/context/FormLayoutContext'
import { Spinner } from 'components/common/Spinner'
import { Tabs } from 'components/common/Tabs'
import { styled, apply } from 'lib/styled'

const Layout = styled.div(
  apply('flex flex-column w-full bg-white rounded-xl shadow-sm relative'),
  {
    overflow: 'hidden',
  }
)

const LayoutForm = Layout.withComponent(Form)

const CloudBackground = styled.div({
  height: '220px',
  backgroundImage: `url('${CloudLayoutImage}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  marginRight: '-24px',
  marginBottom: '-24px',
})

const Container = styled.div(apply('py-8 px-6 relative'), {
  overflowY: 'auto',
  height: '100%',
})

const SpinnerContainer = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

// eslint-disable-next-line complexity
const CustomerDetailsLayout = ({ children, footer, loading, disableTabs }) => {
  const { editing } = useContext(FormLayoutContext)
  const { search } = useLocation()
  const { id } = useParams()

  const LayoutComp = editing ? LayoutForm : Layout

  const tabsBaseFn = () => [
    {
      title: 'Personal Details',
      path: `/my-pilot-info/personal-details${search}`,
      id: 'personal-details',
    },
  ]

  const tabsBase = tabsBaseFn()

  return (
    <LayoutComp>
      <Tabs
        disabled={disableTabs}
        tabs={tabsBase}
        defaultSelectedId="personal-details"
        pathLength={id ? 4 : 3}
      />
      <Container>
        {loading ? (
          <SpinnerContainer>
            <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
          </SpinnerContainer>
        ) : (
          children
        )}
        {!loading ? <CloudBackground /> : null}
      </Container>
      {footer || null}
    </LayoutComp>
  )
}

export { CustomerDetailsLayout }

/* eslint-disable import/no-cycle */
import {
  isNilOrEmpty,
  path,
  filter,
  append,
  mergeDeepRight,
  pick,
  noop,
} from '@seedcloud/ramda-extra'
import { createModule } from '@seedcloud/stateless'

import { DOCUMENT_TYPES } from 'constants/documents'
import { jobModule } from 'modules/job'
import { UploadService } from 'modules/upload'
import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  fileEntities: {},
  folderEntities: {},
  folderOrders: [],
  inspectedFolder: undefined,
  inspectedFile: undefined,
  filterQuery: undefined,
  documentTypes: [],
  sortBy: undefined,
  sortOrder: undefined,
  limit: 15,
  paging: {
    startRow: undefined,
    next: undefined,
  },
})

function documentModule(DocumentService) {
  const fetchJobDocuments = (module, { setError }) =>
    withTryCatch(
      async (_, jobId, { turnPage = false, turnNext } = {}) => {
        const { paging, filterQuery, sortBy, sortOrder, limit, documentTypes } =
          module.getState()
        const next = calcNext(turnPage, turnNext, paging, limit)

        const {
          fileEntities,
          folderEntities,
          folderOrders,
          next: newNext,
        } = await DocumentService.fetchJobDocuments(jobId, {
          q: filterQuery,
          limit: 0,
          next,
          sortBy,
          sortOrder,
          documentTypes,
        })

        module.setState({
          fileEntities,
          folderEntities,
          folderOrders,
          paging: {
            startRow: calcStartRow(newNext, limit, paging),
            next: newNext,
          },
        })
      },
      { errHandler: setError }
    )

  const filterJobDocuments = (module) => (jobId, params) => {
    module.setFilterParams(null, params)
    module.fetchJobDocuments(null, jobId, { turnPage: false })
  }

  const inspectFolder = (module) => (inspectedFolder) => {
    module.setState({
      inspectedFolder,
    })
  }

  const createFolder = (module, { setError }) =>
    withTryCatch(
      (parentPath, { folderName }) => {
        const newFolderEntity = {
          id: folderName,
          name: folderName,
          fullPath: `${parentPath}/${folderName}`,
          files: [],
        }

        module.setState((prevState) => ({
          folderEntities: {
            [folderName]: newFolderEntity,
          },
          folderOrders: append(
            {
              id: folderName,
              children: [],
            },
            prevState.folderOrders
          ),
          inspectedFolder: folderName,
        }))
      },
      { errHandler: setError }
    )

  const downloadJobDocument = (_, { setError }) =>
    withTryCatch(
      async (
        id,
        { multipart = false, fileSize = 0, onProgress = noop, folderId = '' }
      ) => {
        const { entities: jobEntities, inspectedEntity: inspectedJobEntity } =
          jobModule.getState()
        const inspectedJob = path([inspectedJobEntity], jobEntities)

        const file = multipart
          ? await DocumentService.downloadMultipartJobDocument(
              id,
              inspectedJob.id,
              fileSize,
              onProgress,
              folderId
            )
          : await DocumentService.downloadJobDocument(id, inspectedJob.id)

        return file
      },
      { errHandler: setError }
    )

  const uploadJobDocument = (module, { setError }) =>
    withTryCatch(
      async (_, { file, documentType }) => {
        const { inspectedEntity: jobId } = jobModule.getState()

        const { entity: newDocument } = await UploadService.uploadFile(
          file,
          `organization-jobs/${jobId}/upload-documents/`,
          { fileName: file.name, fileSize: file.size, documentType },
          ({ entity }) => {
            DocumentService.deleteJobDocument(entity.id, entity.jobId)
          }
        )

        module.setState((prevState) => ({
          folderEntities: {
            [jobId]: {
              files: append(newDocument.id, prevState.folderEntities[jobId].files),
            },
          },
          fileEntities: mergeDeepRight(prevState.fileEntities, {
            [newDocument.id]: newDocument,
          }),
        }))

        return newDocument
      },
      { errHandler: setError }
    )

  const confirmJobDocumentUpload = (module, { setError }) =>
    withTryCatch(
      async (
        id,
        {
          req,
          reference,
          defaultHeaders,
          documentId,
          fileSize,
          onUploaded,
          onError,
        } = {}
      ) => {
        await UploadService.confirmUpload(
          req,
          reference,
          defaultHeaders,
          documentId,
          fileSize,
          onUploaded,
          onError
        )
        module.filterJobDocuments(id, {
          documentTypes: [
            DOCUMENT_TYPES.JOB_TERMS,
            DOCUMENT_TYPES.JOB_JSEA,
            DOCUMENT_TYPES.JOB_CASA,
            DOCUMENT_TYPES.JOB_ASSET,
          ],
        })
      },
      { errHandler: setError }
    )

  const deleteJobDocument = (module, { setError }) =>
    withTryCatch(
      async (id) => {
        if (isNilOrEmpty(id)) return
        const { fileEntities, folderEntities, inspectedFolder } = module.getState()
        const { entities: jobEntities, inspectedEntity: inspectedJobEntity } =
          jobModule.getState()

        const inspectedJob = path([inspectedJobEntity], jobEntities)

        await DocumentService.deleteJobDocument(id, inspectedJob.id)

        const updatedOrder = filter(
          (v) => v !== id,
          folderEntities[inspectedFolder].files
        )

        module.setState({
          folderEntities: {
            [inspectedFolder]: {
              files: updatedOrder,
            },
          },
        })

        delete fileEntities[id]

        module.setState({
          fileEntities,
        })
      },
      { errHandler: setError }
    )

  const renameJobDocument = (module, { setError }) =>
    withTryCatch(
      async (id, newFileName) => {
        const { inspectedEntity: inspectedJobId } = jobModule.getState()

        const { document } = await DocumentService.renameJobDocument(
          id,
          inspectedJobId,
          newFileName
        )

        module.setState((prevState) => ({
          fileEntities: mergeDeepRight(prevState.fileEntities, {
            [document.id]: document,
          }),
        }))
      },
      { errHandler: setError }
    )

  const setFilterParams = (module) => (_, params) => {
    const updateParams = pick(
      ['filterQuery', 'paging', 'sortBy', 'sortOrder', 'limit', 'documentTypes'],
      params
    )
    module.setState(updateParams)
  }

  const reset = (module) => () =>
    /**
     * The `setState` method second argument is a merging function.
     * It defaults to `mergeDeepRight` function
     * This merging operation is performed as follows:
     *
     * `state = mergeFunction(state, incomingState);`
     *
     * Our intention is to restore the original values
     * rather than merging with the previous object to
     * prevent potential inconsistencies.
     */

    module.setState(INITIAL_STATE, (_, incomingState) => incomingState)

  return createModule({
    name: 'document',
    initialState: INITIAL_STATE,
    decorators: {
      setFilterParams,
      fetchJobDocuments,
      inspectFolder,
      createFolder,
      filterJobDocuments,
      downloadJobDocument,
      uploadJobDocument,
      deleteJobDocument,
      renameJobDocument,
      confirmJobDocumentUpload,
      reset,
    },
  })
}

export { documentModule }

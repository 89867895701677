/* eslint-disable complexity */
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import { filter } from '@seedcloud/ramda-extra'
import FS from 'filesize'
import { useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import TinyQueue from 'tinyqueue'

import CloudBg from 'assets/cloud_bg.svg'
import { ReactComponent as FileIconSvg } from 'assets/document_filled.svg'
import { ReactComponent as DownloadIcon } from 'assets/feathers/download.svg'
import { ReactComponent as FolderIcon } from 'assets/folder_filled.svg'
import { Button } from 'components/common/Button'
import { isPilotDocumentAsset } from 'components/jobs/JobDetails/utils/documentFilters'
import { apply, styled } from 'lib/styled'

const Container = styled.div(
  apply('flex flex-column bg-white w-full py-8 px-4 rounded-lg shadow-sm')
)

const Wrapper = styled.div(
  apply('grid w-full', {
    gap: '2rem',
    backgroundImage: `url('${CloudBg}')`,
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    '@media (max-width: 1024px)': {
      gridTemplateColumns: '1fr',
    },
  })
)

const TitleText = styled.span(
  apply('text-2xl font-bold', {
    marginBottom: '32px',
  })
)

const Col = styled.div(apply('grid', { gridTemplateColumns: '1fr' }))

const Row = styled.div(
  apply('flex flex-column w-full items-start', {
    flex: '0 0 100%',
    maxWidth: '100%',
    marginBottom: '40px',
    gap: '8px',
  })
)

const Header = styled.div(
  apply('flex items-center justify-between w-full pb-2', {
    borderBottom: '1px solid #ccc',
    flexWrap: 'wrap',
  })
)

const TotalAssets = styled.span(apply('text-sm'))

// Temporarily disable Download All
// const DownloadAllButton = styled(Button)(
//   apply('ml-auto', {
//     textTransform: 'none',
//     backgroundColor: '#00326F',
//     color: '#fff',
//     minWidth: '150px',
//     borderRadius: '8px',
//     '&:hover': {
//       backgroundColor: '#0050a1',
//     },
//   })
// )

const ProgressBarWrapper = styled.div(apply('flex items-center align-center h-full'))
const ProgressBarLabel = styled.span(
  apply('flex-shrink-0 text-xs text-right', {
    minWidth: '95px',
    color: '#AAA',
  })
)
const ProgressBar = styled(LinearProgress)(
  apply('w-full mr-2', {
    height: '4px',
    minWidth: '175px',
    flexGrow: 1,
    backgroundColor: '#EEF1F5',
    borderRadius: '8px',
    '.MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#009DE4',
    },
  })
)

const Folder = styled.div({
  ':not(:last-of-type)': {
    marginBottom: '40px',
  },
})
const FolderTitle = styled.span(apply('text-base font-bold', { color: '#000' }))
const FolderDescription = styled.span(apply('text-xs', { color: '#AAA' }))
const FolderWrapper = styled.div(
  apply('flex items-center', {
    gap: '8px',
  })
)
const IconWrapper = styled.div(apply('flex items-center justify-center h-full mr-1'))
const TextWrapper = styled.div(
  apply('flex flex-column ', {
    minWidth: '156px',
  })
)

const FileWrapper = styled.div(
  apply('flex flex-column items-center bg-white rounded-lg shadow-sm relative', {
    padding: '16px',
    cursor: 'pointer',
    position: 'relative',
    transition: 'background-color 0.2s ease-in-out',
    minWidth: '150px',
    minHeight: '150px',
    flexGrow: 1,
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  })
)

const FileIcon = styled(FileIconSvg)({
  width: '56px',
  height: '56px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  color: '#00326F',
})

const DownloadAssetsButton = styled(Button)(
  apply('ml-auto', {
    float: 'right',
    textTransform: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: '#00326F',
    border: '1px solid transparent',
    minWidth: '167px',
    transition: 'border-color 0.2s ease-in-out',
    '&:hover': {
      border: '1px solid #00326F',
    },
  })
)

const FileGridWrapper = styled.div(
  apply('grid', {
    gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
    justifyContent: 'center',
    gap: '32px',
    '@media (max-width: 1280px)': {
      gap: '24px',
    },
    '@media (max-width: 1024px)': {
      gap: '16px',
    },
  })
)

const FileName = styled.p(
  apply('text-base mt-2 mb-1', {
    color: '#000',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '150px',
    cursor: 'default',
  })
)

const FileSize = styled.span(apply('text-xs', { color: '#AAA' }))

const File = ({ id, fileName, fileSize, onDownload }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <FileWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onDownload}
      key={id}
    >
      {isHovered ? (
        <DownloadIcon
          style={{
            width: '56px',
            height: '56px',
            color: '#00326F',
          }}
        />
      ) : (
        <FileIcon />
      )}
      <FileName data-tip={fileName}>{fileName}</FileName>
      <FileSize>{fileSize ? FS(fileSize) : '- KB'}</FileSize>
      <ReactTooltip />
    </FileWrapper>
  )
}

const FolderView = ({
  folderTitle,
  folderId,
  folderSize,
  files,
  onDownload,
  onMultiDownload,
  progress,
}) => {
  const fileList = Object.values(files)
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownload = () => {
    if (!isDownloading) {
      setIsDownloading(true)
      onMultiDownload(files, folderId).finally(() => setIsDownloading(false))
    }
  }

  const folderProgress = progress?.[folderId]

  const progressBarValue = Math.floor(
    (folderProgress?.currentProgress / folderSize) * 100
  )
  const progressBarLabel = folderProgress
    ? `${FS(folderProgress?.currentProgress)} of ${FS(folderSize)}`
    : ''

  return (
    <Folder>
      <Row>
        <Header>
          <FolderWrapper>
            <IconWrapper>
              <FolderIcon width={32} height={32} />
            </IconWrapper>
            <TextWrapper>
              <FolderTitle>{folderTitle}</FolderTitle>
              <FolderDescription>
                {fileList?.length} files - {FS(folderSize)}
              </FolderDescription>
            </TextWrapper>
          </FolderWrapper>
          {folderProgress ? (
            <ProgressBarWrapper>
              <ProgressBar variant={'determinate'} value={progressBarValue} />
              <ProgressBarLabel>{progressBarLabel}</ProgressBarLabel>
            </ProgressBarWrapper>
          ) : (
            <DownloadAssetsButton onClick={handleDownload} disabled={isDownloading}>
              {isDownloading ? 'Downloading...' : 'Download Assets'}
              <DownloadIcon className="ml-2" style={{ color: '#00326F' }} />
            </DownloadAssetsButton>
          )}
        </Header>
      </Row>
      <FileGridWrapper>
        {fileList?.map((file, index) => (
          <File
            key={file?.id || index}
            id={file?.id}
            fileName={file?.fileName}
            fileSize={file?.fileSize}
            onDownload={() => onDownload(file?.id, file?.fileSize, file?.fileName)}
          />
        ))}
      </FileGridWrapper>
    </Folder>
  )
}

function OrderAssets({
  jobId,
  fileEntities,
  jobFolderList,
  onDownload,
  onMultiDownload,
  progress,
}) {
  const allFiles = useMemo(
    () => filter(isPilotDocumentAsset, Object.values(fileEntities)),
    [fileEntities]
  )

  const jobFolder = useMemo(
    () =>
      jobFolderList?.map(({ name, files, id }) => {
        // Filter only assets uploaded by the Pilot
        const pilotFiles = files.reduce((acc, fileId) => {
          const file = fileEntities[fileId]
          if (isPilotDocumentAsset(file)) acc.push(file)
          return acc
        }, [])
        const queue = new TinyQueue(pilotFiles)
        const ordered = []
        let folderSize = 0
        while (queue?.length) {
          folderSize += +queue.peek().fileSize
          ordered.push(queue.pop())
        }

        return { name, files, id, pilotFiles: ordered, folderSize }
      }),
    [fileEntities]
  )

  // const [isDownloading, setIsDownloading] = useState(false)

  return (
    <Container>
      <Wrapper>
        <Col>
          <TitleText>All Assets</TitleText>
          <Row>
            <Header>
              <TotalAssets>Total {allFiles.length} Assets</TotalAssets>
              {/* <DownloadAllButton 
                onClick={() => {
                  setIsDownloading(true)
                  onMultiDownload(allFiles).finally(() => setIsDownloading(false))
                }}
                disabled={isDownloading}
              >
                {isDownloading ? 'Downloading...' : 'Download All'}
              </DownloadAllButton> */}
            </Header>
          </Row>
          {jobFolder?.map(({ name, id, pilotFiles, folderSize }) => (
            <FolderView
              key={id}
              folderTitle={name === jobId ? 'Unassigned' : name}
              folderId={id}
              folderSize={folderSize}
              fileCount={pilotFiles.length}
              files={pilotFiles}
              onDownload={onDownload}
              onMultiDownload={onMultiDownload}
              progress={progress}
            />
          ))}
        </Col>
      </Wrapper>
    </Container>
  )
}

export { OrderAssets }

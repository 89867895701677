import { PilotCategoriesTable } from '../../common/tables/PilotCategoriesTable'
import { CURRENCY_VALUE } from '../common/constants'

import { CurrencySelectField } from './CurrencySelectField'
import { PilotCategoriesLayout } from './PilotCategoriesLayout'
import { PilotCategoryModal } from './PilotCategoryModal'

import { Button } from 'components/common/Button'
import { ConfirmModal } from 'components/common/ConfirmModal/ConfirmModal'
import { FieldGroup } from 'components/company/common'
import { styled, apply } from 'lib/styled'

const Space = styled.div(apply('flex'), {
  gap: '1rem',
  flex: '0 0 300px',
})

// eslint-disable-next-line no-unused-vars
const currencyOptions = Object.entries(CURRENCY_VALUE).map(([_, value]) => ({
  value,
  label: value,
}))

const PilotCategories = ({
  pilotCategoriesList,
  pilotCategoriesFilters,
  isUpdatingCurrency,
  companyCurrency = 'AUD $',
  setCurrencyModal,
  showAddModal,
  setShowAddModal,
  setSelected,
  handleShowEdit,
  handleCloseModal,
  handleOpenDeleteModal,
  handleCreateCategory,
  selectedCategory,
  showEditModal,
  setShowEditModal,
  handleCloseEditModal,
  handleEditPilotCategory,
  showDeleteModal,
  setShowDeleteModal,
  handleDeletePilotCategory,
  showCurrencyModal,
  submitting,
  selected,
  handleUpdateCompanyCurrency,
  isAdmin,
  isInternalEntity = true,
  type,
  company,
  status,
  loading,
  onTurnPage,
}) => {
  const {
    paging: { startRow, next },
    limit,
  } = pilotCategoriesFilters

  return (
    <PilotCategoriesLayout
      isAdmin={isAdmin}
      isInternalEntity={isInternalEntity}
      loading={loading}
      type={type}
      company={company}
      status={status}
    >
      <FieldGroup
        extra={
          <Space>
            <CurrencySelectField
              label="Currency"
              options={currencyOptions}
              selected={companyCurrency}
              name="currency"
              onChange={(option) => {
                setCurrencyModal(true)
                setSelected(option.value)
              }}
              style={{ height: '40px' }}
            />
            <Button onClick={() => setShowAddModal(true)} style={{ height: '40px' }}>
              Add New Category
            </Button>
          </Space>
        }
      >
        <PilotCategoriesTable
          currency={companyCurrency}
          values={pilotCategoriesList}
          handleEdit={handleShowEdit}
          handleDelete={handleOpenDeleteModal}
          limit={limit}
          next={next}
          startRow={startRow}
          onTurnPage={onTurnPage}
        />
      </FieldGroup>

      <PilotCategoryModal
        loading={submitting}
        title="Create New Category"
        currency={companyCurrency}
        isOpen={showAddModal}
        setIsOpen={setShowAddModal}
        closeModal={handleCloseModal}
        onSubmit={handleCreateCategory}
      />

      <PilotCategoryModal
        loading={submitting}
        title="Edit Pilot Category"
        initialValues={selectedCategory}
        currency={companyCurrency}
        isOpen={showEditModal}
        setIsOpen={setShowEditModal}
        closeModal={handleCloseEditModal}
        onSubmit={handleEditPilotCategory}
      />

      <ConfirmModal
        title="Delete Category"
        subtitle="Are you sure you want to delete this pilot category?"
        isOpen={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        confirmLoadingText="Deleting..."
        loading={submitting}
        onConfirm={handleDeletePilotCategory}
      />

      <ConfirmModal
        title="Change Currency"
        subtitle={
          <span>
            Are you sure you want to apply the new currency <b>({selected})</b> to pilot
            categories?
          </span>
        }
        isOpen={showCurrencyModal}
        closeModal={() => setCurrencyModal(false)}
        confirmLoadingText="Updating..."
        loading={isUpdatingCurrency}
        onConfirm={handleUpdateCompanyCurrency}
      />
    </PilotCategoriesLayout>
  )
}

export { PilotCategories }

import { createModule } from '@seedcloud/stateless'
import { toast } from 'react-toastify'

import { SupplierService } from './service'

import { SUPPLIER_LABELS } from 'constants/supplier'
import { getOrganizationId } from 'utils/getOrganizationId'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  order: [],
  filterQuery: '',
  invitationStatus: undefined,
  filterType: undefined,
})

const fetchSuppliers = (module, { setError }) =>
  withTryCatch(
    async () => {
      const { filterQuery, filterType, invitationStatus } = module.getState()

      const { entities, order } = await SupplierService.list({
        query: filterQuery,
        type: filterType,
        invitationStatus,
      })

      module.setState({
        entities,
        order,
      })
    },
    { errHandler: setError }
  )

const filterSuppliers = (module) => (_, filter) => {
  module.setState({
    ...filter,
  })

  module.fetchSuppliers()
}

const inspectSupplier = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      module.setState({
        inspectedEntity: id,
      })

      const entity = await SupplierService.read(id)

      module.setState({
        entities: { [id]: entity },
      })

      return entity
    },
    { errHandler: setError }
  )

const publishSupplier = (module, { setError }) =>
  withTryCatch(
    async (_, payload) => {
      const { organizationId } = await getOrganizationId()

      const supplier = await SupplierService.publish(organizationId, payload)

      return supplier
    },
    { errHandler: setError }
  )

const submitSupplier = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const invitationStatus = SUPPLIER_LABELS.ACTIVE

      await SupplierService.update(id, {
        invitationStatus: SUPPLIER_LABELS.ACTIVE,
      })

      toast.success('Supplier status has been updated!')

      module.setState({
        entities: { [id]: { company: { invitationStatus } } },
      })
    },
    { errHandler: setError }
  )

const updateSupplierCurrency = (module, { setError }) =>
  withTryCatch(
    async (id, currency) => {
      const payload = {
        currency,
      }
      const updatedPilotCategories = await SupplierService.update(id, payload)

      module.setState({
        entities: { [id]: { ...updatedPilotCategories } },
      })
    },
    { errHandler: setError }
  )

const downloadAviationDocuments = (module, { setError }) =>
  withTryCatch(async (id, companyId) => SupplierService.download(id, companyId), {
    errHandler: setError,
  })

const reset = (module) => () =>
  module.setState(INITIAL_STATE, (_, incomingState) => incomingState)

const supplierModule = createModule({
  name: 'supplier',
  initialState: INITIAL_STATE,
  decorators: {
    fetchSuppliers,
    filterSuppliers,
    inspectSupplier,
    updateSupplierCurrency,
    downloadAviationDocuments,
    publishSupplier,
    submitSupplier,
    reset,
  },
})

export { supplierModule }

import { ReactComponent as SearchIcon } from 'assets/feathers/search.svg'
import { Button } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const SearchButton = styled(Button)(
  apply('rounded-lg border-0 flex items-center justify-center', {
    height: '48px',
    width: '48px',
  }),
  ({ theme }) => ({
    background: theme.colors.primary,
  })
)

const SearchAction = () => (
  <SearchButton type="submit">
    <SearchIcon height={38} width={38} color="white" />
  </SearchButton>
)

export { SearchAction }

import { useState } from 'react'

import CloudImage from 'assets/clouds_platform.svg'
import { styled, apply, keyframes } from 'lib/styled'

const slideUp = keyframes({
  from: {
    height: 150,
  },
  to: {
    height: 0,
  },
})

const slideDown = keyframes({
  from: {
    height: 0,
  },
  to: {
    height: '150px',
  },
})

const BasePlatformPlan = styled.div(
  apply('px-4 pt-6 pb-4 rounded-lg'),
  ({ initial }) => ({
    backgroundColor: '#EEF1F5',
    backgroundImage: `url('${CloudImage}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    marginBottom: '2.5em',
    '& * ': {
      animationDuration: !initial ? '0s !important' : undefined,
    },
  })
)

const PlatformHeadWrapper = styled.div(apply('flex w-full pb-4'), {
  borderBottom: '1px solid #AAAAAA',
})
const PlatformTitleWrapper = styled.div(apply('ml-4'))

const PlatformIcon = styled.div({
  padding: '.45em',
})

const PlatformTitle = styled.h1({
  fontSize: '1.0625rem',
  fontWeight: 800,
})

const PlatformTitleDescription = styled.p({
  fontSize: '0.9375rem',
  marginBottom: 0,
})

const PlatformPriceWrapper = styled.div({
  marginLeft: 'auto',
  textAlign: 'right',
})

const PlatformPricePlan = styled.span({
  fontSize: '0.9375rem',
  fontWeight: 500,
})

const PlatformPrice = styled.p({
  marginBottom: '0',
  fontSize: '1.3125rem',
  fontWeight: 800,
})

const PlatformPlanContent = styled.ul({
  listStyle: 'none',
  padding: 0,
  marginTop: '1em',
  overflow: 'hidden',
  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
})

const PlatformPlanItems = styled.li({
  color: '#00326F',
  marginLeft: 0,
  marginBottom: '1em',
  '& > span': {
    fontWeight: 700,
  },
})

const PlatformPlanTrigger = styled.div({
  display: 'flex',
  justifyContent: 'center',
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '&[data-state="open"] svg': {
    transform: 'rotate(180deg)',
  },
})

const PlatformPlanTriggerButton = styled.button({
  all: 'unset',
  cursor: 'pointer',
  '& > span': {
    fontSize: '0.6875rem',
  },
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
})

const IconHeading = () => (
  <PlatformIcon>
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.544006 3.1002C0.544006 2.46368 0.796863 1.85323 1.24695 1.40314C1.69704 0.953052 2.30749 0.700195 2.94401 0.700195H31.744C32.3805 0.700195 32.991 0.953052 33.4411 1.40314C33.8912 1.85323 34.144 2.46368 34.144 3.1002V7.9002C34.144 8.53671 33.8912 9.14716 33.4411 9.59725C32.991 10.0473 32.3805 10.3002 31.744 10.3002H2.94401C2.30749 10.3002 1.69704 10.0473 1.24695 9.59725C0.796863 9.14716 0.544006 8.53671 0.544006 7.9002V3.1002ZM0.544006 17.5002C0.544006 16.8637 0.796863 16.2532 1.24695 15.8031C1.69704 15.3531 2.30749 15.1002 2.94401 15.1002H17.344C17.9805 15.1002 18.591 15.3531 19.0411 15.8031C19.4911 16.2532 19.744 16.8637 19.744 17.5002V31.9002C19.744 32.5367 19.4911 33.1472 19.0411 33.5973C18.591 34.0473 17.9805 34.3002 17.344 34.3002H2.94401C2.30749 34.3002 1.69704 34.0473 1.24695 33.5973C0.796863 33.1472 0.544006 32.5367 0.544006 31.9002V17.5002ZM26.944 15.1002C26.3075 15.1002 25.697 15.3531 25.2469 15.8031C24.7969 16.2532 24.544 16.8637 24.544 17.5002V31.9002C24.544 32.5367 24.7969 33.1472 25.2469 33.5973C25.697 34.0473 26.3075 34.3002 26.944 34.3002H31.744C32.3805 34.3002 32.991 34.0473 33.4411 33.5973C33.8912 33.1472 34.144 32.5367 34.144 31.9002V17.5002C34.144 16.8637 33.8912 16.2532 33.4411 15.8031C32.991 15.3531 32.3805 15.1002 31.744 15.1002H26.944Z"
        fill="#009DE4"
      />
    </svg>
  </PlatformIcon>
)

const IconAction = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.84412 8L12.8441 15L19.8441 8"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const PlatformPlan = () => {
  const plans = [
    {
      name: 'Internal Staff',
      maximum: 6,
    },
    {
      name: 'Internal Pilot',
      maximum: 6,
    },
    {
      name: 'External Customer',
      maximum: 6,
    },
    {
      name: 'External Supplier',
      maximum: 6,
    },
  ]

  const [expanded, setExpanded] = useState(false)
  const [initialSelect, setInitialSelect] = useState(false)

  return (
    <BasePlatformPlan initial={initialSelect}>
      <PlatformHeadWrapper>
        <IconHeading />
        <PlatformTitleWrapper>
          <PlatformTitle>Platform Plan</PlatformTitle>
          <PlatformTitleDescription>
            Contact admin platform to upgrade your plan
          </PlatformTitleDescription>
        </PlatformTitleWrapper>
        <PlatformPriceWrapper>
          <PlatformPricePlan>Basic</PlatformPricePlan>
          <PlatformPrice>Beta Plan</PlatformPrice>
        </PlatformPriceWrapper>
      </PlatformHeadWrapper>
      <PlatformPlanContent data-state={expanded ? 'open' : 'closed'}>
        {expanded
          ? plans.map((plan, idx) => (
              <PlatformPlanItems key={`${plan.name}${idx}`}>
                Maximum of <span>{plan.maximum}</span> {plan.name.toLowerCase()}
              </PlatformPlanItems>
            ))
          : null}
      </PlatformPlanContent>
      <PlatformPlanTrigger data-state={expanded ? 'open' : 'closed'}>
        <PlatformPlanTriggerButton
          type="button"
          onClick={() => {
            setExpanded((expanded) => !expanded)
            setInitialSelect(true)
          }}
        >
          <span>See {expanded ? 'Less' : 'More'} </span>
          <IconAction />
        </PlatformPlanTriggerButton>
      </PlatformPlanTrigger>
    </BasePlatformPlan>
  )
}

export { PlatformPlan }

import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider } from 'formik'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { SupplierLayout } from '../common/SupplierLayout'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { Details } from 'components/company/AviationDetail/Fields/Details'
import { Document } from 'components/company/AviationDetail/Fields/Document'
// import { Equipment } from 'components/company/AviationDetail/Fields/Equipment'
import { Insurance } from 'components/company/AviationDetail/Fields/Insurance'
import { License } from 'components/company/AviationDetail/Fields/License'
import { Links } from 'components/company/AviationDetail/Fields/Links'
import { FieldGroup, Row } from 'components/company/common'
import {
  aviationDetail$,
  companyName$,
  invitationStatus$,
  isInspectingSupplier$,
  supplierModule,
} from 'modules/supplier'

const SupplierAviation = ({
  aviationDetail = {},
  isInspectingSupplier,
  status,
  companyName,
}) => {
  const [editing, setEditing] = useState(false)

  const { id } = useParams()

  useEffect(() => {
    supplierModule.inspectSupplier(id)
  }, [id])

  const handleDownloadDocuments = (documentId) =>
    supplierModule.downloadAviationDocuments(documentId, id)

  const {
    country,
    companyArn,
    documents,
    links,
    licenses,
    insuranceNumber,
    insuranceProvider,
    insuranceIssuedAt,
    insuranceExpiredAt,
    equipments,
  } = aviationDetail

  const initialValues = {
    country,
    companyArn,
    licenses,
    insuranceNumber,
    insuranceProvider,
    insuranceIssuedAt,
    insuranceExpiredAt,
    equipments,
    documents,
    links,
  }

  return (
    <FormLayoutProvider value={{ editing, setEditing }}>
      <FormProvider enableReinitialize initialValues={initialValues}>
        {({ values }) => (
          <SupplierLayout
            company={companyName}
            status={status}
            loading={isInspectingSupplier}
          >
            <Details
              title="Aviation Details"
              values={{ country, companyArn }}
              showEditButton={false}
            />
            <License name="licenses" title="Licence" values={values.licenses} />
            <Insurance
              title="Insurance"
              values={{
                insuranceNumber,
                insuranceProvider,
                insuranceIssuedAt,
                insuranceExpiredAt,
              }}
            />
            {/* <Equipment
              name="equipments"
              title="Equipments"
              values={values.equipments}
            /> */}
            <FieldGroup title="Documents & Links">
              <Row>
                <Document
                  values={values.documents}
                  handleDownloadDocuments={handleDownloadDocuments}
                />
                <Links values={values.links} />
              </Row>
            </FieldGroup>
          </SupplierLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

const ConnectedSupplierAviation = connect(() => ({
  aviationDetail: aviationDetail$,
  isInspectingSupplier: isInspectingSupplier$,
  status: invitationStatus$,
  companyName: companyName$,
}))(SupplierAviation)

export { ConnectedSupplierAviation as SupplierAviation }

import { isNotNaN, trim } from '@seedcloud/ramda-extra'
import { format, isValid } from 'date-fns'

import { addSearchParams } from './addSearchParams'

const addDateParams = (date) =>
  addSearchParams(date, (incomingDate) => {
    const date = new Date(incomingDate)
    return isValid(date) ? format(date, 'yyyy-MM-dd') : undefined
  })

const addStringParams = (str) =>
  addSearchParams(str, (incomingStr) => trim(incomingStr))

const addNumberParams = (number) =>
  addSearchParams(number, (incomingNumber) => {
    const num = Number(incomingNumber)
    return isNotNaN(num) ? num : undefined
  })

export { addDateParams, addStringParams, addNumberParams }

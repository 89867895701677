import { prepend } from '@seedcloud/ramda-extra'
import Select from 'react-select'

import { newStyles } from 'components/common/SelectField'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1'))

function TypeCompanySelectField({
  id,
  name,
  label,
  type = 'select',
  containerProps,
  companyList,
  onChange,
  selected,
  ...props
}) {
  const options = prepend(
    { value: undefined, label: 'Any Company' },
    companyList.map((c) => ({
      label: c.company.companyName,
      value: c.id,
    }))
  )

  return (
    <Container {...containerProps}>
      <Select
        type={type}
        value={options.find(({ value }) => value === selected)}
        onChange={onChange}
        options={options}
        menuPlacement="auto"
        styles={newStyles()}
        isSearchable={false}
        placeholder="Select Company"
        {...props}
      />
    </Container>
  )
}

export { TypeCompanySelectField }

import { Formik as FormProvider } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { RegisterForm } from './RegisterForm'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { NearbyskyLogoIcon } from 'components/icons/NearbyskyLogoIcon'
import { styled, apply } from 'lib/styled'
import { userModule } from 'modules/user'
import { requiredString } from 'utils/validation'

const Background = styled.div(
  apply('flex flex-column justify-center items-center', {
    backgroundColor: '#EDF2F7',
  })
)
const Container = styled.div(
  apply('flex flex-column justify-center items-center  px-10 py-16', {
    backgroundColor: '#FFFFFF',
    margin: '155px auto',
    width: '646px',
    borderRadius: '16px',
  })
)
const Title = styled.h2(apply('mt-6 text-4xl font-bold'))
const Description = styled.p(
  apply('m-0 text-gray-600 text-center', {
    color: '#595959',
  })
)

const Scrollable = styled.div(
  apply('flex flex-column p-6', {
    minHeight: 'min-content',
  })
)

const { string, object } = Validator

const validationSchema = object({
  organizationDetails: object({
    companyName: requiredString('Company Name'),
  }),
  userDetails: object({
    firstName: requiredString('First Name'),
    email: string().email('Invalid email').required('Email is required'),
  }),
})

const initialValues = {
  organizationDetails: {
    companyName: '',
    abn: '',
    companyPhone: '',
    companyWebsite: '',
  },
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
}

const FormContainer = styled.div({
  width: '100%',
})

function Register() {
  const history = useHistory()
  const handleSubmit = async (values) => {
    await userModule.registerSupplier(null, values).then((response) => {
      if (response) {
        history.push('/register-success')
      }
    })
  }
  return (
    <Background>
      <Container>
        <NearbyskyLogoIcon />
        <Title>Welcome</Title>
        <Description>
          As a Client/Supplier you are able to manage your own hub, invite customer,
          invite pilots and see jobs.
        </Description>
        <FormContainer>
          <FormLayoutProvider value={{ editing: true }}>
            <FormProvider
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values)
              }}
            >
              {({ values }) => (
                <Scrollable>
                  <RegisterForm values={values} />
                </Scrollable>
              )}
            </FormProvider>
          </FormLayoutProvider>
        </FormContainer>
      </Container>
    </Background>
  )
}

export { Register }

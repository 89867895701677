import { defaultTo, isNotNil, omit, pick, prop } from '@seedcloud/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi, baseApi, http } from 'lib/http-client'

const pilotEntity = new schema.Entity('pilots')

const PilotWebService = {
  async list({ companyId = '', query = '', limit = 1000, next, sortBy }) {
    const config = {
      searchParams: {
        q: query,
        companyId,
        limit,
        sortBy,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await adminApi.get('pilots', config).json()
    const normalized = normalize(items, [pilotEntity])
    const entities = defaultTo({}, prop('pilots', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async read(id) {
    return adminApi.get(`pilots/${id}`).json()
  },

  async updateIndividualPilot(payload) {
    const { userDetails, equipments = [], ...pilotDetails } = payload

    const dataUser = omit(['email', 'companyName', 'pilotCategoryName'], userDetails)

    let mEquipments
    if (equipments.length) {
      mEquipments = equipments.map(({ name, serialNumber }) => ({
        name,
        serialNumber,
      }))
    }

    const updatedPilot = await baseApi
      .patch(`pilots`, {
        json: {
          userDetails: dataUser,
          ...pilotDetails,
          equipments: mEquipments,
        },
      })
      .json()

    return updatedPilot
  },

  async update(pilotId, payload) {
    const json = pick(
      [
        'companyName',
        'address',
        'addressLocation',
        'insuranceExpiredAt',
        'insuranceNumber',
        'licenceType',
        'licenceExpiredAt',
        'licenceNumber',
        'serviceCategories',
        'pilotCategoryId',
        'serviceAreas',
        'serviceRadius',
      ],
      payload
    )

    const updatedPilot = await adminApi.patch(`pilots/${pilotId}`, { json }).json()

    return updatedPilot
  },

  // TODO(Ilham): Make abstract to remove duplicate code with updateIndividualPilot
  async updatePilotById(id, payload) {
    const { userDetails, equipments = [], ...pilotDetails } = payload

    const dataUser = omit(['email', 'companyName', 'pilotCategoryName'], userDetails)

    let mEquipments
    if (equipments.length) {
      mEquipments = equipments.map(({ name, serialNumber }) => ({
        name,
        serialNumber,
      }))
    }

    const updatedPilot = await baseApi
      .patch(`pilots/${id}`, {
        json: {
          userDetails: dataUser,
          ...pilotDetails,
          equipments: mEquipments,
        },
      })
      .json()

    return updatedPilot
  },

  async create(payload) {
    const json = pick(
      [
        'userDetails',
        'companyName',
        'address',
        'addressLocation',
        'insuranceExpiredAt',
        'insuranceNumber',
        'licenceType',
        'licenceExpiredAt',
        'licenceNumber',
        'serviceCategories',
        'pilotCategoryId',
        'serviceAreas',
        'serviceRadius',
        'termsAndConditionsAccepted',
      ],
      payload
    )

    const pilot = await baseApi.post(`pilots`, { json }).json()

    return pilot
  },

  async downloadDocument(documentId, pilotId) {
    const { signedUrl } = await adminApi
      .get(`pilots/${pilotId}/documents/${documentId}`)
      .json()

    return http.get(signedUrl).blob()
  },
}

export { PilotWebService }

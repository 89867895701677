import Modal from 'react-modal'

import {
  Button as ButtonBase,
  CancelButton as CancelButtonBase,
} from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('text-black bg-white rounded-lg p-8 py-4'))

const Title = styled.h2(apply('text-xl font-semibold mt-2 mb-4 px-2'))

const Message = styled.div(apply('text-base py-1 px-2'))

const ButtonContainer = styled.div(apply('w-full flex justify-between mt-6'))

const CancelButton = styled(CancelButtonBase)(apply('w-5 mx-1 bg-grey-lighter'))

const DeleteButton = styled(ButtonBase)(apply('w-5 mr-1 bg-red border-red-lighter'), {
  '&:active': apply('bg-red-lighter border-red-light'),
})

Modal.setAppElement('#root')

function DeleteFileModal({ isOpen, setIsOpen, fileName, deleteHandler }) {
  const closeModal = () => {
    setIsOpen(false)
  }

  const onDelete = () => {
    deleteHandler()
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      id="special"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <Title>Delete file</Title>
        <Message>
          Are you sure to delete <span style={apply('text-red-light')}>{fileName}</span>
          ?
        </Message>
        <Message>It will be deleted permanently.</Message>

        <ButtonContainer>
          <CancelButton type="button" onClick={closeModal} variant="secondary">
            Cancel
          </CancelButton>
          <DeleteButton onClick={onDelete}>Delete</DeleteButton>
        </ButtonContainer>
      </Root>
    </Modal>
  )
}

export { DeleteFileModal }

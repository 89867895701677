import { Formik as FormProvider } from 'formik'

import { PersonalDetails } from './Fields/PersonalDetails'
import { PilotDetailsLayout } from './PilotDetailsLayout'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { FormAction } from 'components/company/common'

function PilotDetails({
  initialValues,
  validationSchema,
  editing,
  setEditing,
  id,
  userId,
  paramsId,
  avatar,
  pilotCategoriesList,
  isUpdatingIndividualPilot,
  pilotModule,
  isUploadingAvatar,
  loading,
  onSubmit,
}) {
  if (loading) return <PilotDetailsLayout disableTabs loading />

  return (
    <FormLayoutProvider value={{ editing }}>
      <FormProvider
        id="pilotDetails"
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, resetForm }) => (
          <PilotDetailsLayout
            disableTabs={editing}
            footer={
              editing && (
                <FormAction
                  loading={isUpdatingIndividualPilot || isUploadingAvatar}
                  loadingText={
                    isUpdatingIndividualPilot ? 'Updating...' : 'Uploading...'
                  }
                  okText="Save"
                  onCancel={() => {
                    resetForm()
                    setEditing(false)
                  }}
                />
              )
            }
          >
            <PersonalDetails
              id={id}
              userId={userId}
              avatarSrc={avatar}
              pilotCategoriesList={pilotCategoriesList}
              values={values}
              setEditing={setEditing}
              showButtonEdit={!paramsId}
              pilotModule={pilotModule}
            />
          </PilotDetailsLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

export { PilotDetails }

import { CONFIG } from 'config'
import { styled, apply } from 'lib/styled'

function SubscriptionPlans() {
  const Title = styled.h2(apply('text-xl font-semibold m-6 text-center'))
  const Container = styled.div(apply('bg-white pb-6 rounded-lg shadow-sm'))

  const PricingTableContainer = styled.div`
    transition: transform 0.3s ease;
    align-items: center;
    /* Default scaling (for large screens) */
    @media (min-width: 1600px) {
      transform: scale(1);
    }

    /* Medium screens */
    @media (max-width: 1599px) and (min-width: 1440px) {
      width: 1299px;
      transform: scale(0.8);
      transform-origin: 25% 100px;
    }

    /* Medium screens */
    @media (max-width: 1439px) and (min-width: 1300px) {
      width: 1280px;
      transform: scale(0.6);
      transform-origin: 25% 100px;
    }

    /* Medium screens */
    @media (max-width: 1299px) and (min-width: 1100px) {
      width: 1280px;
      transform: scale(0.4);
      transform-origin: 20% 100px;
    }

    /* Small screens */
    @media (max-width: 1099px) {
      width: 100%;
    }
  `

  return (
    <Container>
      <Title>Available Subscription Plans</Title>
      <PricingTableContainer>
        <stripe-pricing-table
          pricing-table-id={CONFIG.STRIPE.PRICING_TABLE_ID}
          publishable-key={CONFIG.STRIPE.PUBLISHABLE_KEY}
        />
      </PricingTableContainer>
    </Container>
  )
}

export { SubscriptionPlans }

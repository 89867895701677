import { Link } from 'react-router-dom'

import CloudLayoutImage from 'assets/clouds_container.svg'
import { ReactComponent as UserCircle } from 'assets/user_circle.svg'
import { Button } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Layout = styled.div(
  apply('flex flex-1 flex-column bg-white rounded-xl shadow-sm relative'),
  {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundImage: `url(${CloudLayoutImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    '& > p:not(:last-of-type)': {
      marginBottom: 0,
    },
  }
)

const Title = styled.h1({
  fontWeight: '800',
  fontSize: '1.625rem',
  marginBottom: '24px',
})

const LinkStyled = styled(Link)({
  fontWeight: 'bold',
  color: '#00326F',
})

const CustomerEmptyState = ({ isEmpty }) => (
  <Layout>
    <UserCircle />
    <Title>{isEmpty ? 'No Customer Found' : 'Your Customers'}</Title>
    <p>
      {isEmpty
        ? 'Start by adding your customer.'
        : 'Please navigate to your customer account'}
    </p>
    <p>
      {isEmpty ? (
        <>
          Click <LinkStyled to="/customer/create">Add Customer</LinkStyled> to invite
          your new customer.
        </>
      ) : (
        <>
          or click <LinkStyled to="/customer/create">Add Customer</LinkStyled> to invite
          your new customer.
        </>
      )}
    </p>
    <Link to="/customer/create">
      <Button>Add Customer</Button>
    </Link>
  </Layout>
)

export { CustomerEmptyState }

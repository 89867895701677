import { pathOr, prop, path, map, isNilOrEmpty } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

function documentSelectors(documentModule) {
  const { state$, effectState$ } = documentModule

  const folderEntities$ = select(state$, prop('folderEntities'))
  const folderOrders$ = select(state$, prop('folderOrders'))

  const inspectedFolderEntity$ = select(state$, prop('inspectedFolder'))

  const inspectedFolder$ = select(
    inspectedFolderEntity$,
    folderEntities$,
    (id, entities) => prop(id, entities)
  )

  const fileEntities$ = select(state$, prop('fileEntities'))
  const inspectedFile$ = select(state$, prop('inspectedFile'))

  const filterQuery$ = select(state$, prop('filterQuery'))

  const sortBy$ = select(state$, prop('sortBy'))

  const paging$ = select(state$, prop('paging'))

  const limit$ = select(state$, prop('limit'))

  const jobDocumentList$ = select(
    inspectedFolderEntity$,
    folderEntities$,
    fileEntities$,
    (inspectedFolder, folderEntities, fileEntities) => {
      if (isNilOrEmpty(inspectedFolder)) return []
      const fileOrder = pathOr([], [inspectedFolder, 'files'], folderEntities)
      const files = map((id) => path([id], fileEntities), fileOrder)
      return files
    }
  )

  const organizationDocumentList$ = select(fileEntities$, Object.values)

  const jobFolderList$ = select(folderOrders$, folderEntities$, (order, entities) => {
    const traverse = (item) => {
      if (!item.children.length) return entities[item.id]
      return { ...item, children: item.children.map(traverse) }
    }
    const mappedFolder = order.map(traverse)
    return mappedFolder
  })

  const fetchJobDocumentsStatus$ = select(effectState$, (effectState) =>
    pathOr('pending', ['fetchJobDocuments', 'status'], effectState)
  )

  const downloadJobDocumentStatus$ = select(effectState$, (effectState) =>
    pathOr('fulfilled', ['downloadJobDocument', 'status'], effectState)
  )
  const uploadJobDocumentStatus$ = select(effectState$, (effectState) =>
    pathOr('fulfilled', ['uploadJobDocument', 'status'], effectState)
  )
  const deleteJobDocumentStatus$ = select(effectState$, (effectState) =>
    pathOr('fulfilled', ['deleteJobDocument', 'status'], effectState)
  )
  const renameJobDocumentStatus$ = select(effectState$, (effectState) =>
    pathOr('fulfilled', ['renameJobDocument', 'status'], effectState)
  )

  const isLoadingJobDocuments$ = select(
    fetchJobDocumentsStatus$,
    (status) => status === 'pending'
  )

  const isDownloadingJobDocument$ = select(
    downloadJobDocumentStatus$,
    (status) => status === 'pending'
  )
  const isUploadingJobDocument$ = select(
    uploadJobDocumentStatus$,
    (status) => status === 'pending'
  )
  const isDeletingJobDocument$ = select(
    deleteJobDocumentStatus$,
    (status) => status === 'pending'
  )
  const isRenamingJobDocument$ = select(
    renameJobDocumentStatus$,
    (status) => status === 'pending'
  )
  const isOperatingFile$ = select(
    isDownloadingJobDocument$,
    isUploadingJobDocument$,
    isDeletingJobDocument$,
    isRenamingJobDocument$,
    (
      isDownloadingJobDocument,
      isUploadingJobDocument,
      isDeletingJobDocument,
      isRenamingJobDocument
    ) =>
      isDownloadingJobDocument ||
      isUploadingJobDocument ||
      isDeletingJobDocument ||
      isRenamingJobDocument
  )

  return {
    jobDocumentList$,
    organizationDocumentList$,
    sortBy$,
    paging$,
    limit$,
    filterQuery$,
    fileEntities$,
    inspectedFolder$,
    inspectedFolderEntity$,
    inspectedFile$,
    jobFolderList$,
    isLoadingJobDocuments$,
    isDownloadingJobDocument$,
    isUploadingJobDocument$,
    isDeletingJobDocument$,
    isRenamingJobDocument$,
    isOperatingFile$,
  }
}

export { documentSelectors }

import { Formik as FormProvider } from 'formik'

import { Details } from './Fields/Details'
import { Document } from './Fields/Document'
// import { Equipment } from './Fields/Equipment'
import { Insurance } from './Fields/Insurance'
import { License } from './Fields/License'
import { Links } from './Fields/Links'

import {
  CompanyLayout,
  FieldGroup,
  Row,
  FormLayoutProvider,
  FormAction,
} from 'components/company/common'

const AviationDetail = ({
  aviationDetail = {},
  initialValues,
  validationSchema,
  isSubmitting,
  isUploading,
  loading,
  editing,
  setEditing,
  handleSubmit,
  handleUploadDocuments,
  handleDownloadDocuments,
  status,
  organization,
  type,
  isAdmin,
  isInternalEntity = true,
}) => {
  const {
    country,
    companyArn,
    insuranceNumber,
    insuranceProvider,
    insuranceIssuedAt,
    insuranceExpiredAt,
  } = aviationDetail

  return (
    <FormLayoutProvider value={{ editing, setEditing }}>
      <FormProvider
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ resetForm, values, setFieldValue }) => (
          <CompanyLayout
            isInternalEntity={isInternalEntity}
            isAdmin={isAdmin}
            loading={loading || !values}
            type={type}
            status={status}
            company={!isAdmin ? organization.name ?? '' : ''}
            footer={
              editing && (
                <FormAction
                  loading={isSubmitting}
                  loadingText="Updating..."
                  okText="Save"
                  onCancel={() => {
                    setEditing(false)
                    resetForm()
                  }}
                />
              )
            }
          >
            <Details
              editing={editing}
              setEditing={setEditing}
              title="Aviation Details"
              values={{ country, companyArn }}
            />
            <License name="licenses" title="Licence" values={values.licenses} />
            <Insurance
              title="Insurance"
              values={{
                insuranceNumber,
                insuranceProvider,
                insuranceIssuedAt,
                insuranceExpiredAt,
              }}
            />
            {/* <Equipment
              name="equipments"
              title="Equipments"
              values={values.equipments}
            /> */}
            <FieldGroup title="Documents & Links">
              <Row>
                <Document
                  isUploading={isUploading}
                  handleUploadDocuments={handleUploadDocuments}
                  handleDownloadDocuments={handleDownloadDocuments}
                  values={values.documents}
                  setFieldValue={setFieldValue}
                />
                <Links values={values.links} />
              </Row>
            </FieldGroup>
          </CompanyLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

export { AviationDetail }

import { isNilOrEmpty } from '@seedcloud/ramda-extra'
import { useField, Field } from 'formik'

import { ErrorMessage } from 'components/common/ErrorMessage'
import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('flex flex-wrap'), {
  gap: '.5rem',
})
const Container = styled.label(({ selected }) =>
  apply('flex items-center jusitify-between text-md px-4 py-2 rounded-full', {
    cursor: 'pointer',
    transition: 'all 250ms ease-in-out',
    background: selected ? '#00326F' : 'none',
    color: selected ? '#ffffff' : '#00326F',
    fontWeight: selected ? 'bold' : 'normal',
    border: !selected ? '1px solid rgba(0, 0, 0, .2)' : '1px solid rgba(0, 0, 0, 0)',
  })
)
const Icon = styled.i(apply('mr-2 text-blue-400'))
const CheckBoxInput = styled(Field)(apply('', { opacity: '0' }))

const TextValue = styled.p({
  fontSize: '1.0625rem',
  color: '#000000',
  fontWeight: 400,
  lineHeight: '20.4px',
  marginBottom: '14px',
})

function ServiceCategories({ name, readOnly, ...props }) {
  const [fieldProps, { touched, error }] = useField({ name })
  const { value: fieldValue } = fieldProps

  if (isNilOrEmpty(fieldValue) && readOnly) return <TextValue>No Data</TextValue>

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Root>
        {SERVICE_CATEGORIES.map(({ value, readableValue, iconClass }) => (
          <Container
            hidden={readOnly && !fieldValue.includes(value)}
            key={value}
            selected={fieldValue.includes(value)}
            {...props}
          >
            <Icon className={`fas fa-${iconClass}`} />
            {readableValue}
            <CheckBoxInput
              disabled={readOnly}
              value={value}
              name={name}
              type="checkbox"
              id={readableValue}
            />
          </Container>
        ))}
      </Root>

      <ErrorMessage error={error} visible={touched} />
    </div>
  )
}

export { ServiceCategories }

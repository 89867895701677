import { map, prop, path, pathEq, pathOr } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { companyModule } from './module'

const { state$, effectState$ } = companyModule

const companyEntities$ = select(state$, prop('entities'))
const pilotEntities$ = select(state$, prop('pilotEntities'))
const nbsCompanyEntities$ = select(state$, prop('nbsEntities'))
const order$ = select(state$, prop('order'))
const pilotOrder$ = select(state$, prop('pilotOrder'))
const nbsOrder$ = select(state$, prop('nbsOrder'))
const inspectedEntity$ = select(state$, prop('inspectedEntity'))
const inspectedPilotEntity$ = select(state$, (state) =>
  prop('inspectedPilotEntity', state)
)
const filterQuery$ = select(state$, prop('filterQuery'))
const filterType$ = select(state$, prop('filterType'))
const canResendInviteAt$ = select(state$, prop('canResendInviteAt'))

const companyList$ = select(order$, companyEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const pilotList$ = select(pilotOrder$, pilotEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const nbsCompanyList$ = select(nbsOrder$, nbsCompanyEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const inspectedCompany$ = select(inspectedEntity$, companyEntities$, (id, entities) =>
  prop(id, entities)
)

const inspectedPilot$ = select(inspectedPilotEntity$, pilotEntities$, (id, entities) =>
  prop(id, entities)
)

const fetchCompaniesStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchCompanies', 'status'], effectState)
)

const fetchCompanyPilotsStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['fetchCompanyPilots', inspectedEntity, 'status'], effectState)
)

const fetchNBSCompaniesStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchNBSCompanies', 'status'], effectState)
)

const isLoadingCompany$ = select(
  fetchCompaniesStatus$,
  (status) => status === 'pending'
)

const isLoadingPilots$ = select(
  fetchCompanyPilotsStatus$,
  (status) => status === 'pending'
)

const isLoadingNBSCompany$ = select(
  fetchNBSCompaniesStatus$,
  (status) => status === 'pending'
)

const inspectCompanyStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectCompany', inspectedEntity, 'status'], effectState)
)

const isInspectingCompany$ = select(
  inspectCompanyStatus$,
  (status) => status === 'pending'
)

const isInvitingPilot$ = select(
  effectState$,
  pathEq(['inviteCompanyPilot', 'status'], 'pending')
)

const isUploadingImage$ = select(
  effectState$,
  pathEq(['uploadImage', 'status'], 'pending')
)

const isCreatingCompany$ = select(
  effectState$,
  pathEq(['createCompany', 'status'], 'pending')
)

const isResendingInvite$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    path(['resendInvite', inspectedEntity, 'status'], effectState) === 'pending'
)

export const companySelectors = {
  inspectedEntity$,
  companyList$,
  pilotList$,
  order$,
  nbsCompanyList$,
  isLoadingCompany$,
  isLoadingNBSCompany$,
  filterQuery$,
  filterType$,
  isCreatingCompany$,
  isLoadingPilots$,
  inspectedCompany$,
  isInvitingPilot$,
  inspectedPilot$,
  isInspectingCompany$,
  isUploadingImage$,
  canResendInviteAt$,
  isResendingInvite$,
}

import { ConnectedPilotListAdminContainer } from './PilotListAdminContainer'
import { ConnectedPilotListContainer } from './PilotListContainer'

import { useIdentity } from 'lib/solta-id-react'

const PilotListGateway = () => {
  const { isAdmin } = useIdentity()
  return isAdmin ? (
    <ConnectedPilotListAdminContainer />
  ) : (
    <ConnectedPilotListContainer />
  )
}

export { PilotListGateway as PilotList }

import { useFormikContext } from 'formik'

import { useJobs } from 'components/common/context/JobContext'
import { SelectField } from 'components/common/SelectField'
import { PUBLISH_TYPES } from 'constants/publishType'
import { styled, apply } from 'lib/styled'

const Row = styled.div(
  apply('flex flex-row my-2 items-start', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)

const JobPublishNetwork = ({
  pilotOptions,
  supplierListOptions,
  publishTypeOptions,
  isDisabled,
}) => {
  const { isNBS } = useJobs()
  const { setFieldValue, values, initialValues } = useFormikContext()

  const {
    jobInfo: { publishType },
  } = initialValues

  const {
    jobInfo: { publishTypeNetwork },
  } = values

  const publishTypeOptionsFiltered = publishTypeOptions.filter(
    ({ value }) => value !== PUBLISH_TYPES.NETWORK
  )

  const toggleSelectedAllField = (selectedType) => {
    if (selectedType === PUBLISH_TYPES.INTERNAL) {
      setFieldValue('jobInfo.selectedAllPilot', true)
      setFieldValue('jobInfo.selectedAllSupplier', false)
    } else {
      setFieldValue('jobInfo.selectedAllSupplier', true)
      setFieldValue('jobInfo.selectedAllPilot', false)
    }
    setFieldValue('jobInfo.selectedSupplier', { id: undefined, companyName: undefined })
    setFieldValue('jobInfo.selectedPilot', { id: undefined, name: undefined })
  }

  const unsetSelectedAllField = () => {
    setFieldValue('jobInfo.selectedAllPilot', false)
    setFieldValue('jobInfo.selectedAllSupplier', false)
  }

  if (publishType !== PUBLISH_TYPES.NETWORK || !isNBS) return null

  return (
    <Row>
      <SelectField
        name="jobInfo.publishTypeNetwork"
        label=" Publish Network Job To"
        options={publishTypeOptionsFiltered}
        emptyOption={{
          value: publishTypeNetwork,
          id: publishTypeNetwork,
        }}
        onChange={({ value }) => {
          setFieldValue('jobInfo.publishTypeNetwork', value)
          toggleSelectedAllField(value)
        }}
        placeholder="Select publish type"
        containerProps={{ style: apply('mr-3') }}
        isDisabled={isDisabled}
        testId="job-company"
      />

      {publishTypeNetwork === PUBLISH_TYPES.INTERNAL ? (
        <SelectField
          name="jobInfo.selectedPilot.name"
          label="Recommend Pilot"
          emptyOption={{
            id: undefined,
            value: undefined,
          }}
          onChange={({ id, value: name }) => {
            if (!id) {
              toggleSelectedAllField(PUBLISH_TYPES.INTERNAL)
              setFieldValue('jobInfo.selectedPilot', { id: undefined, name: undefined })
            } else {
              setFieldValue('jobInfo.selectedPilot', { id, name })
              setFieldValue('jobInfo.selectedSupplier', null)
              unsetSelectedAllField()
            }
          }}
          options={pilotOptions}
          placeholder="Select Pilot"
          isDisabled={isDisabled}
        />
      ) : (
        <SelectField
          name="jobInfo.selectedSupplier.companyName"
          label="Suppliers"
          emptyOption={{
            id: undefined,
            value: undefined,
          }}
          onChange={({ id, value: companyName }) => {
            if (!id) {
              toggleSelectedAllField(PUBLISH_TYPES.SUPPLIER)
              setFieldValue('jobInfo.selectedSupplier', {
                id: undefined,
                companyName: undefined,
              })
            } else {
              setFieldValue('jobInfo.selectedSupplier', { id, companyName })
              setFieldValue('jobInfo.selectedPilot', null)
              unsetSelectedAllField()
            }
          }}
          options={supplierListOptions}
          placeholder="Select Supplier"
          isDisabled={isDisabled}
        />
      )}
    </Row>
  )
}

export { JobPublishNetwork }

import { ConnectedJobDetailsContainer as ConnectedJobDetailsAdminContainer } from './JobDetailsAdminContainer'
import { ConnectedJobDetailsContainer } from './JobDetailsContainer'

import { useIdentity } from 'lib/solta-id-react'

const JobDetailsGateway = () => {
  const { isAdmin } = useIdentity()
  return isAdmin ? (
    <ConnectedJobDetailsAdminContainer />
  ) : (
    <ConnectedJobDetailsContainer />
  )
}

export { JobDetailsGateway as JobDetails }

import SidebarClose from 'assets/sidebar_close.svg'
import SidebarOpen from 'assets/sidebar_open.svg'
import { styled, apply } from 'lib/styled'

const HitSlop = styled.div(
  apply('p-3', {
    cursor: 'pointer',
  })
)

const SidebarBtn = styled.img(apply('block'), {
  width: '24px',
  height: '24px',
  cursor: 'pointer',
})

function Hamburger({ onClick, isOpen }) {
  const svgUrl = isOpen ? SidebarOpen : SidebarClose
  return (
    <HitSlop onClick={onClick}>
      <SidebarBtn src={svgUrl} />
    </HitSlop>
  )
}

export { Hamburger }

import { useFormikContext } from 'formik'
import { useState } from 'react'

import { FormInput, useFilter } from './FilterBar'

import { useDebounce, useUpdateEffect } from 'utils/hooks'

const Search = ({ placeholder = "Search by Client's / Pilot's name or Reference" }) => {
  const { setFieldValue } = useFormikContext()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const { onSubmit } = useFilter()

  useUpdateEffect(() => {
    onSubmit({ filterQuery: debouncedSearch })
  }, [debouncedSearch])

  const handleChange = (e) => {
    setSearch(e.target.value)
    setFieldValue('filterQuery', e.target.value)
  }

  return (
    <FormInput
      name="filterQuery"
      type="text"
      onChange={handleChange}
      placeholder={placeholder}
      testId="job-filter-query-input"
      containerProps={{ style: { flexGrow: 1.5 } }}
      size="lg"
      newStyle
    />
  )
}

export { Search }

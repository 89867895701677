import { Switch, Route } from 'react-router-dom'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { CreateSupplier } from 'components/supplier/CreateSupplier'
import { SupplierAviation } from 'components/supplier/SupplierAviation'
import { SupplierCategories } from 'components/supplier/SupplierCategories'
import { SupplierEquipment } from 'components/supplier/SupplierCategories/SupplierEquipment'
import { SupplierDetail } from 'components/supplier/SupplierDetail'
import { SupplierEmptyState } from 'components/supplier/SupplierEmptyState'
import { SupplierList } from 'components/supplier/SupplierList'
import { SupplierPilotList } from 'components/supplier/SupplierPilotList'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

const SupplierListWrapper = ({ component }) => (
  <>
    <SupplierList />
    {component}
  </>
)

function SupplierManager() {
  return (
    <Container>
      <Switch>
        <Route
          exact
          path={['/', '/suppliers']}
          render={() => <SupplierListWrapper component={<SupplierEmptyState />} />}
        />
        <Route
          exact
          path="/supplier/new"
          render={() => <SupplierListWrapper component={<CreateSupplier />} />}
        />
        <Route
          exact
          path={['/supplier/:id', '/supplier/:id/details']}
          render={() => <SupplierListWrapper component={<SupplierDetail />} />}
        />
        <Route
          exact
          path={['/supplier/:id/ratecard', '/supplier/:id/ratecard/categories']}
          render={() => <SupplierListWrapper component={<SupplierCategories />} />}
        />
        <Route
          exact
          path={['/supplier/:id/ratecard/equipment']}
          render={() => <SupplierListWrapper component={<SupplierEquipment />} />}
        />
        <Route
          exact
          path={['/supplier/:id/aviation']}
          render={() => <SupplierListWrapper component={<SupplierAviation />} />}
        />
        <Route
          exact
          path={['/supplier/:id/pilots']}
          render={() => <SupplierListWrapper component={<SupplierPilotList />} />}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { SupplierManager }

import { ENTITY_TYPE } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'

export const useEntityType = () => {
  const { entityType } = useIdentity()
  return {
    entityType,
    isInternalEntity: entityType === ENTITY_TYPE.INTERNAL,
  }
}

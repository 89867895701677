import Modal from 'react-modal'

import { ReactComponent as Instruction1 } from 'assets/images/upload_instruction_1.svg'
import { ReactComponent as Instruction2 } from 'assets/images/upload_instruction_2.svg'
import { ReactComponent as Instruction3 } from 'assets/images/upload_instruction_3.svg'
import { Button as BaseButton } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('text-black bg-white rounded-lg'), {
  padding: '40px 40px',
  maxWidth: '700px',
  alignItems: 'center',
})

const Button = styled(BaseButton)(apply('rounded-lg'), {
  height: 'auto',
  fontWeight: '500',
  padding: '1rem 82px',
})

const Title = styled.h1(apply('mb-6'), {
  fontWeight: 700,
  fontSize: '26px',
  textAlign: 'center',
  textWrap: 'balance',
})

const Space = styled.div(apply('flex mt-4 justify-between'), {
  gap: '.5rem',
  '& section': {
    maxWidth: '174px',
    textAlign: 'center',
    fontSize: '13px',
    textWrap: 'balance',
  },
})

const SeparatorText = styled.span(
  apply('font-bold', {
    marginLeft: '-2rem',
    marginRight: '-2rem',
    marginTop: 'calc(172px / 2)',
  })
)

Modal.setAppElement('#root')

function UploadInstructionModal({ closeModal, isOpen }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      id="folderNamingModal"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(20, 20, 20, 0.2)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <Title>
          To maintain your folder structure while uploading, follow these steps:
        </Title>
        <Space>
          <section>
            <Instruction1 />
            <p>Compress your files into a .zip on your device</p>
          </section>
          <section>
            <Instruction2 />
            <p>
              Use tools like WinZip to split large files into partitions like .zip.001
            </p>
          </section>
          <SeparatorText>OR</SeparatorText>
          <section>
            <Instruction3 />
            <p>Create a folder and arrange your files in the right folders</p>
          </section>
        </Space>
        <Space style={apply('justify-center')}>
          <Button type="button" onClick={() => closeModal()}>
            Got It
          </Button>
        </Space>
      </Root>
    </Modal>
  )
}

export { UploadInstructionModal }

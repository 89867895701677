import Modal from 'react-modal'

import { Button, CancelButton as CancelButtonBase } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('text-black bg-white rounded-lg'), {
  padding: '48px 64px',
  width: '656px',
  maxWidth: '656px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '32px',
})

const Title = styled.h1({
  fontWeight: 700,
  fontSize: '2.25rem',
  textAlign: 'left',
})

const Subtitle = styled.h2({
  fontWeight: 700,
  margin: 0,
  fontSize: '.875rem',
  textAlign: 'left',
})

const Content = styled.div({
  margin: 0,
  fontSize: '.875rem',
  textAlign: 'left',
  height: '21rem',
  overflow: 'scroll',
})

const Space = styled.div(apply('flex'), {
  gap: '1rem',
})

const ActionButton = styled(Button)(apply('flex-1 mt-5'), {
  marginTop: '1rem',
})

const CancelButton = styled(CancelButtonBase)(apply('flex-1 mt-5'), {
  background: 'transparent',
  boxShadow: 'none',
  marginTop: '1rem',
})

Modal.setAppElement('#root')

function TermsModal({
  isOpen,
  closeModal,
  onConfirm,
  loading,
  confirmText = 'Agree',
  confirmLoadingText = 'Confriming...',
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      id="unique"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(20, 20, 20, 0.2)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <Title>Terms & Condition for GHD Pilot</Title>
        <Content>
          <Subtitle>Introductions</Subtitle>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
          nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
          eu eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
          sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum sit
          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
          in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
          officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
          in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
          officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, amet,
          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
          irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
          nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
          culpa qui officia deserunt mollit anim id est laborum.
        </Content>
        <Space>
          <CancelButton disabled={loading} onClick={() => closeModal()}>
            Cancel
          </CancelButton>
          <ActionButton disabled={loading} onClick={() => onConfirm()}>
            {loading ? confirmLoadingText : confirmText}
          </ActionButton>
        </Space>
      </Root>
    </Modal>
  )
}

export { TermsModal }

import { connect } from '@seedcloud/stateless'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { InvitePilot } from './InvitePilot'

import { createCompanyModule } from 'modules/company'
import { createCompanyModule as createLegacyCompanyModule } from 'modules/legacy-company'
import { createPilotModule } from 'modules/pilot'
import { createPilotCategoriesModule } from 'modules/pilotCategory'

const { module: companyModule } = createLegacyCompanyModule()
const { module: pilotModule } = createPilotModule()
const {
  module: newCompanyModule,
  selectors: { companyDetail$, isInspectingCompany$ },
} = createCompanyModule()

const {
  module: pilotCategoryModule,
  selectors: { pilotCategoriesList$ },
} = createPilotCategoriesModule()

const { inviteCompanyPilot } = companyModule

const ConnectedInvitePilotAdmin = connect(() => ({
  pilotCategoriesList: pilotCategoriesList$,
  isInspectingCompany: isInspectingCompany$,
  companyDetail: companyDetail$,
}))(InvitePilotAdminContainer)

function InvitePilotAdminContainer({ pilotCategoriesList, companyDetail }) {
  const { companyId } = useParams()
  const [isInvitingPilot, setIsInvitingPilot] = useState(false)
  const history = useHistory()

  useEffect(() => {
    pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
    pilotCategoryModule.fetchPilotCategories(null, {
      turnPage: false,
    })
    newCompanyModule.inspectCompany()
    pilotModule.resetUploadAvatar()
    return () => {
      pilotCategoryModule.resetPagination()
    }
  }, [])

  const initialValues = {
    avatar: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    pilotCategoryId: '',
    companyName: companyDetail.name,
    terms: false,
  }

  const invitePilotProps = {
    pilotCategoriesList,
    initialValues,
    isInvitingPilot,
    setAvatarFile: ([file]) => {
      pilotModule.setAvatarFile(null, file)
    },
    handleSubmit: async (values) => {
      setIsInvitingPilot(true)
      const company = await inviteCompanyPilot(null, {
        companyId,
        userDetails: values,
      })
      if (company) {
        history.push(`/pilot/${company._id}`)
      }
      setIsInvitingPilot(false)
    },
  }

  return <InvitePilot {...invitePilotProps} />
}

export { ConnectedInvitePilotAdmin }

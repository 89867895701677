/* eslint-disable react/no-multi-comp */
import { equals, isNilOrEmpty } from '@seedcloud/ramda-extra'
import Crypto from 'crypto-js'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FileUploaderFileTypeValidator from 'filepond-plugin-file-validate-type'
import { memo, forwardRef } from 'react'
import { renderToString } from 'react-dom/server'
import { FilePond, registerPlugin } from 'react-filepond'

import { validateFileType } from '../utils/validateFileType'

import { ReactComponent as UploadCloud } from 'assets/upload_cloud.svg'
import { useJobs } from 'components/common/context/JobContext'
import { CONFIG } from 'config'
import { splitToChunks } from 'lib/multipart/upload'
import { apply, styled } from 'lib/styled'
import { UploadService } from 'modules/upload'
import 'filepond/dist/filepond.min.css'

registerPlugin(FileUploaderFileTypeValidator)
registerPlugin(FilePondPluginFileValidateSize)

// apparently the plugin config doesn't support number & GB unit
const S3_PUT_OBJECT_SIZE_LIMIT_IN_BYTES = '5000MB'

const computeBearerToken = (referenceNo, uploadCode = '') =>
  Crypto.HmacSHA256(referenceNo, uploadCode)

function calculateProgress(progress) {
  return progress.reduce((a, b) => a + b, 0)
}

const FileUploader = styled(FilePond)({
  '&.filepond--root': {
    minHeight: '8em',
    borderRadius: '0.5rem',
    border: '1.5px solid #ccc',
  },
  '.filepond--drop-label': {
    minHeight: '8em',
  },
})

const view = renderToString(
  <div>
    <UploadCloud width={46} height={46} />
    <p style={apply('font-bold text-lg mb-1')}>
      Drag and Drop, or{' '}
      <span
        style={{ textDecoration: 'underline', color: '#81D1EF', cursor: 'pointer' }}
      >
        browse
      </span>{' '}
      your files
    </p>
    <span>Maximum size per file is 5 Gigabyte.</span>
  </div>
)

// TODO: should add e2e test to cover file upload logics since react-testing-lib doesn't support testing useRef yet
const UploadPanel = memo(
  forwardRef(({ jobId, reference, uploadCode, path }, ref) => {
    const { onConfirmJobDocument, onDeleteJobDocument } = useJobs()

    const defaultHeaders = {
      authorization: `Bearer ${computeBearerToken(reference, uploadCode)}`,
    }

    function uploadMultipartFile(
      _fieldName,
      file,
      _metadata,
      onUploaded,
      onError,
      onProgress,
      onAbort
    ) {
      const validate = validateFileType()
      if (!validate(file)) {
        onError('Invalid File Type')
        return {
          abort: () => {
            onAbort()
          },
        }
      }

      const { type: contentType } = file
      const { chunks, partsCount } = splitToChunks(file)
      const requests = []

      UploadService.createMultipartSignedUrl(
        { fileName: file.name, fileSize: file.size, partsCount },
        reference,
        defaultHeaders
      ).then(async (response) => {
        const { signedUrls, handleAbort, handleComplete } = response

        if (isNilOrEmpty(signedUrls)) {
          onError('File failed to upload. Try again in a little bit.')
        }

        const signedUrlList = Object.entries(signedUrls)
        const progress = new Array(signedUrlList.length)

        const promises = signedUrlList.map(
          ([partNo, signedUrl], i) =>
            new Promise((resolve, reject) => {
              // We use XMLHttpRequest to track progress
              const req = new XMLHttpRequest()
              req.open('put', signedUrl, true)
              req.setRequestHeader('content-type', contentType)

              req.upload.onprogress = (e) => {
                progress[i] = e.loaded
                onProgress(e.lengthComputable, calculateProgress(progress), file.size)
              }

              req.onload = () => {
                if (req.status >= 200 && req.status < 300) {
                  const etag = req.getResponseHeader('etag')
                  resolve({ etag, partNo })
                } else {
                  reject(new Error('Failed to upload'))
                }
              }

              req.send(chunks[partNo - 1])
              requests.push(req)
            })
        )

        try {
          const responses = await Promise.all(promises)
          const folderPath = jobId === path ? undefined : path
          const { document } = await handleComplete(responses, folderPath)
          const { id: documentId, fileSize } = document

          onConfirmJobDocument(jobId, {
            req: { status: 200 },
            reference,
            defaultHeaders,
            documentId,
            fileSize,
            onUploaded,
            onError,
            inspectedFolder: path,
          })
        } catch (error) {
          await handleAbort()
          onError(error.message)
        }
      })

      return {
        abort: () => {
          requests.forEach((req) => req.abort())
          onAbort()
        },
      }
    }

    function deleteFile(id) {
      return onDeleteJobDocument(id)
    }

    return (
      <>
        <FileUploader
          id="file-uploader-input"
          ref={ref}
          styles={apply('w-full')}
          allowMultiple
          maxFileSize={S3_PUT_OBJECT_SIZE_LIMIT_IN_BYTES}
          server={{
            url: `${CONFIG.API.URL}/upload-jobs/${reference}/documents`,
            headers: defaultHeaders,
            process: uploadMultipartFile,
            revert: deleteFile,
            remove: deleteFile,
          }}
          labelIdle={view}
        />
      </>
    )
  }),
  equals
)

export { UploadPanel }

import {
  prop,
  pathOr,
  map,
  path,
  mergeDeepRight,
  pick,
  isNotNilOrEmpty,
  ifElse,
  isNilOrEmpty,
  always,
  propOr,
  pipe,
} from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { supplierModule } from './module'

const { state$, effectState$ } = supplierModule

const supplierEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))
const inspectedEntity$ = select(state$, prop('inspectedEntity'))
const filterQuery$ = select(state$, (state) => state.filterQuery)
const filterType$ = select(state$, prop('filterType'))

const supplierList$ = select(order$, supplierEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const fetchSuppliersStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchSuppliers', 'status'], effectState)
)

const publishSupplierStatus$ = select(effectState$, (effectState) =>
  pathOr('idle', ['publishSupplier', 'status'], effectState)
)

const isFetchingSuppliers$ = select(
  fetchSuppliersStatus$,
  (fetchApplicationsStatus) => fetchApplicationsStatus === 'pending'
)

const isPublishing$ = select(publishSupplierStatus$, (status) => status === 'pending')

const inspectedSupplier$ = select(inspectedEntity$, supplierEntities$, (id, entities) =>
  prop(id, entities)
)

const inspectSupplierStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectSupplier', inspectedEntity, 'status'], effectState)
)

const updateSupplierCurrencyStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr(
      'fulfilled',
      ['updateSupplierCurrency', inspectedEntity, 'status'],
      effectState
    )
)

const isInspectingSupplier$ = select(
  inspectSupplierStatus$,
  (status) => status === 'pending'
)
const currency$ = select(inspectedSupplier$, prop('currency'))
const isUpdatingCurrency$ = select(
  updateSupplierCurrencyStatus$,
  (status) => status === 'pending'
)

const supplierDetail$ = select(inspectedSupplier$, (entities) => {
  let detail = {}

  if (isNotNilOrEmpty(entities)) {
    const picked = pick(['firstName', 'lastName', 'email', 'phoneNumber'])
    const commercial = picked(propOr({}, 'commercialUserId', entities))
    const billing = picked(propOr({}, 'billingUserId', entities))
    const heroPilot = picked(propOr({}, 'heroPilotUserId', entities))

    const companyProps = pipe(
      path(['company']),
      pick(['phoneNumber', 'invitationStatus', 'companyName'])
    )(entities)

    detail = pipe(
      pick(['abn', 'website', 'address']),
      mergeDeepRight({ commercial, billing, heroPilot }),
      mergeDeepRight(companyProps)
    )(entities)
  }

  return detail
})

const aviationDetail$ = select(inspectedSupplier$, (entities) =>
  ifElse(
    isNilOrEmpty,
    always({}),
    pick([
      'country',
      'companyArn',
      'documents',
      'links',
      'licenses',
      'insuranceNumber',
      'insuranceProvider',
      'insuranceIssuedAt',
      'insuranceExpiredAt',
      'equipments',
      'invitationStatus',
    ])
  )(entities)
)

const supplierType$ = select(inspectedSupplier$, (entities) =>
  pathOr('', ['company', 'supplierType'], entities)
)

const invitationStatus$ = select(inspectedSupplier$, (entities) =>
  pathOr('', ['company', 'invitationStatus'], entities)
)

const companyName$ = select(inspectedSupplier$, (entities) =>
  pathOr('', ['company', 'companyName'], entities)
)

const submitSupplier$ = select(effectState$, (effectState) =>
  pathOr('idle', ['submitSupplier', 'status'], effectState)
)

const isSubmittingSupplier$ = select(submitSupplier$, (status) => status === 'pending')

export {
  supplierEntities$,
  inspectedEntity$,
  isInspectingSupplier$,
  inspectedSupplier$,
  isUpdatingCurrency$,
  currency$,
  filterQuery$,
  filterType$,
  supplierList$,
  isFetchingSuppliers$,
  supplierDetail$,
  aviationDetail$,
  supplierType$,
  invitationStatus$,
  isPublishing$,
  isSubmittingSupplier$,
  companyName$,
}

import { ReactComponent as UserCircle } from 'assets/user_circle.svg'
import { FilterInput } from 'components/supplier/common/FilterInput'
import { SupplierLayout } from 'components/supplier/common/SupplierLayout'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex flex-1 flex-column rounded-xl items-center justify-center'),
  {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  }
)

const Title = styled.h1({
  fontWeight: '800',
  fontSize: '1.625rem',
  marginBottom: '24px',
})

const SupplierPilotList = () => (
  <SupplierLayout>
    <FilterInput />
    <Container>
      <UserCircle />
      <Title>No Pilots Yet</Title>
    </Container>
  </SupplierLayout>
)

export { SupplierPilotList }

import { connect } from '@seedcloud/stateless'
import { useState, useLayoutEffect } from 'react'
import * as Validator from 'yup'

import { AviationDetail } from './AviationDetail'

import { createCompanyModule } from 'modules/company'
import { transformArrayFields } from 'utils/transformArrayFields'
import { transformDateFields } from 'utils/transormDateFields'
import { dateNowMinimum, optionalString, requiredString } from 'utils/validation'

const {
  module: companyModule,
  selectors: { aviationDetail$, isInspectingCompany$, isSubmitting$, isUploading$ },
} = createCompanyModule()

const { object, array, date } = Validator

const validationSchema = object({
  country: requiredString('Company Country'),
  companyArn: requiredString('Company ARN').matches(
    /^\d+$/,
    'ARN should only contain number'
  ),
  licenses: array().of(
    object().shape({
      licenseType: requiredString('License type'),
      licenseNumber: requiredString('License Number'),
      licenseIssuedAt: date().label('Licence issue').optional().nullable(),
      licenseExpiredAt: dateNowMinimum().label('Licence expiry').optional().nullable(),
    })
  ),
  insuranceProvider: requiredString('Insurance Provider'),
  insuranceNumber: requiredString('Insurance Number'),
  insuranceIssuedAt: date().label('Insurance issue date').optional().nullable(),
  insuranceExpiredAt: dateNowMinimum()
    .label('Insurance expiry date')
    .optional()
    .nullable(),
  equipments: array()
    .of(
      object().shape({
        category: optionalString('Equipment Category'),
        make: optionalString('Equipment make'),
        model: optionalString('Equipment model'),
      })
    )
    .optional(),
  links: array()
    .of(
      object().shape({
        name: optionalString('Links name'),
        url: optionalString('Links url'),
      })
    )
    .optional(),
  documents: array()
    .of(
      object().shape({
        name: requiredString('Documents name'),
        id: optionalString('Documents id'),
        url: optionalString('Documents url'),
        type: optionalString('Documents types'),
      })
    )
    .optional(),
})

const AviationDetailAdmin = ({
  aviationDetail = {},
  isInspectingCompany,
  isSubmitting,
  isUploading,
}) => {
  const [editing, setEditing] = useState(false)

  useLayoutEffect(() => {
    companyModule.inspectCompany()
  }, [])

  const {
    country,
    companyArn,
    documents,
    links,
    licenses,
    insuranceNumber,
    insuranceProvider,
    insuranceIssuedAt,
    insuranceExpiredAt,
    equipments,
  } = aviationDetail

  const initialValues = {
    country,
    companyArn,
    licenses,
    insuranceNumber,
    insuranceProvider,
    insuranceIssuedAt,
    insuranceExpiredAt,
    equipments,
    documents,
    links,
  }

  const handleSubmit = async (values, { resetForm }) => {
    let payload = transformArrayFields(values)
    payload = transformDateFields(
      [
        'insuranceIssuedAt',
        'insuranceExpiredAt',
        'licenseIssuedAt',
        'licenseExpiredAt',
      ],
      payload
    )
    await companyModule.updateCompany(null, payload)
    setEditing(false)
    resetForm()
  }

  const handleUploadDocuments = companyModule.uploadAviationDocuments
  const handleDownloadDocuments = companyModule.downloadAviationDocuments

  const aviationDetailProps = {
    aviationDetail,
    isSubmitting,
    isUploading,
    initialValues,
    validationSchema,
    loading: isInspectingCompany,
    editing,
    setEditing,
    handleSubmit,
    handleUploadDocuments,
    handleDownloadDocuments,
    isAdmin: true,
  }

  return <AviationDetail {...aviationDetailProps} />
}

export const ConnectedAviationDetailAdmin = connect(() => ({
  aviationDetail: aviationDetail$,
  isInspectingCompany: isInspectingCompany$,
  isSubmitting: isSubmitting$,
  isUploading: isUploading$,
}))(AviationDetailAdmin)

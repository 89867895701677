const NearbyskyLogoIcon = ({ width = '122', height = 'auto' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 122 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.3068 27.1605C40.1782 17.98 49.8779 11.7031 61.0864 11.7031C68.5875 11.7031 75.3988 14.5213 80.572 19.1329C84.3225 17.3822 88.5042 16.4001 92.9445 16.4001C93.2894 16.4001 93.6773 16.4001 94.0222 16.4428C86.478 6.3229 74.278 -0.167509 60.5691 0.00329104C43.8856 0.216791 29.487 10.4221 23.3223 24.8974C23.7965 24.8547 24.2707 24.8547 24.7018 24.8547C28.4954 24.7693 32.0735 25.6233 35.3068 27.1605Z"
      fill="#009DE4"
    />
    <path
      d="M61.0865 69.3478C54.8787 69.3478 49.102 67.4263 44.3599 64.0957C43.7133 64.9497 42.9804 65.761 42.2476 66.5296L42.1613 66.615L35.8242 72.8919L61.0865 97.9995L83.0725 76.1798L73.459 66.6577C69.6654 68.3657 65.4837 69.3478 61.0865 69.3478Z"
      fill="#009DE4"
    />
    <path
      d="M32.0305 69.1345C31.039 68.1524 30.1337 67.1275 29.2284 66.06C27.7627 66.4443 26.2538 66.6578 24.6588 66.6578C15.9506 66.6578 8.70816 60.3382 7.32865 52.0971C7.15621 51.1577 7.1131 50.2183 7.1131 49.2789C7.1131 40.9524 13.0191 33.9923 20.9082 32.2843C21.4686 29.7223 22.2446 27.203 23.2361 24.8545C10.3032 25.5377 0 36.2127 0 49.2789C0 52.3106 0.560427 55.2142 1.55195 57.8616C2.80213 61.1495 4.74207 64.0958 7.19932 66.5297L7.28554 66.6151L24.7019 83.866L35.7811 72.8921L32.1598 69.2626L32.0305 69.1345Z"
      fill="#3EB2E3"
    />
    <path
      d="M24.7019 31.8597C27.5903 31.8597 30.3493 32.5429 32.7634 33.7812C33.3239 31.4327 34.1861 29.2123 35.3069 27.12C32.1168 25.6255 28.4956 24.7715 24.745 24.7715C24.2708 24.7715 23.7966 24.7715 23.3655 24.8142C22.3309 27.2054 21.5549 29.682 21.0376 32.244C22.1585 32.0305 23.4086 31.8597 24.7019 31.8597Z"
      fill="#017BBF"
    />
    <path
      d="M42.2041 66.5298C42.98 65.7612 43.6698 64.9499 44.3164 64.0959C42.3334 62.7295 40.5659 61.1069 39.0139 59.3135C36.686 62.5587 33.2372 64.9926 29.228 66.0601C30.0902 67.1276 31.0386 68.1524 32.0302 69.1345L32.1595 69.2626L35.7807 72.8921L42.1178 66.6152L42.2041 66.5298Z"
      fill="#017BBF"
    />
    <path
      d="M93.9787 16.4014C96.0911 19.2196 97.8586 22.3367 99.195 25.6673C107.515 28.3147 113.594 36.0434 113.594 45.1812C113.594 46.2914 113.507 47.4016 113.335 48.5118C111.74 58.162 103.377 65.5064 93.2458 65.6345C92.2543 66.8728 91.2197 68.0257 90.1419 69.1359L90.0126 69.264L83.0288 76.1387L92.9009 85.9171L113.421 65.6345L113.507 65.5491C116.396 62.6882 118.681 59.1868 120.146 55.3438C121.353 52.184 122 48.8107 122 45.2239C122 29.6384 109.541 16.9565 93.9787 16.4014Z"
      fill="#64BCE4"
    />
    <path
      d="M90.0995 69.1359C91.2203 68.0257 92.255 66.8728 93.2034 65.6345C93.1172 65.6345 92.9878 65.6345 92.9016 65.6345C88.3751 65.6345 84.1503 64.1827 80.7447 61.7061C78.5892 63.6703 76.0888 65.3356 73.416 66.5739L83.0295 76.096L89.9702 69.2213L90.0995 69.1359ZM78.3736 65.5064L78.4599 65.5918L78.3736 65.5064Z"
      fill="#017BBF"
    />
    <path
      d="M80.5293 19.0902C82.7279 21.0544 84.5816 23.3175 86.0905 25.8368C88.2028 25.1109 90.4876 24.6839 92.9018 24.6839C95.1004 24.6839 97.2128 25.0255 99.1958 25.666C97.8594 22.3354 96.135 19.2183 93.9795 16.4001C93.6347 16.4001 93.2467 16.3574 92.9018 16.3574C88.5046 16.3574 84.2798 17.3395 80.5293 19.0902Z"
      fill="#017BBF"
    />
    <path
      d="M42.2476 49.2787C42.2476 42.5748 38.4108 36.7249 32.7634 33.8213C32.2461 35.9563 31.9443 38.2194 31.9443 40.5252C31.9443 42.1051 32.0737 43.685 32.3323 45.1795C33.1945 50.517 35.5656 55.3848 39.0143 59.3132C40.5232 57.2209 41.5578 54.7443 42.032 52.0969C42.1613 51.1575 42.2476 50.2181 42.2476 49.2787Z"
      fill="white"
    />
    <path
      d="M99.4109 54.7448C97.9021 58.7159 95.7897 62.3881 93.2031 65.6333C103.334 65.5052 111.697 58.1181 113.292 48.5105C113.465 47.443 113.551 46.3328 113.551 45.1799C113.551 36.0421 107.516 28.3134 99.1523 25.666C100.963 30.2776 101.998 35.2735 101.998 40.4829C102.041 45.5215 101.092 50.3039 99.4109 54.7448Z"
      fill="white"
    />
    <path
      d="M86.1337 25.8375C88.7203 30.1502 90.186 35.1461 90.186 40.4836C90.186 42.0635 90.0567 43.6434 89.798 45.1379C88.7203 51.6283 85.4439 57.3928 80.7881 61.6628C84.1938 64.1394 88.4185 65.5912 92.945 65.5912C93.0312 65.5912 93.1606 65.5912 93.2468 65.5912C95.8334 62.346 97.9458 58.6738 99.4546 54.7027C101.136 50.2619 102.041 45.4795 102.041 40.4836C102.041 35.2315 101.05 30.2356 99.1959 25.6667C97.2129 25.0262 95.1005 24.6846 92.9019 24.6846C90.5309 24.7273 88.2461 25.1116 86.1337 25.8375Z"
      fill="white"
    />
  </svg>
)

export { NearbyskyLogoIcon }

import { replace } from '@seedcloud/ramda-extra'
import { useMemo } from 'react'

import { Table } from 'components/common/withTable'
import { SUPPLIER_TYPES } from 'constants/supplier'
import { createPilotCategoriesModule } from 'modules/pilotCategory'

const { module: pilotCategoryModule } = createPilotCategoriesModule()

const SupplierTable = ({ currency, values, next, limit, startRow, type, id }) => {
  const formatLabel = ({ description }) => `${replace(/_/g, ' ', description)}`

  const onTurnPage = ({ turnNext }) =>
    pilotCategoryModule.fetchPilotCategories(null, {
      turnPage: true,
      turnNext,
      type,
      supplierId: id,
    })

  const PILOT_CATEGORY_COLUMNS = useMemo(
    () => [
      {
        Header: 'No',
        Cell: (prop) => prop.row.index + 1 + (startRow || 0),
        disableSortBy: true,
      },
      {
        Header:
          type === SUPPLIER_TYPES.PILOT ? `Pilot Category` : 'Professional Category',
        accessor: 'description',
        Cell: ({ value }) => formatLabel({ description: String(value) }),
        disableSortBy: true,
      },
      { Header: `Rate  (${currency}/hr)`, accessor: 'rate', disableSortBy: true },
    ],
    [currency, values, type, startRow]
  )

  return (
    <Table
      columns={PILOT_CATEGORY_COLUMNS}
      data={values}
      next={next}
      limit={limit}
      turnPage={onTurnPage}
      startRow={startRow}
    />
  )
}

export { SupplierTable }

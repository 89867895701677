export const getFolderNumbers = (folders) => {
  let number = 0
  return () => {
    do {
      number += 1
      // eslint-disable-next-line no-loop-func
    } while (folders.find((folder) => folder.id === `Folder ${number}`))
    return number
  }
}

import ky from 'ky'

import { CONFIG } from 'config'
import { adminApi, baseApi } from 'lib/http-client'

const uploadApi = ky.create().extend({
  prefixUrl: `${CONFIG.API.URL}`,
})

// 4 Hours Timeout
const TIMEOUT_RESPONSE_IN_MS = 4 * 60 * 60 * 1000

const multipartApi = ky.create({
  timeout: TIMEOUT_RESPONSE_IN_MS,
})

const handleUploadToS3 = ({
  presignedUrl,
  file,
  filename,
  entity,
  onProgress,
  onError,
}) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()

    const performAsynchronously = true
    request.open('PUT', presignedUrl, performAsynchronously)
    request.setRequestHeader('content-type', file.type)

    request.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        onProgress(event.loaded, event.total)
      }
    }

    request.onload = () => {
      const success = request.status >= 200 && request.status < 300

      if (!success) {
        reject(new Error('Failed to upload file. Please try again'))
        onError({ filename, entity })
      } else {
        resolve({ filename, entity })
      }
    }

    request.send(file)
  })

const UploadService = {
  async uploadFileNonAdmin(
    file,
    path,
    json,
    onError = () => {},
    onProgress = () => {}
  ) {
    const {
      signedUrl: presignedUrl,
      filename,
      entity,
    } = await baseApi.post(path, { json }).json()

    return handleUploadToS3({
      presignedUrl,
      file,
      onError,
      onProgress,
      filename,
      entity,
    })
  },

  async uploadFile(file, path, json, onError = () => {}, onProgress = () => {}) {
    const {
      signedUrl: presignedUrl,
      filename,
      entity,
    } = await adminApi.post(path, { json }).json()

    return handleUploadToS3({
      presignedUrl,
      file,
      onError,
      onProgress,
      filename,
      entity,
    })
  },

  async multipartUpload(file, chunks, path, json) {
    const { key, signedUrls, uploadId } = await adminApi
      .post(`${path}/start`, { json })
      .json()

    const promises = Object.entries(signedUrls).map(([partNo, signedUrl]) =>
      multipartApi.put(signedUrl, { body: chunks[partNo - 1] })
    )

    const handleComplete = (responses) => {
      const parts = responses.map((res, idx) => ({
        ETag: res.headers.get('etag'),
        PartNumber: idx + 1,
      }))

      return adminApi
        .post(`${path}/complete`, {
          json: {
            key,
            uploadId,
            parts,
          },
        })
        .json()
    }

    const handleAbort = () =>
      adminApi
        .post(`${path}/abort`, {
          json: {
            key,
            uploadId,
          },
        })
        .json()

    const response = await Promise.all(promises)
      .then((responses) => handleComplete(responses))
      .catch(handleAbort)

    return response
  },

  async createMultipartSignedUrl(payload, reference, defaultHeaders) {
    const path = `upload-jobs/${reference}/documents/upload`
    const { fileName, partsCount, fileSize } = payload
    try {
      const { key, signedUrls, uploadId } = await uploadApi
        .post(`${path}/start`, {
          json: { fileName, partsCount },
          headers: {
            ...defaultHeaders,
            'content-type': 'application/json',
          },
        })
        .json()

      const handleComplete = (responses, folderPath) => {
        const parts = responses.map(({ etag, partNo }) => ({
          ETag: etag,
          PartNumber: partNo,
        }))

        return uploadApi
          .post(`${path}/complete`, {
            json: {
              key,
              uploadId,
              parts,
              path: folderPath,
              fileSize,
            },
            headers: {
              ...defaultHeaders,
              'content-type': 'application/json',
            },
          })
          .json()
      }

      const handleAbort = () =>
        uploadApi
          .post(`${path}/abort`, {
            json: {
              key,
              uploadId,
            },
            headers: {
              ...defaultHeaders,
              'content-type': 'application/json',
            },
          })
          .json()

      return {
        key,
        signedUrls,
        uploadId,
        handleComplete,
        handleAbort,
      }
    } catch (error) {
      return {}
    }
  },

  async createSignedUrl(fileName, reference, defaultHeaders) {
    try {
      const response = await uploadApi
        .post(`upload-jobs/${reference}/documents`, {
          json: { fileName },
          headers: {
            ...defaultHeaders,
            'content-type': 'application/json',
          },
        })
        .json()

      return response
    } catch (error) {
      return {}
    }
  },

  async confirmUpload(
    req,
    reference,
    defaultHeaders,
    documentId,
    fileSize,
    onUploaded,
    onError
  ) {
    const fileUploadedSuccessfully = req.status >= 200 && req.status < 300
    if (!fileUploadedSuccessfully) {
      onError('File failed to upload. Try again in a little bit.')
      return
    }

    try {
      await uploadApi
        .put(`upload-jobs/${reference}/documents/${documentId}`, {
          json: { fileSize },
          headers: {
            ...defaultHeaders,
            'content-type': 'application/json',
          },
        })
        .json()
    } catch (error) {
      onError('File failed to upload. Try again in a little bit.')
      return
    }

    onUploaded(documentId)
  },
}

export { UploadService }

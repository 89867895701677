import { FieldArray, useField } from 'formik'
import { useContext, useEffect } from 'react'
import * as Validator from 'yup'

import { Button } from 'components/common/Button'
import {
  FormLayoutContext,
  EmptyFileItem,
  DocumentField,
  FileItem,
} from 'components/company/common'
import { NewLineIcon, TrashIcon } from 'components/icons'
import { styled, apply } from 'lib/styled'

const LinksContainer = styled.div(apply('flex flex-column'), {
  gap: '1rem',
})

const ExtraActionContainer = styled.div(apply('flex'))

const ContentContainer = styled.div(apply('flex-1 mr-4'))

const ExtraButton = styled(Button)(({ isFirstElement, theme }) => ({
  aspectRatio: '1 / 1',
  padding: 0,
  width: 40,
  height: 40,
  marginTop: isFirstElement ? '28px' : '0',
  background: isFirstElement ? theme.colors.primary : '#555555',
  color: 'white',
}))

const { string } = Validator

const Links = ({ values }) => {
  const { editing } = useContext(FormLayoutContext)
  // eslint-disable-next-line no-unused-vars
  const [{ value: url }, { error }, { setValue }] = useField({
    name: 'link_input',
    validate: (value) =>
      new Promise((resolve) => {
        string()
          .label('Links')
          .url()
          .validate(value)
          .then(() => resolve(undefined))
          .catch((err) => resolve(err.message))
      }),
  })

  useEffect(() => {
    setValue('')
  }, [])

  const handleAddLink = (arrayHelpers) => {
    if (url !== '' && !error) {
      arrayHelpers.push({
        name: `Link ${values.length + 1}`,
        url,
      })
      setValue('')
    }
  }

  return (
    <FieldArray
      name="links"
      render={(arrayHelpers) => (
        <LinksContainer>
          <ExtraActionContainer>
            <ContentContainer>
              <DocumentField
                label="Supporting Links"
                name="link_input"
                type="link"
                tooltip="Add terms & conditions, policy, and other supporting links"
              />
            </ContentContainer>
            {editing ? (
              <ExtraButton
                type="button"
                onClick={() => handleAddLink(arrayHelpers)}
                isFirstElement
              >
                <NewLineIcon />
              </ExtraButton>
            ) : null}
          </ExtraActionContainer>
          {values && values.length ? (
            values.map(({ url }, idx) => (
              <ExtraActionContainer key={idx}>
                <ContentContainer>
                  <FileItem
                    itemType="links"
                    name={`links.${idx}.name`}
                    url={url}
                    editing={editing}
                  />
                </ContentContainer>
                {editing ? (
                  <ExtraButton
                    type="button"
                    onClick={() => {
                      arrayHelpers.remove(idx)
                    }}
                  >
                    <TrashIcon />
                  </ExtraButton>
                ) : null}
              </ExtraActionContainer>
            ))
          ) : (
            <EmptyFileItem />
          )}
        </LinksContainer>
      )}
    />
  )
}

export { Links }

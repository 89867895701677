const SERVICE_CATEGORIES = [
  {
    value: 'photo_video_service',
    readableValue: 'Photo & Video',
  },
  {
    value: 'hd_survey_service',
    readableValue: 'Survey Grade HD',
  },
  {
    value: 'spatial_3d_service',
    readableValue: 'Spatial 3D',
  },
  {
    value: 'lidar_service',
    readableValue: 'LiDAR',
  },
  {
    value: 'thermal_service',
    readableValue: 'Thermal',
  },
  {
    value: 'specialist_service',
    readableValue: 'Specialist',
  },
]

export { SERVICE_CATEGORIES }

import CONSTANTS from '@seedcloud/constants'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { BillingContact } from '../common/Fields/BllingContact'
import { CommercialContact } from '../common/Fields/CommercialContact'
import { Details } from '../common/Fields/Details'
import { HeroPilotContact } from '../common/Fields/HeroPilotContact'
import { SupplierLayout } from '../common/SupplierLayout'

import { ConfirmModal } from 'components/common/ConfirmModal/ConfirmModal'
import { FormAction, FormLayoutProvider } from 'components/company/common'
import { SUPPLIER_TYPES } from 'constants/supplier'
import { isPublishing$, supplierModule } from 'modules/supplier'
import { requiredString, optionalString } from 'utils/validation'

const { object } = Validator

const validationSchema = object({
  companyName: requiredString('Supplier Name'),
  abn: optionalString('ABN').matches(/^\d+$/, 'ABN should only contain number'),
  phoneNumber: optionalString('Phone number').matches(
    CONSTANTS.REGEX.E164_PHONE_FORMAT,
    'Please include a country code (e.g +64213112)'
  ),
  website: optionalString('Supplier Website').url(
    'Please input full url (e.g https://example.com)'
  ),
  address: optionalString('Supplier Address'),
  supplierType: requiredString('Supplier Type'),
  commercial: object().shape({
    firstName: requiredString('Commercial Contact First Name'),
    lastName: optionalString('Commercial Contact Last Name'),
    phoneNumber: optionalString('Commercial Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: requiredString('Commercial Email').email('Not a valid email address'),
  }),
  billing: object().shape({
    firstName: optionalString('Billing Contact First Name'),
    lastName: optionalString('Billing Contact Last Name'),
    phoneNumber: optionalString('Billing Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: optionalString('Billing Email').email('Not a valid email address'),
  }),
  heroPilot: object().shape({
    firstName: optionalString('Hero Pilot First Name'),
    lastName: optionalString('Hero Pilot Last Name'),
    phoneNumber: optionalString('Hero Pilot Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: optionalString('Hero Pilot Email').email('Not a valid email address'),
  }),
})
  .test('commercial-email', 'commercial email cannot be duplicated', function (value) {
    const { billing, heroPilot, commercial } = value
    if (commercial.email === heroPilot.email || commercial.email === billing.email) {
      return this.createError({
        message: 'Email needs te be unique',
        path: 'commercial.email',
      })
    }
    return true
  })
  .test('billing-email', 'billing email cannot be duplicated', function (value) {
    const { billing, heroPilot, commercial } = value
    if (billing.email === undefined) return true // return 'true' because billing email is optional right now
    if (billing.email === heroPilot.email || billing.email === commercial.email) {
      return this.createError({
        message: 'Email needs te be unique',
        path: 'billing.email',
      })
    }
    return true
  })
  .test('heroPilot-email', 'heroPilot email cannot be duplicated', function (value) {
    const { billing, heroPilot, commercial } = value
    if (heroPilot.email === undefined) return true // return 'true' because heroPilot email is optional right now
    if (heroPilot.email === billing.email || heroPilot.email === commercial.email) {
      return this.createError({
        message: 'Email needs te be unique',
        path: 'heroPilot.email',
      })
    }
    return true
  })

const removeEmptyObjects = (obj) => {
  const result = { ...obj }
  Object.keys(result).forEach((key) => {
    const value = result[key]
    if (typeof value === 'object' && value !== null) {
      result[key] = removeEmptyObjects(value)
      // Remove the object if all its properties are empty strings
      if (Object.values(result[key]).every((val) => val === '')) {
        delete result[key]
      }
    }
  })
  return result
}

const CreateSupplier = ({ isPublishing }) => {
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()

  const initialValues = {
    companyName: '',
    abn: '',
    phoneNumber: '',
    website: '',
    address: '',
    supplierType: SUPPLIER_TYPES.PILOT,
    commercial: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    billing: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    heroPilot: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
  }

  const handleSubmit = async (values) => {
    setShowModal(false)
    const payload = removeEmptyObjects(values)
    if (values.supplierType === SUPPLIER_TYPES.PROFESSIONAL) {
      payload.heroPilot = undefined
    }
    const supplier = await supplierModule.publishSupplier(null, payload)
    if (supplier && supplier.id) {
      history.push({ pathname: `/supplier/${supplier.id}` })
    }
  }

  return (
    <>
      <FormLayoutProvider value={{ editing: true }}>
        <FormProvider
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {
            setShowModal(true)
          }}
        >
          {({ values, resetForm }) => (
            <>
              <SupplierLayout
                loading={isPublishing}
                hideTabs
                footer={
                  <FormAction
                    okText="Create"
                    onCancel={() => {
                      resetForm()
                      history.goBack()
                    }}
                  />
                }
              >
                <Details
                  showExtra={false}
                  title="Create New Supplier"
                  subtitle="* indicates required field"
                />
                <CommercialContact title="Commercial Contact" />
                <BillingContact title="Billing Contact" />
                {values.supplierType !== SUPPLIER_TYPES.PROFESSIONAL ? (
                  <HeroPilotContact title="Hero Pilot" />
                ) : null}
              </SupplierLayout>
              <ConfirmModal
                title="Are You Sure?"
                subtitle="Are you sure that all of the information submitted is correct?"
                isOpen={showModal}
                closeModal={() => setShowModal(false)}
                onConfirm={() => handleSubmit(values)}
              />
            </>
          )}
        </FormProvider>
      </FormLayoutProvider>
    </>
  )
}

const ConnectedCreateSupplier = connect(() => ({
  isPublishing: isPublishing$,
}))(CreateSupplier)

export { ConnectedCreateSupplier as CreateSupplier }

import { connect } from '@seedcloud/stateless'
import { useLayoutEffect, useState } from 'react'

import { PilotList } from './PilotList'

import { API_TYPES } from 'lib/http-client'
import { createCompanyModule } from 'modules/company'
import { createPilotModule } from 'modules/pilot'
import { createPilotCategoriesModule } from 'modules/pilotCategory'
import { useDebounce } from 'utils/hooks/useDebounce'
import { useUpdateEffect } from 'utils/hooks/useUpdateEffect'

const {
  module: pilotModule,
  selectors: { pilotList$, inspectedEntity$, isLoadingPilots$ },
} = createPilotModule(API_TYPES.WEB)

const { module: pilotCategoryModule } = createPilotCategoriesModule(API_TYPES.WEB)

const {
  selectors: { inspectedCompany$ },
} = createCompanyModule(API_TYPES.WEB)

const onSearchChanged = (value) => {
  pilotModule.filterPilots(null, { filterQuery: value || '' })
}

export const ConnectedPilotListContainer = connect(() => ({
  pilotList: pilotList$,
  isLoadingPilots: isLoadingPilots$,
  inspectedEntity: inspectedEntity$,
  inspectedCompany: inspectedCompany$,
}))(PilotListContainer)

const { fetchPilots, reset } = pilotModule

function PilotListContainer({
  isLoadingPilots,
  pilotList,
  inspectedEntity,
  inspectedCompany = {},
}) {
  useLayoutEffect(() => {
    pilotCategoryModule.fetchPilotCategories(null, { turnPage: false })
    fetchPilots(null)
    return () => {
      reset()
    }
  }, [])

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  useUpdateEffect(() => {
    onSearchChanged(debouncedSearch)
  }, [debouncedSearch])

  const { id } = inspectedCompany

  const pilotListProps = {
    isLoadingPilots,
    pilotList,
    inspectedEntity,
    search,
    setSearch,
    orgId: id,
    onFilterChange: (value) =>
      pilotModule.filterPilots(null, { filterQuery: value || '' }),
    isAdmin: false,
  }

  return <PilotList {...pilotListProps} />
}

import { useState } from 'react'

import { useFilter } from './FilterBar'

import { DateField } from 'components/common/DateField'
import { useDebounce, useUpdateEffect } from 'utils/hooks'

const DateRange = () => {
  const { onSubmit, jobFilters, selectedTab } = useFilter()
  const { from, to } = jobFilters

  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  const debouncedFrom = useDebounce(fromDate, 500)
  const debouncedTo = useDebounce(toDate, 500)

  useUpdateEffect(() => {
    onSubmit({ from: debouncedFrom })
  }, [debouncedFrom])

  useUpdateEffect(() => {
    onSubmit({ to: debouncedTo })
  }, [debouncedTo])

  return (
    <>
      <DateField
        name="from"
        type="text"
        onChange={setFromDate}
        autoOk
        maxDate={selectedTab === 'overdue' ? to : undefined}
        newStyle
        size="lg"
      />
      <DateField
        disabled={selectedTab === 'overdue'}
        name="to"
        type="text"
        minDate={from}
        onChange={setToDate}
        autoOk
        newStyle
        size="lg"
      />
    </>
  )
}

export { DateRange }

import { useState } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'

import { Button, CancelButton as CancelButtonBase } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('text-black bg-white rounded-lg '), {
  padding: '64px 64px',
  width: '656px',
  maxWidth: '656px',
  alignItems: 'center',
})

const Title = styled.h1(apply('mb-6'), {
  fontWeight: 700,
  fontSize: '2rem',
  textAlign: 'left',
})

const Space = styled.div(apply('flex mt-4'), {
  gap: '1rem',
})

const ActionButton = styled(Button)(apply('flex-1'), {
  marginTop: '1rem',
})

const CancelButton = styled(CancelButtonBase)(apply('flex-1 mt-5'), {
  background: 'transparent',
  boxShadow: 'none',
  marginTop: '1rem',
})

const styles = (selectStyles = {}) => ({
  control: (_, state) => {
    const basicStyle = apply(
      'flex flex-row border-solid border-0 rounded-md bg-grey-100 text-lg  w-full',
      { ...selectStyles.control }
    )
    const disabledStyle = state.isDisabled
      ? apply('bg-grey-100 w-full', {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        })
      : {}

    return { ...basicStyle, ...disabledStyle }
  },

  option: (provided) => ({
    ...provided,
    ...apply('bg-grey-lightest text-black', {
      '&:hover': apply('bg-grey-light'),
    }),
  }),

  dropdownIndicator: () => apply('p-0 px-2 relative', { top: 1 }),

  valueContainer: (provided) => ({
    ...provided,
    ...apply('px-3 py-2'),
  }),

  indicatorSeparator: () => apply('hidden'),

  singleValue: (provided) => ({
    ...provided,
    ...apply('text-black'),
  }),

  placeholder: (provided) => ({
    ...provided,
    ...apply('text-grey'),
  }),

  menuList: (provided) => ({
    ...provided,
    height: '75px',
    overflowY: 'scroll',
  }),
})

const Label = styled.label(
  apply('mb-2 uppercase tracking-wide text-xs text-grey-dark normal-case')
)

Modal.setAppElement('#root')

function AssignFileModal({
  isOpen,
  closeModal,
  title,
  onConfirm,
  loading,
  confirmText = 'Asssign',
  confirmLoadingText = 'Assigning...',
  selectStyles,
  folderList,
}) {
  // eslint-disable-next-line no-unused-vars
  const [selectedFolder, setSelectedFolder] = useState()

  const options = folderList.map((folder) => ({
    value: folder.id,
    label: folder.name,
  }))

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      id="unique"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(20, 20, 20, 0.2)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <Title>{title}</Title>
        <Label htmlFor="folder-select">Choose Folder</Label>
        <Select
          label="Pilot Category"
          type="select"
          onChange={(selected, { action }) => {
            setSelectedFolder(action === 'clear' ? undefined : selected.value)
          }}
          isClearable
          menuPlacement="auto"
          styles={styles(selectStyles)}
          isSearchable
          options={options}
          placeholder="Choose Folder"
          classNamePrefix="folder-select"
          instanceId="folder-select"
          inputId="folder-select"
        />

        <Space>
          <CancelButton disabled={loading} onClick={() => closeModal()}>
            Cancel
          </CancelButton>
          <ActionButton disabled={loading} onClick={() => onConfirm()}>
            {loading ? confirmLoadingText : confirmText}
          </ActionButton>
        </Space>
      </Root>
    </Modal>
  )
}

export { AssignFileModal }

/* eslint-disable camelcase */
import { connect } from '@seedcloud/stateless'
import { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'

import { FieldGroup } from 'components/company/common'
import { SupplierCategoriesLayout } from 'components/supplier/SupplierCategories/common/SupplierCategoriesLayout'
import { EquipmentTable } from 'components/supplier/SupplierCategories/EquipmentTable'
import { createCompanyModule } from 'modules/company'
import { createPilotCategoriesModule } from 'modules/pilotCategory'
import { invitationStatus$, supplierType$ } from 'modules/supplier'

const {
  module: companyModule,
  selectors: { isInspectingCompany$, currency$ },
} = createCompanyModule()

const {
  module: pilotCategoryModule,
  selectors: {
    isFetchingPilotCategories$,
    pilotCategoriesFilters$,
    pilotCategoriesList$,
  },
} = createPilotCategoriesModule()

const SupplierEquipment = ({
  pilotCategoriesList,
  isFetchingPilotCategories,
  isInspectingCompany,
  currency,
  pilotCategoriesFilters,
  status,
}) => {
  const { id } = useParams()

  useLayoutEffect(() => {
    const getPilotCategories = async () => {
      await pilotCategoryModule.resetPagination()
      await pilotCategoryModule.fetchPilotCategories(null, {
        turnPage: false,
        type: 'equipment',
        supplierId: id,
      })
    }
    getPilotCategories()
    companyModule.inspectCompany()
  }, [])

  const {
    paging: { startRow, next },
    limit,
  } = pilotCategoriesFilters

  return (
    <SupplierCategoriesLayout
      status={status}
      loading={isFetchingPilotCategories || isInspectingCompany}
    >
      <FieldGroup title="Equipment Categories">
        <EquipmentTable
          currency={currency}
          values={pilotCategoriesList}
          limit={limit}
          next={next}
          startRow={startRow}
          id={id}
        />
      </FieldGroup>
    </SupplierCategoriesLayout>
  )
}

const ConnectedSupplierEquipment = connect(() => ({
  pilotCategoriesList: pilotCategoriesList$,
  isFetchingPilotCategories: isFetchingPilotCategories$,
  isInspectingCompany: isInspectingCompany$,
  currency: currency$,
  pilotCategoriesFilters: pilotCategoriesFilters$,
  type: supplierType$,
  status: invitationStatus$,
}))(SupplierEquipment)

export { ConnectedSupplierEquipment as SupplierEquipment }

import { createModule } from '@seedcloud/stateless'

// eslint-disable-next-line import/no-cycle
import { getCompanyAndOrganizationId } from 'utils/getOrganizationId'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
})

function organizationModule(name, OrganizationService) {
  const inspectOrganization = (module, { setError }) =>
    withTryCatch(
      async (id) => {
        module.setState({
          inspectedEntity: id,
        })

        const entity = await OrganizationService.read(id, id)

        module.setState({
          entities: { [id]: entity },
        })

        return entity
      },
      { errHandler: setError }
    )

  const updateOrganization = (module, { setError }) =>
    withTryCatch(
      async (_, payload) => {
        const { organizationId: id, companyId } = await getCompanyAndOrganizationId()

        await OrganizationService.update(id, companyId, payload)

        const entity = await OrganizationService.read(id, companyId)

        module.setState({
          entities: { [id]: { ...entity } },
        })
      },
      { errHandler: setError }
    )

  return createModule({
    name,
    initialState: INITIAL_STATE,
    decorators: {
      inspectOrganization,
      updateOrganization,
    },
  })
}

export { organizationModule }

import { createModule } from '@seedcloud/stateless'

import { ProjectService } from './service'

import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  order: [],
})

const fetchProjects = (module, { setError }) =>
  withTryCatch(
    async () => {
      const { entities, order, next: newNext } = await ProjectService.list()

      module.setState({
        entities,
        order,
        paging: {
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const createProject = (module, { setError }) =>
  withTryCatch(
    async (_, reference) => {
      const { order } = module.getState()
      const newProject = await ProjectService.create(reference)

      order.push(newProject.id)

      module.setState({
        entities: { [newProject.id]: newProject },
        order,
      })
    },
    { errHandler: setError }
  )

const projectModule = createModule({
  name: 'project',
  initialState: INITIAL_STATE,
  decorators: {
    fetchProjects,
    createProject,
  },
})

export { projectModule }

import { connect } from '@seedcloud/stateless'

import { DocumentField, FileItem } from 'components/company/common'
import { styled, apply } from 'lib/styled'
import { createCompanyModule } from 'modules/company'

const {
  module: companyModule,
  selectors: { isUploadingLogo$ },
} = createCompanyModule()

const LogoContainer = styled.div(apply('flex flex-column'), {
  flex: '1',
  gap: '1rem',
})

const ContentContainer = styled.div(apply('flex-1'))

const Logo = ({ logo, isUploading, organizationId }) => {
  const handleUploadImage = async ([file]) => {
    const response = await companyModule.uploadLogo(organizationId, { file })
    return response ? response.filename : null
  }

  return (
    <LogoContainer>
      <ContentContainer>
        <DocumentField
          label="Logo"
          name="logo"
          tooltip="Upload your logo here"
          isUploading={isUploading}
          handleOnFileDrop={handleUploadImage}
          accept="image/jpeg, image/png"
          errorMessage="Only PNG/JPG file type allowed"
        />
      </ContentContainer>
      <ContentContainer>
        <FileItem
          itemType="logo"
          name="logo"
          url={logo}
          urlText="Logo"
          handleClick={() => window.open(logo)}
        />
      </ContentContainer>
    </LogoContainer>
  )
}

const ConnectedLogo = connect(() => ({
  isUploading: isUploadingLogo$,
}))(Logo)

export { ConnectedLogo as Logo }

import { Switch, Route } from 'react-router-dom'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { AviationDetail } from 'components/company/AviationDetail/AviationDetailGateway'
import { CompanyDetail } from 'components/company/CompanyDetail/CompanyDetailGateway'
import { EquipmentCategory } from 'components/company/PilotCategories/EquipmentCategory'
import { PilotCategories } from 'components/company/PilotCategories/PilotCategoriesGateway'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

function CompanyManager() {
  return (
    <Container>
      <Switch>
        <Route
          exact
          path={['/', '/company', '/company/details']}
          component={CompanyDetail}
        />
        <Route
          exact
          path={['/company/ratecard', '/company/ratecard/categories']}
          component={PilotCategories}
        />
        <Route exact path="/company/ratecard/equipment" component={EquipmentCategory} />
        <Route exact path="/company/aviation" component={AviationDetail} />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { CompanyManager }

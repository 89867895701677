/* eslint-disable no-await-in-loop */
import { noop } from '@seedcloud/ramda-extra'
import { getOriginPrivateDirectory } from 'native-file-system-adapter'
import indexedDbAdapter from 'native-file-system-adapter/src/adapters/indexeddb'

const DEFAULT_CHUNK_SIZE = 16 * 1024 * 1024

const calculateChunkSize = (fileSize) => {
  const fsize = Number(fileSize) / 1024 / 1024
  if (fsize < 50) return 8 * 1024 * 1024
  return DEFAULT_CHUNK_SIZE
}

async function fetchWithRange(url, start, end) {
  return fetch(url, {
    headers: {
      range: `bytes=${start}-${end}`,
    },
  })
}

function getRangeAndLength(contentRange) {
  if (!contentRange) throw Error('No content range in headers!')

  const [range, length] = contentRange.split('/')
  const [start, end] = range.split('-')

  return {
    start: parseInt(start, 10),
    end: parseInt(end, 10),
    length: parseInt(length, 10),
  }
}

export const isComplete = ({ end, length }) => end === length - 1

async function downloadFile(id, dirHandle) {
  const completedFileHandle = await dirHandle.getFileHandle(id, {
    create: false,
  })
  const completedFile = await completedFileHandle.getFile()
  return completedFile
}

// eslint-disable-next-line complexity
async function downloadInChunks(
  url,
  { id, fileSize = 0, onProgress = noop, folderId = '' }
) {
  const chunkSize = fileSize ? calculateChunkSize(fileSize) : DEFAULT_CHUNK_SIZE

  const dirHandle = await getOriginPrivateDirectory(indexedDbAdapter)
  let filehandle = await dirHandle.getFileHandle(id, { create: true })
  const file = await filehandle.getFile()

  let rangeAndLength = { start: -1, end: -1, length: -1 }

  if (file.size) {
    // File already on the disk should resume when available
    // the size here should represent the start of the download if file already there
    // since we dont know the length are, we use -1.
    rangeAndLength = { start: file.size, end: file.size + chunkSize, length: -1 }
  }

  const writeable = await filehandle.createWritable({
    keepExistingData: true,
  })

  while (!isComplete(rangeAndLength)) {
    const { end } = rangeAndLength
    const nextRange = { start: end + 1, end: end + chunkSize }

    const res = await fetchWithRange(url, nextRange.start, nextRange.end)

    if (res.ok) {
      const buffer = await res.blob()
      await writeable.write(buffer)

      onProgress({
        progress: buffer.size,
        folderId,
      })

      rangeAndLength = getRangeAndLength(res.headers.get('Content-Range'))
    } else if (res.status === 416) {
      await dirHandle.removeEntry(id)
      filehandle = await dirHandle.getFileHandle(id, { create: true })
      rangeAndLength = { start: -1, end: -1, length: -1 }
    } else {
      throw Error('Unknown Error!')
    }
  }

  // Saving on the indexeddb store are done
  await writeable.close()

  const blob = await downloadFile(id, dirHandle)
  // Before removing the entry, we clone the blob object
  const completedFile = blob.slice()

  // Remove the entry after saving
  await dirHandle.removeEntry(id)

  return completedFile
}

export { downloadInChunks }

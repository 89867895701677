import { prop, isNotEmpty } from '@seedcloud/ramda-extra'
import { useCombobox } from 'downshift'
import { useField, useFormikContext } from 'formik'
import { useEffect } from 'react'

import { NewLabel } from './Fields/Label'

import { styled, apply } from 'lib/styled'
import { useGeocoding } from 'utils/hooks'

const Root = styled.div(apply('relative'))

const Label = styled.label(apply('uppercase tracking-wide text-xs text-grey-dark'))

const InputContainer = styled.div(
  apply('flex-1 flex-column flex'),
  ({ isAttachedToSuggestions }) =>
    isAttachedToSuggestions ? apply('rounded-b-none border-grey') : {}
)

const NewInputContainer = styled.div(apply('flex-1 flex flex-column mx-0'), {
  gap: '.5em',
})

const Input = styled.input(
  apply('bg-white text-lg border-0 px-3 py-2 w-full rounded-md'),
  {
    border: '1px solid #CCCCCC',
    '&:read-only': {
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
      border: '0',
      backgroundColor: 'rgb(243 244 246)',
    },
  }
)

const NewInput = styled.input(
  apply('border text-black rounded-lg w-full', {
    border: '1px solid #CCCCCC',
    padding: '.5rem 1rem',
    fontSize: '0.9375rem',
    height: '40px',
    '&:read-only': {
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
    },
  })
)

const Suggestions = styled.ul(
  apply(
    'absolute z-1 bg-grey-lightest p-0 py-1 m-0 w-full border-0 border-b-2 border-solid border-grey rounded-b-lg pin-l mt-2'
  ),
  {
    top: '100%',
    listStyleType: 'none',
    overflow: 'hidden',
  },
  ({ isVisible }) => (isVisible ? apply('block') : apply('hidden'))
)

const Item = styled.li(
  apply('px-3 py-2'),
  {
    cursor: 'pointer',
  },
  ({ isHighlighted }) => (isHighlighted ? apply('bg-grey-light') : {})
)

const ErrorMessage = styled.div(apply('mt-2 text-red text-sm'))

// eslint-disable-next-line complexity
function LocationField({
  name,
  id,
  type = 'text',
  label,
  required,
  readOnly,
  disabled,
  placeholder,
  testId,
  containerProps,
  newStyle,
  ...props
}) {
  const { results, forwardGeocode, resetResults } = useGeocoding()
  const hasResults = isNotEmpty(results)

  const { isSubmitting } = useFormikContext()

  const [
    { onChange: onFieldChange, onBlur: onFieldBlur, multiple },
    { touched, error, initialValue },
    { setTouched: setFieldTouched },
  ] = useField({
    name,
    id,
    type,
    ...props,
  })

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
    reset,
    setInputValue,
  } = useCombobox({
    labelId: name,
    inputId: id ?? name,
    items: results,
    itemToString: prop('place_name'),
    initialInputValue: prop(['place_name'], initialValue),

    onStateChange: ({ type: stateChangeType, inputValue: query }) => {
      const { InputChange, InputBlur } = useCombobox.stateChangeTypes

      const handler = {
        [InputChange]: () => {
          forwardGeocode(query)

          onFieldChange({ target: { value: undefined, name } })
        },
        [InputBlur]: resetResults,
      }[stateChangeType]

      if (handler) {
        handler()
      }
    },

    onSelectedItemChange: ({ selectedItem }) => {
      onFieldChange({ target: { value: selectedItem, name } })

      resetResults()
    },
  })

  function handleBlur(...args) {
    setFieldTouched(true)

    onFieldBlur(...args)
  }

  useEffect(() => {
    if (readOnly) {
      reset()
    }
  }, [readOnly])

  useEffect(() => {
    if (!isSubmitting && disabled) {
      setInputValue(prop(['place_name'], initialValue))
    }
  }, [disabled, initialValue])

  const LabelComp = newStyle ? NewLabel : Label
  const InputComp = newStyle ? NewInput : Input
  const InputContainerComp = newStyle ? NewInputContainer : InputContainer

  return (
    <Root {...containerProps}>
      <InputContainerComp isAttachedToSuggestions={hasResults} {...getComboboxProps()}>
        <LabelComp {...getLabelProps()}>
          {label}
          {required && '*'}
        </LabelComp>
        <InputComp
          multiple={multiple}
          placeholder={placeholder}
          {...getInputProps({ onBlur: handleBlur })}
          disabled={disabled}
          data-test-id={`${testId}-search-input`}
          {...props}
        />

        <Suggestions
          isVisible={hasResults}
          {...getMenuProps()}
          data-test-id={`${testId}-search-menulist`}
        >
          {results.map((place, index) => (
            <Item
              isHighlighted={highlightedIndex === index}
              key={`${prop('id', place)}`}
              {...getItemProps({ item: place, index })}
            >
              {prop('place_name', place)}
            </Item>
          ))}
        </Suggestions>
      </InputContainerComp>

      {/* Need to provide a message instead of using formik's error as the error is an object */}
      {!hasResults && touched && error && (
        <ErrorMessage>This field is required</ErrorMessage>
      )}
    </Root>
  )
}
export { LocationField }

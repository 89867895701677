import { connect } from '@seedcloud/stateless'
import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { FilterInput } from './FilterInput'
import { RoleGroupSelectField } from './RoleGroupSelectField'
import { StaffListItem } from './StaffListItem'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { Button as ButtonBase } from 'components/common/Button'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { CanCreateStaff } from 'lib/permissions'
import { styled, apply } from 'lib/styled'
import {
  staffModule,
  staffList$,
  inspectedEntity$,
  isLoadingStaff$,
} from 'modules/staff'

const Container = styled.div(
  apply('flex flex-column w-1/4 min-w-10 max-w-24 mr-6 bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const FilterContainer = styled.div(
  apply('p-4 border-0 border-b-1 border-solid border-grey-lighter')
)
const InputContainer = styled.div(apply('flex mb-4'))

const CreateStaffButton = styled(ButtonBase)(
  apply('flex justify-center p-2 mx-1 items-center', {
    width: '30px',
    boxSizing: 'content-box',
  })
)

const List = styled.ol(
  apply('p-0 m-0', {
    overflow: 'auto',
    listStyleType: 'none',
  })
)

const ConnectedStaffList = connect(() => ({
  staffList: staffList$,
  isLoadingStaff: isLoadingStaff$,
  inspectedEntity: inspectedEntity$,
}))(StaffList)

const { fetchStaff, clearAvatar } = staffModule

function StaffList({ staffList, isLoadingStaff, inspectedEntity, ...props }) {
  const history = useHistory()

  useLayoutEffect(() => {
    fetchStaff(null)
  }, [])

  return (
    <Container {...props}>
      <FilterContainer>
        <InputContainer>
          <FilterInput containerProps={{ style: apply('flex-1') }} />
          <CanCreateStaff>
            <CreateStaffButton
              onClick={() => {
                clearAvatar()
                history.push('/staff/create')
              }}
              style={apply('text-blue-700')}
            >
              <PlusIcon width={17} height={17} />
            </CreateStaffButton>
          </CanCreateStaff>
        </InputContainer>

        <RoleGroupSelectField />
      </FilterContainer>

      <Loader
        loading={isLoadingStaff}
        renderPlaceholder={<Spinner size={32} thickness={6} style={apply('mt-4')} />}
      >
        <List>
          {staffList.map(
            ({ id, activatedAt, isBanned, isDeleted, role, userDetails }) => {
              const { firstName, lastName, phoneNumber, avatar } = userDetails
              const fullName = `${firstName} ${lastName}`
              const isInspected = id === inspectedEntity

              const onInspectStaff = () => {
                history.push(`/staff/${id}`)
              }

              return (
                <StaffListItem
                  key={id}
                  isInspected={isInspected}
                  isBanned={isBanned}
                  isDeleted={isDeleted}
                  fullName={fullName}
                  role={role}
                  phoneNumber={phoneNumber}
                  activatedAt={activatedAt}
                  avatarSrc={avatar}
                  onClick={onInspectStaff}
                />
              )
            }
          )}
        </List>
      </Loader>
    </Container>
  )
}

export { ConnectedStaffList as StaffList }

import { filter, isNilOrEmpty, pick } from '@seedcloud/ramda-extra'
import { createModule } from '@seedcloud/stateless'

import { PilotCategoryAdminService } from './service'

import toast from 'lib/toaster'
import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  order: [],
  isSubmitting: false,
  limit: undefined,
  paging: {
    startRow: undefined,
    next: undefined,
  },
})

const filterPilotCategories =
  (module) =>
  async (_, { filterQuery }) => {
    module.setState({
      ...filterQuery,
    })
  }

const resetPagination = (module) => async () =>
  module.setState({
    limit: undefined,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

const fetchPilotCategories = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = true, turnNext, type = null, supplierId = null }) => {
      const { paging, limit } = module.getState()

      const next = calcNext(turnPage, turnNext, paging, limit)

      const {
        entities,
        order,
        next: newNext,
        limit: newLimit,
      } = await PilotCategoryAdminService.list({
        next,
        supplierType: type,
        supplierId,
        limit,
      })

      module.setState({
        entities,
        order,
        limit: newLimit,
        paging: {
          startRow: calcStartRow(newNext, newLimit, paging),
          next: newNext,
        },
      })
    },
    { onError: (err) => setError(null, err) }
  )

const publishPilotCategories = (module, { setError }) =>
  withTryCatch(
    async (_, payload) => {
      module.setState({
        isSubmitting: true,
      })
      const pilotCategoryPayload = pick(['description', 'rate'], payload)

      const pilotCategory = await PilotCategoryAdminService.publish({
        ...pilotCategoryPayload,
      })

      toast.success('New category has been added!')

      module.setState({
        isSubmitting: false,
      })

      return pilotCategory
    },
    { errHandler: setError }
  )

const updatePilotCategories = (module, { setError }) =>
  withTryCatch(
    async (id, payload) => {
      const updatedPilotCategories = await PilotCategoryAdminService.update(id, payload)

      toast.success('Pilot Category has been updated!')

      module.setState({
        entities: {
          [id]: { ...updatedPilotCategories },
        },
      })
    },
    { errHandler: setError }
  )

const deletePilotCategory = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      if (isNilOrEmpty(id)) return

      const { entities, order } = module.getState()

      await PilotCategoryAdminService.delete(id)

      delete entities[id]
      const updatedOrder = filter((order) => order !== id, order)

      module.setState({
        entities,
        order: updatedOrder,
      })
    },
    {
      errHandler: setError,
    }
  )

const pilotCategoryAdminModule = createModule({
  name: 'pilotCategory-admin',
  initialState: INITIAL_STATE,
  decorators: {
    fetchPilotCategories,
    publishPilotCategories,
    updatePilotCategories,
    deletePilotCategory,
    resetPagination,
    filterPilotCategories,
  },
})

export { pilotCategoryAdminModule }

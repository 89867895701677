import { Switch, Route } from 'react-router-dom'

import { CreateProduct } from './CreateProduct'
import { ProductDetails } from './ProductDetails'
import { ProductList } from './ProductList'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { ProtectedRoute } from 'components/common/ProtectedRoute'
import { RESOURCES } from 'constants/resources'
import { usePermissions } from 'lib/permissions'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

// eslint-disable-next-line react/display-name
const withProductList = (Component) => (props) =>
  (
    <>
      <ProductList />
      <Component {...props} />
    </>
  )

function ProductsManager() {
  const permissions = usePermissions()
  const canCreateProduct = permissions.can('create', RESOURCES.PRODUCT)

  return (
    <Container>
      <Switch>
        <Route exact path={['/', '/products']} component={ProductList} />
        <ProtectedRoute
          exact
          path="/product/create"
          render={withProductList(CreateProduct)}
          allowed={canCreateProduct}
        />
        <Route exact path="/product/:id" render={withProductList(ProductDetails)} />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { ProductsManager }

import { path } from '@seedcloud/ramda-extra'
import { useEffect } from 'react'

function usePilotCompany(module, inspectedJob, property) {
  useEffect(() => {
    const id = path([property, 'pilotCategory', 'organizationId'], inspectedJob)
    if (id) {
      module.inspectOrganization(id)
    }
  }, [inspectedJob])
}

export { usePilotCompany }

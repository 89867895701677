import { createModule } from '@seedcloud/stateless'
import { toast } from 'react-toastify'

import { CompanyAdminService as CompanyService } from './service'

// eslint-disable-next-line import/no-cycle
import { getOrganizationId } from 'utils/getOrganizationId'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  inspectedDocument: undefined,
})

const inspectCompany = (module, { setError }) =>
  withTryCatch(
    async () => {
      const { organizationId: id } = await getOrganizationId()

      module.setState({
        inspectedEntity: id,
      })

      const entity = await CompanyService.read(id)

      module.setState({
        entities: { [id]: entity },
      })

      return entity
    },
    { errHandler: setError }
  )

const updateCompany = (module, { setError }) =>
  withTryCatch(
    async (_, payload) => {
      const { organizationId: id } = await getOrganizationId()

      const updatedCompany = await CompanyService.update(id, payload)

      toast.success('Company details has been updated!')

      module.setState({
        entities: { [id]: { ...updatedCompany } },
      })

      return updatedCompany
    },
    { errHandler: setError }
  )

const uploadLogo = (module, { setError }) =>
  withTryCatch(
    async (id, { file }) => {
      const filename = await CompanyService.uploadLogo(id, file, {
        fileName: file.name,
        fileSize: file.size,
      })

      return filename
    },
    { errHandler: setError }
  )

const uploadAviationDocuments = (module, { setError }) =>
  withTryCatch(
    async (_, { files, auth0UserId }) => {
      const { organizationId: id } = await getOrganizationId()

      const entities = await Promise.all(
        files.map((file) =>
          CompanyService.upload(id, file, {
            fileName: file.name,
            fileSize: file.size,
            authId: auth0UserId,
          })
        )
      )

      return entities
    },
    { errHandler: setError }
  )

const downloadAviationDocuments = (module, { setError }) =>
  withTryCatch(
    async (id, _organizationId) => {
      let organizationId = _organizationId

      if (!organizationId) {
        const { organizationId: currentOrganizationId } = await getOrganizationId()
        organizationId = currentOrganizationId
      }

      module.setState({ inspectedDocument: id })

      return CompanyService.download(id, organizationId)
    },
    { errHandler: setError }
  )

const updateCompanyCurrency = (module, { setError }) =>
  withTryCatch(
    async (id, currency) => {
      const payload = {
        currency,
      }
      const updatedPilotCategories = await CompanyService.update(id, payload)

      module.setState({
        entities: { [id]: { ...updatedPilotCategories } },
      })
    },
    { errHandler: setError }
  )

const companyAdminModule = createModule({
  name: 'company-admin',
  initialState: INITIAL_STATE,
  decorators: {
    inspectCompany,
    updateCompanyCurrency,
    updateCompany,
    uploadLogo,
    uploadAviationDocuments,
    downloadAviationDocuments,
  },
})

export { companyAdminModule }

import CONSTANTS from '@seedcloud/constants'
import { head, keys, path, pipe } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { CreateCompanyForm } from './CreateCompanyForm'
import { KeyContactsSection } from './KeyContactsSection'

import { Button, CancelButton } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { roleKey } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import toast from 'lib/toaster'
import { createCompanyModule } from 'modules/legacy-company'
import { createPilotCategoriesModule } from 'modules/pilotCategory'

const {
  module: companyModule,
  selectors: { isCreatingCustomer$ },
} = createCompanyModule()

const {
  module: pilotCategoryModule,
  selectors: { pilotCategoriesList$ },
} = createPilotCategoriesModule()

const { string, object } = Validator
const requiredString = string().required('This field is required')

const validationSchema = object({
  organizationDetails: object({
    name: requiredString,
    address: string().optional().label('Address'),
    abn: requiredString,
  }).required(),
  userDetails: object({
    firstName: requiredString,
    lastName: requiredString,
    phoneNumber: requiredString.matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please input phone number with country code ex: +6123123'
    ),
    email: requiredString.email('Not a valid email address'),
    password: requiredString,
  }),
})

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)
const Header = styled.div(apply('flex flex-row py-6 px-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const ButtonsContainer = styled.div(apply('flex', { marginLeft: 'auto' }))
const Scrollable = styled.div(
  apply('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)
const SubmitButton = styled(Button)(apply('my-6'))

const { createCustomer } = companyModule

/**
 * @param {object} user auth0 user
 * @example
 * // Auth0 stringifies the role object under a key
 * {
 *  'https://nearbysky.com/roles':
 *  '{"admin":false,"staff": {"5e7351910af6acca4509faa4":"organization_admin"}}',
 * }
 * @returns {string} organizationId
 */
const parseOrgId = (user) => {
  const role = JSON.parse(user?.[roleKey])
  return pipe(path(['staff']), keys, head)(role)
}

const ConnectedCreateCompany = connect(() => ({
  isCreatingCustomer: isCreatingCustomer$,
  pilotCategoriesList: pilotCategoriesList$,
}))(CreateCompany)

function CreateCompany({ isCreatingCustomer, pilotCategoriesList }) {
  const { user } = useIdentity()
  const history = useHistory()
  const organizationId = parseOrgId(user)

  useEffect(() => {
    pilotCategoryModule.fetchPilotCategories(null, { turnPage: false })
  })

  return (
    <Container>
      <Header>
        <Title>Invite Customer</Title>
        <ButtonsContainer>
          {isCreatingCustomer && (
            <Spinner size={32} thickness={6} style={{ margin: 'auto 32px' }} />
          )}
          <CancelButton
            onClick={() => history.goBack()}
            style={apply('ml-4')}
            type="button"
          >
            Cancel
          </CancelButton>
        </ButtonsContainer>
      </Header>
      <FormProvider
        id="createCompany"
        enableReinitialize
        initialValues={{
          organizationId,
          organizationDetails: {
            name: undefined,
            address: undefined,
            abn: undefined,
          },
          userDetails: {
            phoneNumber: undefined,
            email: undefined,
            firstName: undefined,
            lastName: undefined,
            password: 'Customerpass1!',
          },
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await createCustomer(null, values).then((customer) => {
            if (customer) {
              toast.success('Customer created successfully!')
              history.push({
                pathname: `/customer/${customer.id}`,
                state: { isNew: true },
              })
            }
          })
        }}
      >
        <Scrollable>
          <Form>
            <>
              <CreateCompanyForm
                isEditing={!isCreatingCustomer}
                pilotCategoriesList={pilotCategoriesList}
              />
              <KeyContactsSection isEditing={!isCreatingCustomer} />
              <SubmitButton disabled={isCreatingCustomer} type="submit">
                Submit
              </SubmitButton>
            </>
          </Form>
        </Scrollable>
      </FormProvider>
    </Container>
  )
}

export { ConnectedCreateCompany as CreateCompany }

import { useLocation } from 'react-router-dom'

import { Tabs } from 'components/common/Tabs'
import { CompanyLayout, FieldGroup } from 'components/company/common'
import { SUPPLIER_TYPES } from 'constants/supplier'
import { styled } from 'lib/styled'

const StyledTab = styled(Tabs)({
  width: '400px',
  '> a': {
    borderBottom: 'none',
  },
})

const TabsContainer = styled.div({
  marginBottom: '41px',
  borderBottom: '1px solid #CCCCCC',
})

const PilotCategoriesLayout = ({
  children,
  loading,
  type,
  company,
  status,
  isInternalEntity,
}) => {
  const { search } = useLocation()

  const tabs = [
    {
      title: 'Pilot Categories',
      path: `/company/ratecard/categories${search}`,
      id: 'categories',
    },
    {
      title: 'Equipment Rates',
      path: `/company/ratecard/equipment${search}`,
      id: 'equipment',
    },
  ]

  if (isInternalEntity)
    return (
      <CompanyLayout
        isAdmin
        isInternalEntity
        company={company}
        loading={loading}
        type={type}
        status={status}
      >
        <FieldGroup title="Company Rate Card">
          <TabsContainer>
            <StyledTab tabs={tabs} defaultSelectedId="categories" pathLength={4} />
          </TabsContainer>
          {children}
        </FieldGroup>
      </CompanyLayout>
    )

  if (type !== SUPPLIER_TYPES.PROFESSIONAL)
    return (
      <CompanyLayout company={company} loading={loading} type={type} status={status}>
        <FieldGroup title="Company Rate Card">
          <TabsContainer>
            <StyledTab tabs={tabs} defaultSelectedId="categories" pathLength={4} />
          </TabsContainer>
          {children}
        </FieldGroup>
      </CompanyLayout>
    )

  return children
}

export { PilotCategoriesLayout }

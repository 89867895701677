import { pickThenRename, noop } from '@seedcloud/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'

import { useJobs } from 'components/common/context/JobContext'
import { DateField } from 'components/common/DateField'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { LocationField } from 'components/common/LocationField'
import { SelectField } from 'components/common/SelectField'
import { Title } from 'components/common/Tabs/ui'
import { Toggle as ToggleBase } from 'components/common/Toggle'
import { AreaOfInterest } from 'components/jobs/common'
import { styled, apply } from 'lib/styled'

const Container = styled.section()

const Column = styled.div(apply('flex-1 flex flex-column'))
const Header = styled.div(apply('flex flex-row py-6'))
const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const Toggle = styled(ToggleBase)(apply('mr-2'))
const ToggleContainer = styled.div(apply('flex pr-8'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

function JobInfo({ minScheduledAtDate }) {
  const { productList, projectList, companyList, onCreateProject, onFilterProduct } =
    useJobs()

  const {
    setFieldValue,
    values: {
      jobInfo: { commercialSettings, address, radius },
    },
  } = useFormContext()

  const { billable, paid, dataUpload } = commercialSettings
  const coordinates = address?.geometry?.coordinates

  const productOptions = productList
    .filter((product) => product.isActive)
    .map(({ id, name: label }) => ({
      id,
      value: label,
      label,
    }))
  const projectOptions = projectList.map(({ id, reference }) => ({
    id,
    value: reference,
    label: reference,
  }))
  // eslint-disable-next-line no-unused-vars
  const companyOptions = companyList.map(
    ({ id, company: { companyName, userId } }) => ({
      id,
      value: companyName,
      label: companyName,
      userId,
    })
  )

  return (
    <Container>
      <Header>
        <Title id="info">Job Info</Title>
      </Header>
      <Column>
        <Label>Commercial settings</Label>
        <Row>
          <ToggleContainer>
            <Toggle
              onChange={(e) =>
                setFieldValue('jobInfo.commercialSettings.billable', e.target.checked)
              }
              checked={billable}
            />
            <Label>Billable</Label>
          </ToggleContainer>
          <ToggleContainer>
            <Toggle
              onChange={(e) =>
                setFieldValue('jobInfo.commercialSettings.paid', e.target.checked)
              }
              checked={paid}
            />
            <Label>Paid</Label>
          </ToggleContainer>
          <ToggleContainer>
            <Toggle
              onChange={(e) =>
                setFieldValue('jobInfo.commercialSettings.dataUpload', e.target.checked)
              }
              checked={dataUpload}
            />
            <Label>Data Upload</Label>
          </ToggleContainer>
        </Row>
        <Row>
          <SelectField
            name="jobInfo.product.name"
            label="Product Name"
            required
            onChange={(selectedOption) => {
              setFieldValue(
                'jobInfo.product',
                pickThenRename(['id', 'value'], { value: 'name' })(selectedOption)
              )
            }}
            options={productOptions}
            placeholder="Search and Select Product"
            onInputChange={onFilterProduct}
            containerProps={{ style: apply('mr-3') }}
            testId="job-product-name"
          />
          <DateField
            name="jobInfo.scheduledAt"
            label="Scheduled at"
            required
            autoOk
            disablePast
            inputDisabled
            minDate={minScheduledAtDate}
            onError={noop}
            testId="job-scheduled-at"
          />
        </Row>
        <Row>
          <SelectField
            name="jobInfo.template"
            label="Job Template Type"
            onChange={(selectedOption) => {
              setFieldValue('jobInfo.template', selectedOption.value)
            }}
            options={[{ id: 'default', value: 'default', label: 'Default' }]}
            placeholder="Select a template type"
            containerProps={{ style: apply('mr-3') }}
            testId="job-template-type"
          />
          <SelectField
            name="jobInfo.project.reference"
            label="Project ID"
            creatable
            emptyOption={{ id: undefined, value: undefined }}
            onChange={({ id, value: reference }, { action }) => {
              if (action === 'create-option') {
                onCreateProject(reference)
              }
              setFieldValue('jobInfo.project', { id, reference })
            }}
            options={projectOptions}
            testId="job-project"
          />
        </Row>
        {/* TODO: Uncomment, when customer using dropdown is used */}
        {/* <Row>
          <SelectField
            name="jobInfo.company.companyName"
            label="Company"
            onChange={(selectedOption) => {
              setFieldValue(
                'jobInfo.company',
                pickThenRename(['id', 'value', 'userId'], {
                  value: 'companyName',
                })(selectedOption)
              )
            }}
            options={companyOptions}
            placeholder="Select customer"
            containerProps={{ style: apply('mr-3') }}
            testId="job-company"
          />
        </Row>
        <Row>
          <FormInput
            name="jobInfo.company.userId.email"
            label="Customer Email"
            testId="job-company-email"
            disabled
          />
          <FormInput
            name="jobInfo.company.userId.phoneNumber"
            label="Customer Phone Number"
            testId="job-company-phone-number"
            disabled
          />
        </Row> */}
        <Row>
          <FormInput
            name="jobInfo.customer.companyName"
            label="Customer Name"
            testId="job-customer-name"
          />
        </Row>
        <Row>
          <FormInput
            name="jobInfo.customer.companyEmail"
            label="Customer Email"
            testId="job-customer-email"
          />
          <FormInput
            name="jobInfo.customer.companyNumber"
            label="Customer Number"
            testId="job-customer-phone"
          />
        </Row>
        <Row>
          <LocationField
            name="jobInfo.address"
            label="Location"
            required
            testId="job-location"
            containerProps={{ style: apply('mr-3', { flexGrow: 6 }) }}
          />
          <FormInput
            name="jobInfo.radius"
            label="Radius in meters"
            required
            testId="job-radius"
          />
        </Row>

        <AreaOfInterest coordinates={coordinates} radius={radius} />
      </Column>
    </Container>
  )
}

export { JobInfo }

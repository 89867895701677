import { pilotCategoryAdminModule } from './admin/module'
import { pilotCategoriesAdminSelctors } from './admin/selectors'
import { PilotCategoryAdminService } from './admin/service'
import { pilotCategoryModule } from './web/module'
import { pilotCategoriesSelectors } from './web/selectors'
import { PilotCategoryService } from './web/service'

import { API_TYPES } from 'lib/http-client'
import { createModule } from 'utils/createModule'

export const createPilotCategoriesModule = (type = API_TYPES.ADMIN) => {
  if (type === API_TYPES.ADMIN) {
    return createModule(
      pilotCategoryAdminModule,
      pilotCategoriesAdminSelctors,
      PilotCategoryAdminService
    )
  }

  return createModule(
    pilotCategoryModule,
    pilotCategoriesSelectors,
    PilotCategoryService
  )
}

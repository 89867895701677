import { Button, CancelButton } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const FormActionWrapper = styled.div(apply('p-6 flex items-stretch'), {
  borderTop: '1px solid #CCCCCC',
  '& > button': {
    width: '100%',
  },
})

const FormAction = ({
  onCancel,
  cancelText = 'Cancel',
  okText = 'Create',
  onOK,
  loading,
  loadingText = 'Creating...',
}) => (
  <FormActionWrapper>
    <CancelButton
      type="reset"
      onClick={onCancel}
      disabled={loading}
      style={{ marginRight: '24px' }}
    >
      {cancelText}
    </CancelButton>
    <Button type="submit" onClick={onOK} disabled={loading}>
      {loading ? loadingText : okText}
    </Button>
  </FormActionWrapper>
)

export { FormAction }

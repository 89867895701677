import { ConnectedAviationDetailAdmin } from './AviationDetailAdminContainer'
import { ConnectedAviationDetail } from './AviationDetailContainer'

import { useIdentity } from 'lib/solta-id-react'

const AviationDetailGateway = () => {
  const { isAdmin } = useIdentity()
  return isAdmin ? <ConnectedAviationDetailAdmin /> : <ConnectedAviationDetail />
}

export { AviationDetailGateway as AviationDetail }

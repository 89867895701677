import { connect } from '@seedcloud/stateless'
import { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'

import { FieldGroup } from 'components/company/common'
import { SupplierCategoriesLayout } from 'components/supplier/SupplierCategories/common/SupplierCategoriesLayout'
import { SupplierTable } from 'components/supplier/SupplierCategories/SupplierTable'
import { createCompanyModule } from 'modules/company'
import { createPilotCategoriesModule } from 'modules/pilotCategory'
import {
  companyName$,
  invitationStatus$,
  supplierModule,
  supplierType$,
} from 'modules/supplier'

const {
  module: companyModule,
  selectors: { isInspectingCompany$, currency$ },
} = createCompanyModule()

const {
  module: pilotCategoryModule,
  selectors: {
    isFetchingPilotCategories$,
    pilotCategoriesFilters$,
    pilotCategoriesList$,
  },
} = createPilotCategoriesModule()

const SupplierCategories = ({
  pilotCategoriesList,
  isFetchingPilotCategories,
  isInspectingCompany,
  currency,
  pilotCategoriesFilters,
  type,
  status,
  companyName,
}) => {
  const { id } = useParams()

  useLayoutEffect(() => {
    if (!type) {
      supplierModule.inspectSupplier(id)
    }
  }, [])

  useLayoutEffect(() => {
    const getPilotCategories = async () => {
      await pilotCategoryModule.resetPagination()
      await pilotCategoryModule.fetchPilotCategories(null, {
        turnPage: false,
        type,
        supplierId: id,
      })
    }
    if (type) {
      getPilotCategories()
    }
    companyModule.inspectCompany()
  }, [type])

  const {
    paging: { startRow, next },
    limit,
  } = pilotCategoriesFilters

  return (
    <SupplierCategoriesLayout
      company={companyName}
      status={status}
      type={type}
      loading={isFetchingPilotCategories || isInspectingCompany}
    >
      <FieldGroup title="Company Rate Card">
        <SupplierTable
          currency={currency}
          values={pilotCategoriesList}
          limit={limit}
          next={next}
          startRow={startRow}
          type={type}
          id={id}
        />
      </FieldGroup>
    </SupplierCategoriesLayout>
  )
}

const ConnectedSupplierCategories = connect(() => ({
  pilotCategoriesList: pilotCategoriesList$,
  isFetchingPilotCategories: isFetchingPilotCategories$,
  isInspectingCompany: isInspectingCompany$,
  currency: currency$,
  type: supplierType$,
  status: invitationStatus$,
  companyName: companyName$,
  pilotCategoriesFilters: pilotCategoriesFilters$,
}))(SupplierCategories)

export { ConnectedSupplierCategories as SupplierCategories }

import { map, prop, pathOr, pick } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { pilotCategoryModule } from './module'

const { state$, effectState$ } = pilotCategoryModule

const pilotCategoriesEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))

const pilotCategoriesList$ = select(
  order$,
  pilotCategoriesEntities$,
  (order, entities) => map((id) => entities?.[id], order)
)

const pilotCategoriesFilters$ = select(state$, pick(['paging', 'limit']))

const fetchPilotCategoriesStatus$ = select(effectState$, (effectState) =>
  pathOr('idle', ['fetchPilotCategories', 'status'], effectState)
)

const isFetchingPilotCategories$ = select(
  fetchPilotCategoriesStatus$,
  (status) => status === 'pending'
)

const isSubmitting$ = select(state$, prop('isSubmitting'))

export const pilotCategoriesSelectors = {
  pilotCategoriesList$,
  isFetchingPilotCategories$,
  pilotCategoriesEntities$,
  isSubmitting$,
  pilotCategoriesFilters$,
}

/* eslint-disable complexity */
import CONSTANTS from '@seedcloud/constants'
import { connect } from '@seedcloud/stateless'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Validator from 'yup'

import { PilotDetails } from '../common/PilotDetails'

import { roleKey } from 'constants/roles'
import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import toaster from 'lib/toaster'
import { createCompanyModule } from 'modules/company'
import { createPilotModule } from 'modules/pilot'
import { createPilotCategoriesModule } from 'modules/pilotCategory'

const {
  module: pilotModule,
  selectors: {
    inspectedPilot$,
    isInspectingPilot$,
    isUpdatingIndividualPilot$,
    isUploadingAvatar$,
  },
} = createPilotModule(API_TYPES.WEB)

const {
  module: companyModule,
  selectors: { inspectedCompany$ },
} = createCompanyModule(API_TYPES.WEB)

const {
  module: pilotCategoryModule,
  selectors: { pilotCategoriesList$ },
} = createPilotCategoriesModule(API_TYPES.WEB)

const { object, string } = Validator
const requiredString = (label) => string().required().label(label)

const validationSchema = object({
  userDetails: object({
    firstName: requiredString('First name'),
    lastName: requiredString('Last name'),
    phoneNumber: requiredString('Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Not a valid phone number'
    ),
    pilotCategory: requiredString('Pilot category').matches(
      CONSTANTS.REGEX.MONGO_OBJECT_ID
    ),
  }),
})

function useParamsOrRegisteredPilotID(user, id) {
  const pilotId = JSON.parse(user?.[roleKey]).pilot
  if (!id && pilotId) {
    return pilotId
  }
  return id
}

const ConnectedPilotDetails = connect(() => ({
  isInspectingPilot: isInspectingPilot$,
  inspectedPilot: inspectedPilot$,
  pilotCategoriesList: pilotCategoriesList$,
  companyDetail: inspectedCompany$,
  isUpdatingIndividualPilot: isUpdatingIndividualPilot$,
  isUploadingAvatar: isUploadingAvatar$,
}))(PilotDetailsContainer)

function PilotDetailsContainer({
  inspectedPilot = {},
  companyDetail = {},
  pilotCategoriesList,
  isUpdatingIndividualPilot,
  isUploadingAvatar,
}) {
  const { user } = useIdentity()
  const { id: paramsId } = useParams()
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)

  const id = useParamsOrRegisteredPilotID(user, paramsId)

  useEffect(() => {
    const fetchPilotAndSetDefaultCompany = async () => {
      setLoading(true)
      pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
      await Promise.all([
        companyModule.inspectCompany(),
        pilotCategoryModule.fetchPilotCategories(null, {
          turnPage: false,
        }),
        pilotModule.inspectPilot(id),
      ])
      setLoading(false)
    }
    fetchPilotAndSetDefaultCompany()
    return () => {
      pilotCategoryModule.resetPagination()
    }
  }, [id])

  const { userDetails = {}, pilotCategory, userId } = inspectedPilot
  const { name: companyName } = companyDetail
  const { firstName, lastName, phoneNumber, avatar } = userDetails

  const initialValues = {
    userDetails: {
      firstName,
      lastName,
      phoneNumber,
      email: paramsId ? userDetails?.email : user.email,
      avatar,
      pilotCategory: pilotCategory?.id,
      pilotCategoryName: pilotCategory
        ? `${pilotCategory?.description} A$${pilotCategory?.rate} / hr`
        : '',
      companyName,
    },
  }

  const pilotDetailsProps = {
    initialValues,
    validationSchema,
    editing,
    setEditing,
    toaster,
    id,
    userId,
    paramsId,
    avatar,
    pilotCategoriesList,
    isUpdatingIndividualPilot,
    pilotModule,
    isUploadingAvatar,
    loading,
    onSubmit: async (values) => {
      await pilotModule.updateIndividualPilot(id, values).then(() => {
        toaster.success('Update Success!')
      })
      setEditing(false)
    },
  }

  return <PilotDetails {...pilotDetailsProps} />
}

const PaddedContainer = styled.div(apply('flex-1 flex flex-row p-6'))

function PilotDetailsWithContainer() {
  return (
    <PaddedContainer>
      <ConnectedPilotDetails />
    </PaddedContainer>
  )
}

export { ConnectedPilotDetails as PilotDetails, PilotDetailsWithContainer }

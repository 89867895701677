import Modal from 'react-modal'

import { Button, CancelButton as CancelButtonBase } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('text-black bg-white rounded-lg'), {
  padding: '48px 64px',
  width: '656px',
  maxWidth: '656px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '32px',
})

const Title = styled.h1({
  fontWeight: 700,
  fontSize: '2.25rem',
  textAlign: 'center',
})

const Subtitle = styled.p({
  margin: 0,
  fontSize: '.875rem',
  textAlign: 'center',
})

const Space = styled.div(apply('flex'), {
  gap: '1rem',
})

const ActionButton = styled(Button)(apply('flex-1 mt-5'), {
  marginTop: '1rem',
})

const CancelButton = styled(CancelButtonBase)(apply('flex-1 mt-5'), {
  background: 'transparent',
  boxShadow: 'none',
  marginTop: '1rem',
})

Modal.setAppElement('#root')

function ConfirmModal({
  isOpen,
  closeModal,
  title,
  subtitle,
  onConfirm,
  loading,
  confirmText = 'Confirm',
  confirmLoadingText = 'Confriming...',
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      id="unique"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(20, 20, 20, 0.2)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Space>
          <CancelButton disabled={loading} onClick={() => closeModal()}>
            Cancel
          </CancelButton>
          <ActionButton disabled={loading} onClick={() => onConfirm()}>
            {loading ? confirmLoadingText : confirmText}
          </ActionButton>
        </Space>
      </Root>
    </Modal>
  )
}

export { ConfirmModal }

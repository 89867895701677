import { Field, useField } from 'formik'
import { useState } from 'react'

import { Button } from 'components/common/Button'
import { FileIcon, LinkIcon } from 'components/icons'
import {
  COMPANY_DOCUMENT_TYPES,
  COMPANY_DOCUMENT_TYPES_DISPLAY_NAMES,
} from 'constants/documentTypes'
import { styled, apply } from 'lib/styled'

const FileFieldContainer = styled.div(({ editing, isFile }) => ({
  position: 'relative',
  width: '100%',
  fontSize: '1.0625rem',
  padding: '0.5rem 1rem',
  borderRadius: '.5em',
  color: '#000000',
  fontWeight: 400,
  lineHeight: '20.4px',
  background: editing && !isFile ? 'transparent' : '#EEF1F5',
  border: editing ? '1px solid #CCCCCC' : 'none',
  display: 'flex',
  alignItems: isFile ? 'flex-start' : 'center',
  gap: '1em',
  minHeight: '40px',
}))

const FileFieldContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  position: 'relative',
  overflow: 'hidden',
})

const FileFieldExtra = styled.div({
  position: 'absolute',
  top: 4,
  right: 8,
})

const ErrorMessage = styled.div(apply('flex-1 text-red text-xs'))

const ActionButton = styled(Button)({
  background: 'transparent',
  border: 'none',
  boxShadow: 'none',
  padding: 0,
  textTransform: 'none',
  textDecoration: 'underline',
  color: '#262626',
})

const FileFieldTitle = styled(Field)({
  all: 'unset',
  marginBottom: 0,
  fontSize: '0.9375rem',
})

const EmptyFileFieldTitle = styled.div({
  all: 'unset',
  marginBottom: 0,
  fontSize: '0.9375rem',
})

const FileURL = styled.p({
  cursor: 'pointer',
  fontSize: '0.6875rem',
  color: '#00326F',
  textDecoration: 'underline',
  lineHeight: '0,8125rem',
  marginBottom: 0,
})

const FileURLLink = styled.a({
  cursor: 'pointer',
  fontSize: '0.6875rem',
  color: '#00326F',
  textDecoration: 'underline',
  lineHeight: '0,8125rem',
  marginBottom: 0,
})

const FileTypeContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '0.5em',
})

const FileTypeLabel = styled.label({
  fontSize: '0.89rem',
  fontWeight: 400,
  color: '#000',
  lineHeight: 'normal',
})

const FileFieldType = styled.input(({ value }) => ({
  appearance: 'none',
  border: '1px solid #ccc',
  borderRadius: '4px',
  display: 'inline-block',
  width: '40px',
  height: '20px',
  textAlign: 'center',
  lineHeight: '20px',
  cursor: 'pointer',
  backgroundColor: '#fff',
  color: '#333',
  fontSize: '12px',
  fontWeight: 400,
  marginLeft: '6px',
  '&:checked': {
    backgroundColor: '#fff',
    color: '#0078d4',
    borderColor: '#0078d4',
    fontWeight: 'bold',
  },
  '&:before': {
    content: `"${COMPANY_DOCUMENT_TYPES_DISPLAY_NAMES[value]}"`,
  },
  '&:first-of-type': {
    marginLeft: '8px',
  },
}))

const FileTypeRadioGroup = ({ docId, name, initialValue, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value, docId)
  }

  return (
    <>
      <FileFieldType
        id={name}
        name={name}
        type="radio"
        value={COMPANY_DOCUMENT_TYPES.TNC}
        checked={initialValue === COMPANY_DOCUMENT_TYPES.TNC}
        onChange={handleChange}
        docId={docId}
      />
      <FileFieldType
        id={name}
        name={name}
        type="radio"
        value={COMPANY_DOCUMENT_TYPES.OTHER}
        checked={initialValue === COMPANY_DOCUMENT_TYPES.OTHER}
        onChange={handleChange}
        docId={docId}
      />
    </>
  )
}

// eslint-disable-next-line complexity
const FileItem = ({
  id,
  name,
  url,
  urlText,
  editing,
  itemType = 'file',
  handleClick,
  type,
  docId,
  handleTypeChange,
}) => {
  const [renaming, setRenaming] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [_, { error }] = useField({ name })

  const handleRenaming = () => {
    if (!renaming) {
      document.getElementById(name).focus()
    }

    setRenaming((renaming) => !renaming)
  }

  return (
    <FileFieldContainer editing={editing} isFile={itemType === 'file'}>
      {itemType !== 'link' ? <FileIcon /> : <LinkIcon />}
      <FileFieldContent>
        <FileFieldTitle
          id={`${name}`}
          name={`${name}`}
          readOnly={!editing && !renaming}
        />
        <ErrorMessage>{error}</ErrorMessage>
        {itemType === 'file' ? (
          <>
            <FileURL onClick={() => handleClick(id)}>{urlText || url}</FileURL>
            <FileTypeContainer>
              <FileTypeLabel>
                Type:
                {editing ? (
                  <FileTypeRadioGroup
                    docId={docId}
                    name={`${name}.type`}
                    initialValue={type}
                    onChange={handleTypeChange}
                  />
                ) : (
                  <span> {COMPANY_DOCUMENT_TYPES_DISPLAY_NAMES[type]}</span>
                )}
              </FileTypeLabel>
            </FileTypeContainer>
          </>
        ) : (
          <FileURLLink target="_blank" href={url}>
            {urlText || url}
          </FileURLLink>
        )}
      </FileFieldContent>
      {editing ? (
        <FileFieldExtra>
          <ActionButton type="button" onClick={handleRenaming}>
            {renaming ? 'Save' : 'Rename'}
          </ActionButton>
        </FileFieldExtra>
      ) : null}
    </FileFieldContainer>
  )
}

const EmptyFileItem = ({ type }) => (
  <FileFieldContainer>
    <FileFieldContent>
      <EmptyFileFieldTitle>
        No {type === 'file' ? 'Documents' : 'Links'}
      </EmptyFileFieldTitle>
    </FileFieldContent>
  </FileFieldContainer>
)

export { FileItem, EmptyFileItem }

import { Link as LinkBase } from 'react-router-dom'

import { styled, apply } from 'lib/styled'

export const TabItem = styled(LinkBase)(
  apply('text-center py-4 px-6 font-black relative'),
  ({ selected, theme, block }) => ({
    width: block ? '100%' : 'auto',
    color: selected ? `${theme.colors.primary}` : '#AAAAAA',
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.colors.primary}`,
      textDecoration: 'none',
    },
    '&::after': {
      content: selected ? '""' : 'unset',
      position: 'absolute',
      bottom: '0',
      left: '0',
      height: '4px',
      backgroundColor: `${theme.colors.primary}`,
      borderRadius: '.25em .25em 0 0',
      width: '100%',
    },
  })
)

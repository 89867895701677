import { Switch, Route } from 'react-router-dom'

import { CompanyDetails } from './CompanyDetails'
import { CompanyList } from './CompanyList'
import { CreateCompany } from './CreateCompany'
import { CustomerEmptyState } from './CustomerEmptyState'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { ProtectedRoute } from 'components/common/ProtectedRoute'
import { RESOURCES } from 'constants/resources'
import { usePermissions } from 'lib/permissions'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

// eslint-disable-next-line react/display-name
const withCompanyList = (Component) => (props) =>
  (
    <>
      <CompanyList />
      <Component {...props} />
    </>
  )

function CompanyManager() {
  const permissions = usePermissions()
  const canCreateCompany = permissions.can('create', RESOURCES.CUSTOMER)

  return (
    <Container>
      <Switch>
        <Route
          exact
          path={['/', '/customer']}
          component={withCompanyList(CustomerEmptyState)}
        />
        <ProtectedRoute
          exact
          path="/customer/create"
          render={withCompanyList(CreateCompany)}
          allowed={canCreateCompany}
        />
        <Route
          exact
          path="/customer/:companyId"
          render={withCompanyList(CompanyDetails)}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { CompanyManager }

/**
 * We normalize the tree to this structure
 *
 * The first children is guaranteed to be the job id key.
 * All files that are directly placed here will be placed as the "Unasigned Folder"
 * foldersOrder = [
 *   { id: ':jobId', children: [] },
 *   { id: 'id2', children: [{ id: 'id3' }] },
 * ]
 *
 * folderEntities = {
 *   id1: {
 *     name: 'Folder A',
 *     files: ['filesId1', 'filesId2', 'filesId3'],
 *   },
 * }
 *
 * fileEntities = {
 *  "filedId1": {
 *    name: "ABCD_name.pdf",
 *    kind: "file",
 *    fullPath: "bucketFolder/ABCD_name.pdf",
 *    ...rest
 *  }
 * }
 */

function normalizeFileTree(data, id) {
  if (!data.children.length)
    return {
      folderOrders: [{ id, children: [] }],
      folderEntities: {
        [id]: {
          id,
          fullPath: id,
          name: id,
          files: [],
        },
      },
      fileEntities: {},
    }

  const {
    children: [unassignedFolder],
  } = data

  const unasignedFolderFiles = unassignedFolder.children?.filter(
    (file) => file.kind === 'file'
  )

  const folderEntities = {
    [unassignedFolder.name]: {
      id: unassignedFolder.name,
      name: unassignedFolder.name,
      fullPath: unassignedFolder.fullPath,
      files: unasignedFolderFiles?.map((file) => file.id),
    },
  }

  const fileEntities = {}

  unasignedFolderFiles.forEach((file) => {
    fileEntities[file.id] = file
  })

  const traverse = (tree) => {
    if (!tree.children) return {}
    return tree.children.reduce((acc, curr) => {
      const { name, kind, fullPath } = curr
      if (kind === 'folder') {
        const files = curr.children?.filter((file) => file.kind === 'file')
        folderEntities[name] = {
          id: name,
          name,
          fullPath,
          files: files?.map((file) => file.id),
        }

        acc.push({
          id: name,
          ...(curr.children && { children: traverse(curr) }),
        })
      }
      if (kind === 'file') {
        fileEntities[curr.id] = curr
      }
      return acc
    }, [])
  }

  const folderOrders = [
    { id: unassignedFolder.name, children: [] },
    ...traverse(unassignedFolder),
  ]

  return {
    folderOrders,
    folderEntities,
    fileEntities,
  }
}

export { normalizeFileTree }

import { ConnectedInvitePilotAdmin } from './InvitePilotAdminContainer'
import { ConnectedInvitePilot } from './InvitePilotContainer'

import { useIdentity } from 'lib/solta-id-react'

const InvitePilotGateway = () => {
  const { isAdmin } = useIdentity()
  return isAdmin ? <ConnectedInvitePilotAdmin /> : <ConnectedInvitePilot />
}

export { InvitePilotGateway as InvitePilot }

import { useField } from 'formik'
import { useState } from 'react'

import { FolderNamingModal } from './FolderNamingModal'

import { ReactComponent as AddFolderIcon } from 'assets/add_folder.svg'
import { ReactComponent as FolderIcon } from 'assets/folder_filled.svg'
import { ReactComponent as FolderExpandedIcon } from 'assets/folder_open_filled.svg'
// eslint-disable-next-line no-unused-vars
import { ReactComponent as EditIcon } from 'assets/pencil.svg'
import { useJobs } from 'components/common/context/JobContext'
import { apply, styled } from 'lib/styled'

const FolderName = styled.div(apply('flex ml-3 flex-1 text-base'))

const btnStyle = {
  cursor: 'pointer',
  transition: 'opacity 150ms ease-in-out',
  '&:hover': {
    opacity: 0.4,
  },
}

// eslint-disable-next-line no-unused-vars
const ActionBtn = styled.i(btnStyle, ({ disabled }) => ({
  display: 'none',
  pointerEvents: disabled && 'none',
  color: disabled ? 'gray !important' : '',
}))

const FolderWrapper = styled.div(
  apply('flex flex-row items-center mb-2 rounded-lg'),
  ({ active }) => ({
    padding: ' .5rem .5rem',
    background: active ? '#CCD6E2' : 'transparent',
    maxHeight: '46px',
    transition: 'background 150ms ease-in-out',
    '&:hover': {
      background: '#F4F6F8',
      '& .action': {
        display: 'block',
      },
      '& span': {
        // TODO: Uncomment when move file integration
        // display: 'none',
      },
    },
  })
)

const FolderPanelWrapper = styled.div(apply('h-100'))

const FolderItem = ({ onClick, name, fileCount, type, activeFolder }) => (
  <FolderWrapper role="button" active={activeFolder} onClick={onClick}>
    {activeFolder ? <FolderExpandedIcon /> : <FolderIcon />}
    <FolderName>{type === 'unasigned' ? 'Unassigned assets' : name}</FolderName>
    {activeFolder ? <span>{fileCount}</span> : null}
    {/* TODO: Uncomment when move file integration */}
    {/* {type !== 'unasigned' ? (
      <ActionBtn className="action">
        <EditIcon style={{ color: '#00326F', width: '16', height: '16' }} />
      </ActionBtn>
    ) : null} */}
  </FolderWrapper>
)

const AddFolder = ({ handleCreateFolder }) => (
  <FolderWrapper role="button" onClick={handleCreateFolder}>
    <AddFolderIcon />
    <FolderName>Add folder</FolderName>
  </FolderWrapper>
)

function FolderPanel() {
  const {
    jobFolderList,
    inspectedFolder = {},
    onInspectFolder,
    onCreateFolder,
  } = useJobs()

  const [{ value: documents }] = useField({ name: 'documents.uploadedByPilot' })

  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <FolderPanelWrapper>
        {jobFolderList?.map(({ name, id }, i) => (
          <FolderItem
            key={id}
            id={id}
            name={name}
            onClick={() => onInspectFolder(id)}
            fileCount={documents.length}
            activeFolder={inspectedFolder.id === id}
            type={i === 0 ? 'unasigned' : ''}
          />
        ))}
        <AddFolder handleCreateFolder={() => setOpen(true)} />
      </FolderPanelWrapper>
      <FolderNamingModal
        title="Add New Folder"
        isOpen={isOpen}
        closeModal={() => setOpen(false)}
        onConfirm={(folderName) => {
          onCreateFolder(inspectedFolder.id, {
            folderName,
          })
        }}
      />
    </>
  )
}

export { FolderPanel }

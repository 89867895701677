import { prop, pathOr, path, find, propEq, isNilOrEmpty } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { COMPANY_DOCUMENT_TYPES } from 'constants/documentTypes'

function organizationSelectors(organizationModule) {
  const { state$, effectState$ } = organizationModule

  const organizationEntities$ = select(state$, prop('entities'))
  const inspectedEntity$ = select(state$, prop('inspectedEntity'))

  const inspectedOrganization$ = select(
    inspectedEntity$,
    organizationEntities$,
    (id, entities) => prop(id, entities)
  )

  const inspectOrganizationStatus$ = select(effectState$, (effectState) =>
    pathOr('idle', ['inspectOrganization', 'status'], effectState)
  )

  const isInspectingOrganization$ = select(
    inspectOrganizationStatus$,
    (status) => status === 'pending'
  )

  const organizationTermsAndCondition$ = select(
    inspectedOrganization$,
    (inspectedOrganization) => {
      const documents = path(['documents'], inspectedOrganization)

      if (isNilOrEmpty(documents)) return undefined

      return find(propEq('type', COMPANY_DOCUMENT_TYPES.TNC), documents)
    }
  )

  return {
    organizationEntities$,
    inspectedOrganization$,
    inspectOrganizationStatus$,
    isInspectingOrganization$,
    organizationTermsAndCondition$,
  }
}

export { organizationSelectors }

const MAX_CHUNKS = 10_000
const DEFAULT_CHUNK_SIZE = 20 * 1024 * 1024

export const calculateChunkSize = (fileSize, chunkSize = DEFAULT_CHUNK_SIZE) => {
  const partNo = Math.round(Number(fileSize) / chunkSize)
  if (partNo < MAX_CHUNKS) {
    return chunkSize
  }
  return calculateChunkSize(fileSize, chunkSize + 20)
}

export const splitToChunks = (file) => {
  const { size, name } = file
  const chunkSize = calculateChunkSize(size)

  const chunks = []

  for (let i = 0; i < size; i += chunkSize) {
    chunks.push(file.slice(i, Math.min(i + chunkSize, size)))
  }

  return {
    chunks,
    partsCount: chunks.length,
    filename: name,
  }
}

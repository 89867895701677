import { prepend } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useLayoutEffect, useMemo } from 'react'

import { useFilter } from './FilterBar'

import { SelectField } from 'components/common/SelectField'
import { projectModule, projectList$ } from 'modules/project'

const Project = ({ projectList }) => {
  const { onSubmit } = useFilter()

  const handleProjectChange = (project) => onSubmit({ project: project?.id })

  const projectOptions = useMemo(
    () =>
      prepend(
        { value: undefined, label: 'Any Projects' },
        projectList.map(({ id, reference }) => ({
          id,
          value: id,
          label: reference,
        }))
      ),
    [projectList]
  )

  useLayoutEffect(() => {
    projectModule.fetchProjects(null)
  }, [])

  return (
    <SelectField
      name="project"
      type="select"
      placeholder="e.g. ABC123"
      optional
      emptyOption={{ value: undefined }}
      onChange={handleProjectChange}
      options={projectOptions}
      selectStyles={{ valueContainer: { height: '46px' } }}
      size="lg"
      newStyle
    />
  )
}

const ConnectedProject = connect(() => ({
  projectList: projectList$,
}))(Project)

export { ConnectedProject as Project }

import { pilotAdminModule } from './admin/module'
import { pilotAdminSelectors } from './admin/selectors'
import { PilotAdminService } from './admin/service'
import { pilotWebModule } from './web/module'
import { pilotWebSelectors } from './web/selectors'
import { PilotWebService } from './web/service'

import { API_TYPES } from 'lib/http-client'
import { createModule } from 'utils/createModule'

export * from './constants'

export const createPilotModule = (type = API_TYPES.ADMIN) => {
  if (type === API_TYPES.ADMIN) {
    return createModule(pilotAdminModule, pilotAdminSelectors, PilotAdminService)
  }

  return createModule(pilotWebModule, pilotWebSelectors, PilotWebService)
}

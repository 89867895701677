import { Link } from 'react-router-dom'

import CloudLayoutImage from 'assets/clouds_container.svg'
import { ReactComponent as UserCircle } from 'assets/user_circle.svg'
import { Button } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Layout = styled.div(
  apply('flex flex-1 flex-column bg-white rounded-xl shadow-sm relative'),
  {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundImage: `url(${CloudLayoutImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    '& > p:not(:last-of-type)': {
      marginBottom: 0,
    },
  }
)

const Title = styled.h1({
  fontWeight: '800',
  fontSize: '1.625rem',
  marginBottom: '24px',
})

const LinkStyled = styled(Link)({
  fontWeight: 'bold',
  color: '#00326F',
})

const SupplierEmptyState = ({ isEmpty }) => (
  <Layout>
    <UserCircle />
    <Title>{isEmpty ? 'No Supplier Found' : 'Your Suppliers'}</Title>
    <p>
      {isEmpty
        ? 'Start by adding your supplier.'
        : 'Please navigate to your supplier account'}
    </p>
    <p>
      {isEmpty ? (
        <>
          Click <LinkStyled to="supplier/new">Add Supplier</LinkStyled> to invite your
          new supplier.
        </>
      ) : (
        <>
          or click <LinkStyled to="supplier/new">Add Supplier</LinkStyled> to invite
          your new supplier.
        </>
      )}
    </p>
    <Link to="supplier/new">
      <Button>Add Supplier</Button>
    </Link>
  </Layout>
)

export { SupplierEmptyState }

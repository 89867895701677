export const RESOURCES = {
  PILOT_APPLICATION: 'PILOT_APPLICATION',
  PILOT_DETAILS: 'PILOT_DETAILS',
  JOB: 'JOB',
  MY_PILOT_INFO: 'MY_PILOT_INFO',
  MY_PROFILE: 'MY_PROFILE',
  MY_COMPANY: 'MY_COMPANY',
  PRODUCT: 'PRODUCT',
  FILE: 'FILE',
  STAFF: 'STAFF',
  SUPPLIER: 'SUPPLIER',
  CUSTOMER: 'CUSTOMER',
  CLAIM_COMPANY: 'CLAIM_COMPANY',
  SUBSCRIPTION_PLANS: 'SUBSCRIPTION_PLANS',
}

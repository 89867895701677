import { Formik as FormProvider, Form } from 'formik'
import * as Validator from 'yup'

import { Button as ButtonBase } from 'components/common/Button'
import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { Row, TextField } from 'components/company/common'
import { NearbyskyLogoIcon } from 'components/icons/NearbyskyLogoIcon'
import { CONFIG } from 'config'
import { styled, apply } from 'lib/styled'
import toast from 'lib/toaster'

const Background = styled.div(
  apply('flex flex-column justify-center items-center', {
    backgroundColor: '#EDF2F7',
  })
)
const Container = styled.div(
  apply('flex flex-column justify-center items-center px-10 py-16', {
    backgroundColor: '#FFFFFF',
    margin: '155px auto',
    width: '386px',
    borderRadius: '16px',
  })
)
const Title = styled.h2(apply('my-8 text-2xl font-bold'))
const Description = styled.p(
  apply('mb-2 text-gray-600 w-full', {
    color: '#595959',
  })
)

const Scrollable = styled.div(
  apply('flex flex-column', {
    minHeight: 'min-content',
  })
)

const { string, object } = Validator

const validationSchema = object({
  email: string().email('Invalid email').required('Email is required'),
})

const initialValues = {
  email: '',
}

const FormContainer = styled.div({
  width: '100%',
})

const SubmitButton = styled(ButtonBase)(
  apply('flex justify-center p-2 items-center', {
    fontSize: '15px',
    fontWeight: '500',
    boxSizing: 'content-box',
    borderRadius: '8px',
  })
)

function ResetPassword() {
  const handleSubmit = (values, { setSubmitting }) => {
    try {
      fetch(`https://${CONFIG.IDENTITY.DOMAIN}/dbconnections/change_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: values.email,
          connection: CONFIG.IDENTITY.CONNECTION,
        }),
      }).then((res) => {
        if (res.ok) {
          toast.success('Check your email to reset your password')
        } else {
          res.json().then((data) => {
            toast.error(`Error: ${data.error}`)
          })
        }
        setSubmitting(false)
      })
    } catch (error) {
      toast.error(error.message)
      setSubmitting(false)
    }
  }

  return (
    <Background>
      <Container>
        <NearbyskyLogoIcon width="80px" />
        <Title>Forgot Password</Title>
        <Description>
          Enter your email and we’ll send you a link to reset your password.{' '}
        </Description>
        <FormContainer>
          <FormLayoutProvider value={{ editing: true }}>
            <FormProvider
              id="resetPassword"
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  <Scrollable>
                    <Row>
                      <TextField
                        value={values.email}
                        name="email"
                        type="text"
                        placeholder="Enter Email"
                        required
                      />
                    </Row>
                    <SubmitButton type="submit" disabled={isSubmitting}>
                      Send link to email
                    </SubmitButton>
                  </Scrollable>
                </Form>
              )}
            </FormProvider>
          </FormLayoutProvider>
        </FormContainer>
      </Container>
    </Background>
  )
}

export { ResetPassword }

import { isFunction, isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { Field } from 'formik'

import CardBg from 'assets/card_bg.svg'
import { apply, styled } from 'lib/styled'

const Row = styled.div(
  apply('flex flex-row my-2 items-center', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)

const StyledComplianceCard = styled.div(apply('rounded-lg w-100'), {
  padding: '16px 14px',
  backgroundColor: '#EEF1F5',
  backgroundImage: `url('${CardBg}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top right',
  backgroundSize: '20%',
})

const Label = styled.label(apply('mb-2 tracking-wide text-sm text-black'), {
  '& .tnc-link': {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 500,
    color: 'black',
  },
})

const ComplianceTitle = styled.p(apply('text-lg font-bold text-black mb-2'))

const CheckBoxInput = styled.input(apply('mr-4'))
const CheckBoxInputUncontrolled = CheckBoxInput.withComponent(Field)

const ComplianceCard = ({
  title,
  disabled,
  name,
  id,
  complianceMessage,
  checked = '',
  onClick,
  ...props
}) => {
  const isControlled = isNotNilOrEmpty(checked) && isFunction(onClick)
  return (
    <StyledComplianceCard {...props}>
      <ComplianceTitle>{title}</ComplianceTitle>
      <Row>
        {isControlled ? (
          <CheckBoxInput
            id={id}
            name={name}
            disabled={disabled}
            type="checkbox"
            checked={checked}
            onClick={onClick}
          />
        ) : (
          <CheckBoxInputUncontrolled
            id={id}
            name={name}
            disabled={disabled}
            type="checkbox"
          />
        )}
        <Label htmlFor={id} style={apply('my-0')}>
          {complianceMessage}
        </Label>
      </Row>
    </StyledComplianceCard>
  )
}

export { ComplianceCard }

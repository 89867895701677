import { FormInput as FormInputBase } from 'components/common/FormInput'
import { styled, apply } from 'lib/styled'

const Container = styled.section()
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-normal'))
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)

const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

function PersonalDetails() {
  return (
    <Container>
      <Header>
        <Title>Personal Details</Title>
      </Header>

      <Details>
        <Column>
          <Row>
            <FormInput
              name="userDetails.firstName"
              type="text"
              label="First name"
              required
            />

            <FormInput
              name="userDetails.lastName"
              type="text"
              label="Last name"
              required
            />
          </Row>

          <Row style={apply('w-1/2')}>
            <FormInput
              disabled
              name="pilotDetails.companyName"
              type="text"
              label="Company name"
              containerProps={{ style: apply('mr-3') }}
            />
          </Row>

          <Row>
            <FormInput
              name="userDetails.phoneNumber"
              type="text"
              label="Phone Number"
              required
            />

            <FormInput
              disabled
              name="userDetails.email"
              type="text"
              label="E-mail"
              required
            />
          </Row>
        </Column>
      </Details>
    </Container>
  )
}

export { PersonalDetails }

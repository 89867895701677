import { prop, defaultTo } from '@seedcloud/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi } from 'lib/http-client'

const productEntity = new schema.Entity('projects')

const ProjectService = {
  async list() {
    const items = await adminApi.get('projects').json()
    const normalized = normalize(items, [productEntity])
    const entities = defaultTo({}, prop('projects', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
    }
  },

  async create(reference) {
    const config = { json: { reference } }
    return adminApi.post('projects', config).json()
  },
}

export { ProjectService }

import { baseApi } from 'lib/http-client'

const AuthService = {
  async fetchCurrentAccount() {
    const response = await baseApi.get(`my-account`)
    return response.json()
  },

  async updateCurrentAccount(payload) {
    const { email, ...updatedPayload } = payload
    const config = {
      json: updatedPayload,
    }
    const response = await baseApi.patch(`my-account`, config)
    return response.json()
  },

  async ChangePassword() {
    const response = await baseApi.get(`auth/change-password`)
    return response.json()
  },
}

export { AuthService }

import { Switch, Route } from 'react-router-dom'

import { CreateStaff } from './CreateStaff'
import { StaffDetails } from './StaffDetails'
import { StaffList } from './StaffList'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { ProtectedRoute } from 'components/common/ProtectedRoute'
import { RESOURCES } from 'constants/resources'
import { usePermissions } from 'lib/permissions'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

// eslint-disable-next-line react/display-name
const withStaffList = (Component) => (props) =>
  (
    <>
      <StaffList />
      <Component {...props} />
    </>
  )

function StaffManager() {
  const permissions = usePermissions()
  const canCreateStaff = permissions.can('create', RESOURCES.STAFF)

  return (
    <Container>
      <Switch>
        <Route exact path={['/', '/staff']} component={StaffList} />
        <ProtectedRoute
          exact
          path="/staff/create"
          render={withStaffList(CreateStaff)}
          allowed={canCreateStaff}
        />
        <Route exact path="/staff/:id" render={withStaffList(StaffDetails)} />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { StaffManager }

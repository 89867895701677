import { getEnvVariable } from 'utils/getEnvVariable'

const CONFIG = {
  API: {
    URL: getEnvVariable('API_URL'),
  },
  IDENTITY: {
    DOMAIN: getEnvVariable('IDENTITY_DOMAIN'),
    CLIENT_ID: getEnvVariable('IDENTITY_CLIENT_ID'),
    REDIRECT_URL: getEnvVariable('IDENTITY_REDIRECT_URL'),
    CONNECTION: getEnvVariable('IDENTITY_CONNECTION'),
  },
  MAPBOX: {
    ACCESS_TOKEN: getEnvVariable('MAPBOX_ACCESS_TOKEN'),
    API_URL: getEnvVariable('MAPBOX_API_URL'),
  },
  PILOT_APP_APPSTORE_URL: getEnvVariable('PILOT_APP_APPSTORE_URL'),
  STRIPE: {
    PRICING_TABLE_ID: getEnvVariable('STRIPE_PRICING_TABLE_ID'),
    PUBLISHABLE_KEY: getEnvVariable('STRIPE_PUBLISHABLE_KEY'),
  },
}

export { CONFIG }

import { organizationModule } from './module'
import { organizationSelectors } from './selectors'
import { OrganizationService } from './service'

import { API_TYPES } from 'lib/http-client'
import { createModule } from 'utils/createModule'

export const createOrganizationModule = (
  type = API_TYPES.ADMIN,
  name = 'organization'
) => {
  const service = OrganizationService()
  const module = organizationModule(name, service)
  const selectors = organizationSelectors(module)

  if (type === API_TYPES.ADMIN) {
    return createModule(undefined, {}, service)
  }

  return createModule(module, selectors, service)
}

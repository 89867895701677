import { saveAs } from 'file-saver'
import { useState } from 'react'
import TinyQueue from 'tinyqueue'

import { MultiDownloadModal } from './MultiDownloadModal'
import { OrderAssets } from './OrderAssets'
import { OrderOverview } from './OrderOverview'
import { OrderPilot } from './OrderPilot'

import ArrowLeft from 'assets/arrowLeft.svg'
import { apply, styled } from 'lib/styled'

const Container = styled.div(apply('flex flex-column w-full pb-8'))

const Scrollable = styled.div(
  apply('flex flex-column h-full', {
    overflowY: 'scroll',
  })
)

const GoBackBtn = styled.div(apply('flex p-2 items-center mb-6', { cursor: 'pointer' }))

const Wrapper = styled.div(
  apply('grid', {
    gridTemplateColumns: '3fr 1fr',
    gap: '1.5rem',
    '@media (max-width: 1280px)': {
      gridTemplateColumns: '1fr',
    },
  })
)

function OrderDetails({
  history,
  inspectedJob = {},
  fileEntities = {},
  jobFolderList,
  companyName,
  documentModule,
}) {
  const shouldShowModal = () =>
    localStorage.getItem('nbsMultiDownloadModalShown') !== 'true'
  const [showModal, setShowModal] = useState()
  const [progress, setProgress] = useState({})

  const onDownload = async (id, fileSize, fileName) => {
    const file = await documentModule.downloadJobDocument(id, {
      multipart: true,
      fileSize,
    })
    if (file) saveAs(file, fileName)
  }

  const onConfirm = () => {
    localStorage.setItem('nbsMultiDownloadModalShown', 'true')
    setShowModal(false)
  }

  const onProgress = ({ folderId, progress }) => {
    setProgress((prevProgress) => ({
      ...prevProgress,
      [folderId]: {
        ...(prevProgress[folderId] || {}),
        currentProgress: (prevProgress[folderId]?.currentProgress || 0) + progress,
      },
    }))
  }

  const onMultiDownload = async (documents, folderId) => {
    if (shouldShowModal()) {
      setShowModal(true)
      return
    }
    const queue = new TinyQueue([...documents])

    while (queue.length) {
      const document = queue.peek()
      // eslint-disable-next-line no-await-in-loop
      const file = await documentModule.downloadJobDocument(document.id, {
        multipart: true,
        fileSize: document.fileSize,
        onProgress,
        folderId,
      })
      queue.pop()
      saveAs(file, document.fileName)

      if (queue.length === 0) {
        setProgress((prevProgress) => {
          const { [folderId]: _, ...rest } = prevProgress
          return rest
        })
      }
    }
  }

  return (
    <Container>
      <GoBackBtn onClick={() => history.goBack()}>
        <img src={ArrowLeft} alt="Go back" />
        <span className="ml-4">Back</span>
      </GoBackBtn>
      <Scrollable>
        <Wrapper>
          <OrderOverview
            reference={inspectedJob?.reference}
            product={inspectedJob?.product}
            address={inspectedJob?.address}
            startedAt={inspectedJob?.startedAt}
            finishedAt={inspectedJob?.finishedAt}
          />
          <OrderPilot engagedBy={inspectedJob?.engagedBy} companyName={companyName} />
          <OrderAssets
            jobId={inspectedJob?.id}
            fileEntities={fileEntities}
            jobFolderList={jobFolderList}
            onDownload={onDownload}
            onMultiDownload={onMultiDownload}
            progress={progress}
          />
        </Wrapper>
      </Scrollable>

      <MultiDownloadModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        title="Action Required: Enable Multiple Downloads"
        subtitle="Ensure that you activate the option for multiple downloads by navigating to Settings > Privacy & Security in your browser."
        onConfirm={onConfirm}
      />
    </Container>
  )
}

export { OrderDetails }

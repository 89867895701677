import { map, prop, path, pathOr } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { projectModule } from './module'

const { state$, effectState$ } = projectModule

const projectEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))

const projectList$ = select(order$, projectEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const fetchProductsStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchProducts', 'status'], effectState)
)

const isLoadingProjects$ = select(
  fetchProductsStatus$,
  (status) => status === 'pending'
)

export { projectList$, isLoadingProjects$, projectEntities$ }

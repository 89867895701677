import { useHistory, useLocation } from 'react-router-dom'

import { ClaimCompanyLayout, ClaimCompanyCard } from 'components/claim/common'

const { Image, Title, Description, Button, Field } = ClaimCompanyCard

const ClaimCompany = () => {
  const { pathname } = useLocation()
  const history = useHistory()

  return (
    <ClaimCompanyLayout>
      <ClaimCompanyCard>
        <Image />
        <Title>Welcome to GHD Portal</Title>
        <Description>
          Hi carlos@ferrari.com, GHD has invited you as <br /> <b>Key Contact</b> for{' '}
          <b>Ferrari</b>. To get started, please claim your company.
        </Description>
        <Field name="company" value="Ferrari" label="Company" id="company" disabled />
        <Button
          onClick={() => {
            history.push(`${pathname}/change-password`)
          }}
        >
          Claim Company
        </Button>
      </ClaimCompanyCard>
    </ClaimCompanyLayout>
  )
}

export { ClaimCompany }

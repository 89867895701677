import CONSTANTS from '@seedcloud/constants'
import { connect } from '@seedcloud/stateless'
import { useLayoutEffect, useState } from 'react'
import * as Validator from 'yup'

import { CompanyDetail } from './CompanyDetail'

import { useIdentity } from 'lib/solta-id-react'
import { createCompanyModule } from 'modules/company'
import { getFilename } from 'utils/getFilename'
import { requiredString, optionalString } from 'utils/validation'

const {
  module: companyAdminModule,
  selectors: {
    companyDetail$: companyAdminDetail$,
    isInspectingCompany$: isInspectingAdminCompany$,
    isSubmitting$: isAdminSubmmiting$,
  },
} = createCompanyModule()

const { object } = Validator

const companyDetailAdminValidationSchema = object({
  name: requiredString('Company Name'),
  abn: requiredString('ABN').matches(/^\d+$/, 'ABN should only contain number'),
  phone: requiredString('Phone number').matches(
    CONSTANTS.REGEX.E164_PHONE_FORMAT,
    'Please include a country code (e.g +64213112)'
  ),
  website: requiredString('Company Website').url(
    'Please input full url (e.g https://example.com)'
  ),
  address: requiredString('Company Address'),
  commercial: object().shape({
    firstName: requiredString('Commercial Contact First Name'),
    lastName: requiredString('Commercial Contact Last Name'),
    phoneNumber: requiredString('Commercial Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: requiredString('Commercial Email').email('Not a valid email address'),
  }),
  billing: object().shape({
    firstName: optionalString('Billing Contact First Name'),
    lastName: optionalString('Billing Contact Last Name'),
    phoneNumber: optionalString('Billing Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: optionalString('Billing Email').email('Not a valid email address'),
  }),
  heroPilot: object().shape({
    firstName: optionalString('Hero Pilot First Name'),
    lastName: optionalString('Hero Pilot Last Name'),
    phoneNumber: optionalString('Hero Pilot Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: optionalString('Hero Pilot Email').email('Not a valid email address'),
  }),
})

const CompanyDetailAdminContainer = ({
  isInspectingCompany,
  isSubmitting,
  companyDetail = {},
}) => {
  const { setOrganization } = useIdentity()

  useLayoutEffect(() => {
    companyAdminModule.inspectCompany()
  }, [])

  const [editing, setEditing] = useState(false)

  const { name, abn, website, address, phone, logo, commercial, billing, heroPilot } =
    companyDetail

  const initialValues = {
    name,
    abn,
    website,
    address,
    phone,
    commercial,
    billing,
    heroPilot,
    logo: getFilename(logo),
  }

  const companyDetailProps = {
    isAdmin: true,
    isInspectingCompany,
    isSubmitting,
    companyDetail,
    initialValues,
    validationSchema: companyDetailAdminValidationSchema,
    editing,
    setEditing,
    handleSubmit: async (values) => {
      const payload = values
      const company = await companyAdminModule.updateCompany(null, payload)
      setOrganization(company)
      setEditing(false)
    },
  }

  return <CompanyDetail {...companyDetailProps} />
}

export const ConnectedCompanyAdminDetail = connect(() => ({
  companyDetail: companyAdminDetail$,
  isInspectingCompany: isInspectingAdminCompany$,
  isSubmitting: isAdminSubmmiting$,
}))(CompanyDetailAdminContainer)

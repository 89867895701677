import { FormInput as FormInputBase } from 'components/common/FormInput'
import { styled, apply } from 'lib/styled'

const Container = styled.section()
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0 ml-0'),
})

function CreateCompanyForm({
  isEditing,
  isCreatingCustomer,
  pilotCategoriesList,
  ...props
}) {
  return (
    <Container {...props}>
      <Details>
        <Column style={apply('flex-1')}>
          <Row>
            <FormInput
              name="organizationDetails.name"
              type="text"
              disabled={!isEditing}
              label="Company Name"
            />
            <FormInput
              name="organizationDetails.abn"
              type="text"
              disabled={!isEditing}
              label="abn"
            />
          </Row>
          <Row>
            <FormInput
              name="organizationDetails.address"
              type="text"
              disabled={!isEditing}
              label="Address"
            />
          </Row>
        </Column>
      </Details>
    </Container>
  )
}

export { CreateCompanyForm }

import {
  prop,
  pathOr,
  pick,
  mergeDeepRight,
  isNotNilOrEmpty,
  ifElse,
  isNilOrEmpty,
  always,
  path,
} from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

// eslint-disable-next-line import/no-cycle
import { companyAdminModule } from './module'

import { FLEETCODE_NETWORK_TYPES } from 'constants/roles'

const { state$, effectState$ } = companyAdminModule

const companyEntities$ = select(state$, prop('entities'))
const inspectedEntity$ = select(state$, prop('inspectedEntity'))
const inspectedDocument$ = select(state$, prop('inspectedDocument'))

const inspectedCompany$ = select(inspectedEntity$, companyEntities$, (id, entities) =>
  prop(id, entities)
)

const companyDetail$ = select(inspectedCompany$, (entities) => {
  let detail = {}

  if (isNotNilOrEmpty(entities)) {
    const picked = pick(['firstName', 'lastName', 'email', 'phoneNumber'])
    const commercial = picked(prop('commercial', entities))
    const billing = picked(prop('billing', entities))
    const heroPilot = picked(prop('heroPilot', entities))

    detail = mergeDeepRight(
      pick(['id', 'name', 'abn', 'website', 'address', 'phone', 'logo'], entities),
      {
        commercial,
        billing,
        heroPilot,
      }
    )
  }

  return detail
})

const aviationDetail$ = select(inspectedCompany$, (entities) =>
  ifElse(
    isNilOrEmpty,
    always({}),
    pick([
      'country',
      'companyArn',
      'documents',
      'links',
      'licenses',
      'insuranceNumber',
      'insuranceProvider',
      'insuranceIssuedAt',
      'insuranceExpiredAt',
      'equipments',
    ])
  )(entities)
)

const isNBS$ = select(
  inspectedCompany$,
  (entities) => path(['fleetCode'], entities) === FLEETCODE_NETWORK_TYPES.NBS
)

const inspectCompanyStatus$ = select(effectState$, (effectState) =>
  pathOr('idle', ['inspectCompany', 'status'], effectState)
)

const updateCompanyCurrencyStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr(
      'fulfilled',
      ['updateCompanyCurrency', inspectedEntity, 'status'],
      effectState
    )
)

const isInspectingCompany$ = select(
  inspectCompanyStatus$,
  (status) => status === 'pending'
)

const updateCompanyStatus$ = select(effectState$, (effectState) =>
  pathOr('fulfilled', ['updateCompany', 'status'], effectState)
)

const inspectUploadAviationDocuments$ = select(effectState$, (effectState) =>
  pathOr('fulfilled', ['uploadAviationDocuments', 'status'], effectState)
)

const isUploading$ = select(
  inspectUploadAviationDocuments$,
  (status) => status === 'pending'
)

const inspectDownloadAviationDocuments$ = select(
  effectState$,
  inspectedDocument$,
  (effectState, inspectedDocument) =>
    pathOr(
      'idle',
      ['downloadAviationDocuments', inspectedDocument, 'status'],
      effectState
    )
)

const isDownloading$ = select(
  inspectDownloadAviationDocuments$,
  (status) => status === 'pending'
)

const inspectUploadLogo$ = select(effectState$, (effectState) =>
  pathOr('idle', ['uploadLogo', 'status'], effectState)
)

const isUploadingLogo$ = select(inspectUploadLogo$, (status) => status === 'pending')

const isSubmitting$ = select(updateCompanyStatus$, (status) => status === 'pending')

const currency$ = select(inspectedCompany$, prop('currency'))
const isUpdatingCurrency$ = select(
  updateCompanyCurrencyStatus$,
  (status) => status === 'pending'
)

export const companyAdminSelectors = {
  companyEntities$,
  inspectedEntity$,
  isInspectingCompany$,
  inspectedCompany$,
  isSubmitting$,
  isUpdatingCurrency$,
  companyDetail$,
  aviationDetail$,
  currency$,
  isUploading$,
  isUploadingLogo$,
  isDownloading$,
  isNBS$,
}

import { connect } from '@seedcloud/stateless'
import { useState, useLayoutEffect } from 'react'

import { CURRENCY_VALUE } from '../common/constants'

import { PilotCategories } from './PilotCategories'

import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import { createCompanyModule } from 'modules/company'
import { createOrganizationModule } from 'modules/organization'
import { createPilotCategoriesModule } from 'modules/pilotCategory'
import { useEntityType } from 'utils/hooks/useEntityType'

const {
  module: companyModule,
  selectors: {
    companyDetail$,
    currency$,
    invitationStatus$,
    isInspectingGetCompanyAndOrganizationId$,
    isUpdatingCurrency$,
    supplierType$,
  },
} = createCompanyModule(API_TYPES.WEB)

const {
  module: organizationModule,
  selectors: { isInspectingOrganization$ },
} = createOrganizationModule(API_TYPES.WEB)

const {
  module: pilotCategoryModule,
  selectors: {
    pilotCategoriesList$,
    isFetchingPilotCategories$,
    pilotCategoriesEntities$,
    pilotCategoriesFilters$,
  },
} = createPilotCategoriesModule(API_TYPES.WEB)

const PilotCategoriesContainer = ({
  pilotCategoriesList,
  pilotCategoriesEntities,
  isFetchingPilotCategories,
  isInspectingCompany,
  type,
  status,
  pilotCategoriesFilters,
  isInspectingOrganization,
}) => {
  const { isInternalEntity } = useEntityType()
  const { organization } = useIdentity()

  const [selected, setSelected] = useState(CURRENCY_VALUE.AUD)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCurrencyModal, setCurrencyModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [deletedCategoryId, setDeletedCategoryId] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  useLayoutEffect(() => {
    if (!type) {
      companyModule.inspectCompany()
    }
  }, [type])

  useLayoutEffect(() => {
    pilotCategoryModule.fetchPilotCategories(null, { turnPage: false })
    return () => {
      pilotCategoryModule.resetPagination()
    }
  }, [])

  const handleShowEdit = (id) => {
    setShowEditModal(true)
    setSelectedCategory(pilotCategoriesEntities[id])
  }

  const handleOpenDeleteModal = (id) => {
    setShowDeleteModal(true)
    setDeletedCategoryId(id)
  }

  const handleCloseModal = () => {
    setShowAddModal(false)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedCategory(null)
  }

  const handleCreateCategory = async (values) => {
    setSubmitting(true)
    await pilotCategoryModule.publishPilotCategories(null, { type, payload: values })
    setSubmitting(false)
    setShowAddModal(false)
    await pilotCategoryModule.fetchPilotCategories(null, { turnPage: false, type })
  }

  const handleEditPilotCategory = async (values) => {
    setSubmitting(true)
    await pilotCategoryModule.updatePilotCategories(selectedCategory.id, {
      type,
      payload: values,
    })
    setSubmitting(false)
    setShowEditModal(false)
    await pilotCategoryModule.fetchPilotCategories(null, { turnPage: false, type })
  }

  const handleDeletePilotCategory = async () => {
    setSubmitting(true)
    await pilotCategoryModule.deletePilotCategory(deletedCategoryId)
    setSubmitting(false)
    setShowDeleteModal(false)
    await pilotCategoryModule.fetchPilotCategories(null, { turnPage: false, type })
  }

  const handleUpdateCompanyCurrency = async () => {
    // eslint-disable-next-line no-undef
    await organizationModule.updateOrganization(null, { currency: selected })
    setCurrencyModal(false)
  }

  const pilotCategoriesProps = {
    pilotCategoriesList,
    pilotCategoriesFilters,
    isFetchingPilotCategories,
    isInspectingCompany,
    companyCurrency: organization.currency,
    showAddModal,
    submitting,
    setCurrencyModal,
    setShowAddModal,
    handleShowEdit,
    handleCloseModal,
    handleOpenDeleteModal,
    handleCreateCategory,
    selectedCategory,
    showEditModal,
    setShowEditModal,
    handleCloseEditModal,
    handleEditPilotCategory,
    showDeleteModal,
    setShowDeleteModal,
    handleDeletePilotCategory,
    showCurrencyModal,
    selected,
    setSelected,
    handleUpdateCompanyCurrency,
    isAdmin: false,
    isInternalEntity,
    module: pilotCategoryModule,
    status,
    type,
    company: organization.name ?? '',
    loading:
      isFetchingPilotCategories || isInspectingCompany || isInspectingOrganization,
    onTurnPage: (turnNext) =>
      pilotCategoryModule.fetchPilotCategories(null, { turnPage: true, turnNext }),
  }

  return <PilotCategories {...pilotCategoriesProps} />
}

export const ConnectedPilotCategoriesContainer = connect(() => ({
  pilotCategoriesList: pilotCategoriesList$,
  pilotCategoriesEntities: pilotCategoriesEntities$,
  isFetchingPilotCategories: isFetchingPilotCategories$,
  isInspectingCompany: isInspectingGetCompanyAndOrganizationId$,
  isUpdatingCurrency: isUpdatingCurrency$,
  companyCurrency: currency$,
  pilotCategoriesFilters: pilotCategoriesFilters$,
  companyDetails: companyDetail$,
  type: supplierType$,
  status: invitationStatus$,
  isInspectingOrganization: isInspectingOrganization$,
}))(PilotCategoriesContainer)

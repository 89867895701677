import { useFormikContext } from 'formik'

// import { ServiceCategories } from './ServiceCategories'

import { DateField } from 'components/common/DateField'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { SelectField } from 'components/common/SelectField'
import { LICENCE_TYPE_DISPLAY_NAMES } from 'constants/licenceTypes'
import { styled, apply } from 'lib/styled'

const Container = styled.section()
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-normal'))
// const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

function SkillsAndAbilities() {
  const licenceTypes = Object.entries(LICENCE_TYPE_DISPLAY_NAMES).map(
    ([value, label]) => ({
      label,
      value,
    })
  )

  // const { setFieldValue, values } = useFormikContext()
  const { setFieldValue } = useFormikContext()

  return (
    <Container>
      <Header>
        <Title>Skills & Abilities</Title>
      </Header>

      <Details>
        <Column>
          <Row style={apply('w-1/2')}>
            <SelectField
              label="Licence Type"
              name="pilotDetails.licenceType"
              options={licenceTypes}
              containerProps={{ style: apply('mr-3') }}
              onChange={({ value }) => {
                setFieldValue('pilotDetails.licenceType', value)
              }}
            />
          </Row>

          <Row>
            <FormInput
              label="Licence Number"
              name="pilotDetails.licenceNumber"
              type="text"
            />

            <DateField
              label="Licence Expiry"
              name="pilotDetails.licenceExpiredAt"
              type="text"
              rootStyle={apply('ml-3')}
              autoOk
            />
          </Row>

          {/* Hide Insurance and Service Categories for now 
            <Row>
              <FormInput
                label="Insurance Number"
                required={!!values.pilotDetails.insuranceExpiredAt}
                name="pilotDetails.insuranceNumber"
                type="text"
              />

              <DateField
                label="Insurance Expiry"
                required={!!values.pilotDetails.insuranceNumber}
                name="pilotDetails.insuranceExpiredAt"
                type="text"
                rootStyle={apply('ml-3')}
                autoOk
              />
            </Row>

            <Row style={apply('flex-column')}>
              <Label>Service Categories</Label>
              <ServiceCategories name="pilotDetails.serviceCategories" />
            </Row> 
          */}
        </Column>
      </Details>
    </Container>
  )
}

export { SkillsAndAbilities }

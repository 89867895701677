import { styled } from 'lib/styled'

const badgeStyle = {
  padding: '4px 8px',
  borderRadius: '4px',
  fontSize: '11px',
  textAlign: 'center',
}

export const BaseBadge = styled.span(({ status, variants }) => ({
  ...badgeStyle,
  ...variants[status],
  display: 'flex',
  height: '30px',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const BorderedBadge = styled.div({
  ...badgeStyle,
  width: 'auto',
  padding: '6px 8px',
  maxWidth: '140px',
  border: '1px solid #00326F',
  borderRadius: '8px',
  fontWeight: 500,
  color: '#00326F',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})
